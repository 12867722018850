<div *ngIf=eventInfo class="common-img default-img h-100" [style.background-image]="eventInfo.bgUrl ? 
'url(' + eventInfo.bgUrl + ')': 
'url(../../assets/images/defaultBg1.png)'">
    <div class="row bg-white banner-block">
        <div class="container d-flex">
            <div *ngIf="eventInfo.eventLogoUrl">
                <img id="login-banner-image" id="event-logo"  class="my-3" src="{{eventInfo.eventLogoUrl}}">
                <p id="login-period-text" class="text-muted mb-3">{{getPeriodString(eventInfo)}}</p>
            </div>
            
            <div *ngIf="!eventInfo.eventLogoUrl">
                <h4 id="login-event-text" class="my-3" >{{eventInfo.eventName}}</h4>
                <p id="login-period-text" class="text-muted mb-3">{{getPeriodString(eventInfo)}}</p>
            </div>

           
            <div class="my-auto ml-auto">
                <button id="login-logout-button" class="btn btn-outline-danger border-0" (click)=logout()><fa-icon
                        [icon]="faSignOutAlt"></fa-icon></button>
            </div>
        </div>
        <!-- <p id="login-period-text" class="text-muted mb-3">{{getPeriodString(eventInfo)}}</p> -->

    </div>
    <div class="container d-flex justify-content-center login-box">
        <div *ngIf=eventInfo id="login-card" class="my-auto justify-content-center border card-form"
            style="width: 612px;">
            <!-- <div class="d-flex justify-content-center">
                <img src="{{eventInfo.bannerUrl}}" class="img-fluid" style="width: 500px; padding: -24px;">
            </div> -->
            <h4 id="login-title-text" class="d-flex justify-content-center" style="color: #009FD4;">{{loginType |
                titlecase}} Login</h4>
            <div id="login-event-warning-text" class="alert alert-danger" role="alert" *ngIf="!canLogin" [innerHTML]="eventTextWarning">
            </div>
            <div class="mt-4" *ngIf="canLogin">
                <form [formGroup]=form>
                    <label id="login-field-email-text" for="login-field-email-input"
                        class="col-form-label">Email</label>
                    <input id="login-field-email-input" type="text" class="form-control mb-2" formControlName="username"
                        placeholder="Enter your email">
                    <label id="login-field-password-text" for="login-field-password-input"
                        class="col-form-label">Password</label>
                    <input id="login-field-password-input" type="password" class="form-control mb-2"
                        formControlName="password" placeholder="Enter your password">
                    <div class="justify-content-center mt-4">
                        <button id="login-button" class="btn btn-orange d-block w-100" (click)=login()>LOGIN</button>
                    </div>
                </form>
            </div>

        </div>
    </div>
    <div *ngIf=eventInfo class="text-center mt-2">
        <div id="login-contact-admin-text" class="text-danger" *ngIf="eventInfo.emailFrom">If you forgot password please
            contact
            {{eventInfo.emailFrom}}</div>
    </div>

</div>