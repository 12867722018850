import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@ent-regis/entregis-ts-angular/api/dietaryPublicController.service";
export class DietaryService {
    constructor(dietaryPublicService) {
        this.dietaryPublicService = dietaryPublicService;
        this.dietaries = new BehaviorSubject([]);
        this.noneDietaryId = new BehaviorSubject(null);
        this.dietaryPublicService.getDietaryUsingGET().subscribe((response) => {
            this.dietaries.next(response.data);
            const noneDietaryIndex = response.data.findIndex(x => x.dietaryName === 'None');
            if (noneDietaryIndex) {
                this.noneDietaryId.next(response.data[noneDietaryIndex].dietaryId);
            }
        });
    }
}
DietaryService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DietaryService_Factory() { return new DietaryService(i0.ɵɵinject(i1.DietaryPublicControllerService)); }, token: DietaryService, providedIn: "root" });
