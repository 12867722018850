import { Pipe, PipeTransform } from '@angular/core';
import { MeetingType } from 'src/app/constants/meeting-type';

@Pipe({
  name: 'meetingType'
})
export class MeetingTypePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value) {
      const result = MeetingType.find(f => f.type === value);
      return result ? result.label : null;
    } else {
      return null;
    }
  }
}
