<div style="position: relative;">
    <div class="tableFixHead table-card-form">
        <table id="company-info-table" class="table table-responsive-lg">
            <thead class="thead-blue">
                <tr>
                    <th id="company-info-table-field-name-column-text" scope="col" style="width: 20%;">Name</th>
                    <th id="company-info-table-field-type-column-text" scope="col" style="width: 20%;"></th>
                    <th id="company-info-table-field-job-column-text" scope="col" style="width: 10%;">Job title</th>
                    <th id="company-info-table-field-email-column-text" scope="col" style="width: 20%;">Email</th>
                    <th id="company-info-table-field-mobile-column-text" scope="col" style="width: 10%;">Mobile</th>
                    <th id="company-info-table-field-dietary-column-text" scope="col" style="width: 10%;">Dietary</th>
                    <th></th>
                </tr>
            </thead>
            <tbody class="bg-white" cdkDropList (cdkDropListDropped)="drop($event)">
                <tr *ngFor="let element of participantData; let i = index;" class="pop rowGroup" cdkDrag
                    cdkDragLockAxis="y">
                    <td class="col" style="width: 20%;">
                        <span id="company-info-table-field-firstname-text-{{i}}" class="mr-2">{{element.firstName}}</span> 
                        <span id="company-info-table-field-lastname-text-{{i}}" class="pr-1">{{element.lastName}}</span></td>
                    <td class="col" style="width: 20%;">
                        <span id="company-info-table-field-contact-text-{{i}}" *ngIf="element.isContact" class="badge badge-light-blue mr-1" >Contact Person</span>
                        <span id="company-info-table-field-speaker-text-{{i}}" *ngIf="element.isParticipant"  class="badge badge-light-orange">Speaker</span>
                    </td>
                    <td id="company-info-table-field-job-text-{{i}}" class="col" style="width: 10%;">{{element.jobTitle}}</td>
                    <td id="company-info-table-field-email-text-{{i}}" class="col" style="width: 20%;">{{element.email}}</td>
                    <td id="company-info-table-field-mobile-text-{{i}}" class="col" style="width: 10%;">{{element.mobileNumber}}</td>
                    <td class="col" style="width: 10%;">
                        <span id="company-info-table-field-dietary-text-{{i}}" *ngIf="element.dietary != null">{{ element.dietary.dietaryName }}</span>
                        <span id="company-info-table-field-other-dietary-text-{{i}}" *ngIf="element.dietary == null"> {{ element.otherDietary}} </span>
                    </td>
                    <td>
                        <div class="d-flex">
                            <button id="company-info-table-edit-button-{{i}}" class="btn btn-sm btn-warning mr-1" (click)="editClicked(element)"><i
                                    class="fas fa-edit"></i></button>
                            <button id="company-info-table-remove-button-{{i}}" class="btn btn-sm btn-danger mr-1" (click)="rmClicked($event, element)"
                                *ngIf="!isCloseRegister"><i class="fas fa-trash-alt"></i></button>
                            <button id="company-info-table-sort-button-{{i}}" type="button" class="btn btn-sm"><i class="fas fa-sort"></i></button>
                        </div>
                    </td>
                </tr>
            </tbody>
            <!-- <ng-container cdkColumnDef="name">
                <th class="table-header-cell" cdk-header-cell *cdkHeaderCellDef> Name </th>
                <td class="table-cell" cdk-cell *cdkCellDef="let element"> {{element.firstName}} </td>
            </ng-container>
            <ng-container cdkColumnDef="surname">
                <th class="table-header-cell" cdk-header-cell *cdkHeaderCellDef> Surname </th>
                <td class="table-cell" cdk-cell *cdkCellDef="let element"> {{element.lastName}} </td>
            </ng-container>
            <ng-container cdkColumnDef="email">
                <th class="table-header-cell" cdk-header-cell *cdkHeaderCellDef> Email </th>
                <td class="table-cell" cdk-cell *cdkCellDef="let element"> {{element.email}} </td>
            </ng-container>
            <ng-container cdkColumnDef="mobile">
                <th class="table-header-cell" cdk-header-cell *cdkHeaderCellDef> Mobile </th>
                <td class="table-cell" cdk-cell *cdkCellDef="let element"> {{element.mobileNumber}} </td>
            </ng-container>
            <ng-container cdkColumnDef="job">
                <th class="table-header-cell" cdk-header-cell *cdkHeaderCellDef> Job Title </th>
                <td class="table-cell" cdk-cell *cdkCellDef="let element"> {{element.jobTitle}} </td>
            </ng-container>
            <ng-container cdkColumnDef="dietary">
                <th class="table-header-cell" cdk-header-cell *cdkHeaderCellDef> Dietary </th>
                <td class="table-cell" cdk-cell *cdkCellDef="let element">
                  <span *ngIf="element.dietary != null">{{ element.dietary.dietaryName }}</span>
                  <span *ngIf="element.dietary == null"> {{ element.otherDietary}} </span>
                </td>
            </ng-container>
    
            <ng-container cdkColumnDef="action">
                <th class="table-header-cell" cdk-header-cell *cdkHeaderCellDef></th>
                <td class="table-cell" cdk-cell *cdkCellDef="let element">
                    <button class="btn btn-delete" (click)="rmClicked($event, element)" style="color: red;" *ngIf="!isCloseRegister"><i class="fas fa-times"></i></button>
                </td>
            </ng-container>
    
            <tr class="table-header" cdk-header-row *cdkHeaderRowDef="displayedColumns"></tr>
            <tr class="table-row" cdk-row *cdkRowDef="let row; columns: displayedColumns;" (click)=editClicked(row)></tr> -->
        </table>
        <div id="company-info-table-no-participant-text" class="text-center h5 mt-3" *ngIf="participantData == null || participantData.length === 0">No
            participant found.</div>

    </div>
    <div style="position: absolute;top:-50px;right: 0px;">
        <button id="company-info-table-add-participant-button" class="btn btn-primary my-2" (click)="addClicked()" *ngIf="checkShowAddbtn()">
            Add Participants
        </button>
    </div>
</div>