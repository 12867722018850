<!-- <div class="content">
    <div class="table-responsive-lg tableFixHead">
        <table class="table">
            <thead class="thead-white">
                <tr>
                    <th scope="col">TIME</th>
                    <th scope="col">AVAILABLE</th>
                    <th scope="col" *ngIf="isEventTypeDigitalRoadShow">COMPANY LIST</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let slot of slots; let i = index;">
                    <tr>
                        <td colspan="5"><strong>{{slot.slotDate | date:'MMM dd, yyyy':isEventTypeThailandFocus ? 'UTC +7' : null}}</strong></td>
                    </tr>
                    <tr *ngFor="let period of slot.slotPeriods;let s = index;"
                        [ngClass]="(period.available === null && submited) ? 'invalid': 'dd' ">
                        <td>{{period.startTime | date:'H:mm':isEventTypeThailandFocus ? 'UTC +7' : null}} - {{period.endTime | date:'H:mm':isEventTypeThailandFocus ? 'UTC +7' : null}}</td>
                        <td>
                            <div class="form-check form-check-inline">
                                <label class="form-check-label">
                                    <input class="form-check-input" type="radio"
                                        name="available_{{i}}_{{period.slotPeriodId}}" [disabled]="isCloseRegister"
                                        (change)="onSelectionChange(i, period.slotPeriodId, 'Y')"
                                        [checked]="period.available === 'Y'" value="Y"> Yes
                                </label>
                            </div>
                            <div class="form-check form-check-inline">
                                <label class="form-check-label">
                                    <input class="form-check-input" type="radio"
                                        name="available_{{i}}_{{period.slotPeriodId}}" [disabled]="isCloseRegister"
                                        (change)="onSelectionChange(i, period.slotPeriodId, 'N')"
                                        [checked]="period.available === 'N'" value="N"> No
                                </label>
                            </div>
                        </td>
                        <td style="white-space: normal;" *ngIf="isEventTypeDigitalRoadShow">
                            <span *ngFor="let symbol of lookupSymbolsFromSpId(period.slotPeriodId); let symbolIndex = index">
                                <span *ngIf="symbolIndex > 0">,&nbsp;&nbsp;&nbsp;</span>
                                <span>{{symbol}}</span>
                            </span>
                        </td>
                    </tr>
                </ng-container>

            </tbody>
        </table>
        <button type="button" class="btn btn-primary" (click)="save()" *ngIf="!isCloseRegister">NEXT</button>
    </div>
</div> -->
<div class="content" style="border-top: 2px solid #0064c5;margin-top: 1px;">
    <div class="content-container">
        <div class="content">
            <div class="card-form">
                <div class="m-3">
                    <div id="investor-time-warning-text" class="card-xl-title row mb-3">Please select your available
                        times</div>
                    <ng-container *ngFor="let slot of slots; let i = index;">
                        <div class="row mb-1">
                            <strong id="investor-time-field-slot-date-text-{{i}}">{{slot.slotDate | date:'MMM dd, yyyy':isEventTypeThailandFocus ? 'UTC +7' : null}}</strong>
                        </div>
                        <div class="row mb-3">
                            <div *ngFor="let period of slot.slotPeriods;let s = index;">
                                <div *ngIf="!isCloseRegister"
                                    [ngClass]="(period.available=='Y')? 'time-box-item is-check':'time-box-item'"
                                    (click)="changeIsAvalable(i,period.slotPeriodId,period.available)">
                                    <input type="checkbox" [checked]="period.available === 'Y'"
                                        id="investor-time-field-slot-period-input-{{i}}-{{s}}"
                                        name="available_{{i}}_{{period.slotPeriodId}}">
                                    <span id="investor-time-field-slot-period-text-{{i}}-{{s}}"
                                        class="description">{{period.startTime | date:'H:mm':isEventTypeThailandFocus ?
                                        'UTC +7' : null}} - {{period.endTime | date:'H:mm':isEventTypeThailandFocus ? 'UTC +7' :
                                        null}}</span>
                                </div>
                                <div *ngIf="isCloseRegister" class="time-box-item-disable">
                                    <input type="checkbox" [checked]="period.available === 'Y'"
                                        id="investor-time-field-slot-period-input-{{i}}-{{s}}"
                                        name="available_{{i}}_{{period.slotPeriodId}}" disabled>
                                    <span id="investor-time-field-slot-period-text-{{i}}-{{s}}"
                                        class="description">{{period.startTime | date:'H:mm':isEventTypeThailandFocus ?
                                        'UTC +7' : null}} - {{period.endTime | date:'H:mm':isEventTypeThailandFocus ? 'UTC +7' :
                                        null}}</span>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>

            <div class="d-flex justify-content-center mt-2">
                <button id="investor-time-next-button" type="button" class="btn btn-primary" (click)="save()" *ngIf="!isCloseRegister">SAVE</button>
            </div>
        </div>

    </div>
</div>