<!-- <nav class="navbar header">
	 <div><a class="navbar-brand font-weight-bold">
			<img id="event-logo" [src]="logoURL" class="navbar-brand">
			{{eventName}}
		</a>
	</div>
	<div>
		<button class="btn btn-outline-danger border-0" (click)=logout()><fa-icon
				[icon]="faSignOutAlt"></fa-icon></button>
	</div>
</nav> -->
<div class="row bg-white banner-block">
	<div class="container d-flex">
		<div *ngIf="logoURL">
			<img id="header-logo-image" id="event-logo"  class="my-3" src="{{logoURL}}">
			<p id="login-period-text" class="text-muted mb-3">{{getPeriodString(eventInfo)}}</p>
		</div>
		
		<div *ngIf="!logoURL">
			<h4 id="login-event-text" class="my-3" >{{eventName}}</h4>
			<p id="login-period-text" class="text-muted mb-3">{{getPeriodString(eventInfo)}}</p>
		</div>
		<!-- <img *ngIf="logoURL" id="header-logo-image" height="80px" id="event-logo" class="my-3" [src]="logoURL">
		<h4 id="header-event-text" class="my-auto ml-4">{{eventName}}</h4> -->
		<div class="my-auto ml-auto">
			<button id="header-logout-button" class="btn btn-outline-danger border-0" (click)=logout()><fa-icon
					[icon]="faSignOutAlt"></fa-icon></button>
		</div>
	</div>

</div>