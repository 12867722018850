/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./hotel.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./hotel.component";
import * as i3 from "@ent-regis/entregis-ts-angular/api/investorHotelController.service";
import * as i4 from "@ent-regis/entregis-ts-angular/api/dietaryPublicController.service";
import * as i5 from "../../core/service/register-investor.service";
import * as i6 from "@angular/common";
import * as i7 from "../../shared/services/alert.service";
import * as i8 from "../../shared/services/event.service";
var styles_HotelComponent = [i0.styles];
var RenderType_HotelComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HotelComponent, data: {} });
export { RenderType_HotelComponent as RenderType_HotelComponent };
export function View_HotelComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "content"], ["style", "border-top: 2px solid #0064c5;margin-top: 1px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "content-container mt-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 8, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "text-danger"], ["id", "investor-hotel-remark-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["Remark : If you would like to stay at ", " with special group rate, please follow the link below to make a reservation with hotel directly."])), (_l()(), i1.ɵeld(5, 0, null, null, 5, "div", [["class", "card-form mt-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "m-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "h4", [["id", "investor-hotel-location-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "a", [["class", "link-blue"], ["href", "https://www.marriott.com/events/start.mi?id=1716519936463&key=GRP"], ["id", "investor-hotel-reservation-link"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Hotel reservation link "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.event.location; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.event.location; _ck(_v, 8, 0, currVal_1); }); }
export function View_HotelComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-hotel", [], null, null, null, View_HotelComponent_0, RenderType_HotelComponent)), i1.ɵdid(1, 245760, null, 0, i2.HotelComponent, [i3.InvestorHotelControllerService, i4.DietaryPublicControllerService, i5.RegisterInvestorService, i6.DatePipe, i7.AlertService, i8.EventService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HotelComponentNgFactory = i1.ɵccf("app-hotel", i2.HotelComponent, View_HotelComponent_Host_0, {}, {}, []);
export { HotelComponentNgFactory as HotelComponentNgFactory };
