import { AuthProvider } from './core/authentication/auth-provider';
import { environment } from './../environments/environment';
import { Configuration, ConfigurationParameters } from '@ent-regis/entregis-ts-angular';
import localeTh from '@angular/common/locales/th';
import { registerLocaleData } from '@angular/common';
import { Adal8HTTPService } from 'adal-angular8';
let sharedApiModuleConfig;
export function apiConfigFactory() {
    const params = {};
    params.apiKeys = {
        Authorization: ''
    };
    if (sharedApiModuleConfig == null) {
        sharedApiModuleConfig = new Configuration(params);
    }
    return sharedApiModuleConfig;
}
export function init_app(authProvider) {
    return () => authProvider.onAppLoaded();
}
registerLocaleData(localeTh);
const ɵ0 = environment.baseUrl, ɵ1 = Adal8HTTPService.factory;
export class AppModule {
}
export { ɵ0, ɵ1 };
