import { RegisterCompanyConfirmRmParticipantModalComponent } from './register-company-confirm-rm-participant-modal/register-company-confirm-rm-participant-modal.component';
import {
  RegisterCompanyAddEditParticipantModalComponent
} from './register-company-add-edit-participant-modal/register-company-add-edit-participant-modal.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { DataSource } from '@angular/cdk/collections';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ParticipantResponse, ParticipantControllerService, ParticipantRequest, EventInfoWithConsentResponse } from '@ent-regis/entregis-ts-angular';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Router } from '@angular/router';
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { msg } from 'src/app/constants/msg';
import { AlertService } from 'src/app/shared/services/alert.service';
import { RegisterCompanyService } from 'src/app/register-company/register-company.service';
import { first } from 'rxjs/operators';
@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit, OnDestroy {

  @Input()
  registerCompanyId: number;
  @Input() eventInfoWithConsent: EventInfoWithConsentResponse;

  @Input()
  isCloseRegister: boolean;

  editContactPersonModalRef: BsModalRef;
  addEditParticipantModalRef: BsModalRef;
  rmParticipantModalRef: BsModalRef;

  //participantDataSource: ParticipantDataSource = new ParticipantDataSource();
  participantData: ParticipantResponse[] = [];
  isDisableContactPerson = false;
  maxParticipant = 5;
  isParticipantAvailable = true;

  private subscription = new Subscription();

  constructor(
    private participantControllerService: ParticipantControllerService,
    private modalService: BsModalService,
    private authService: AuthService,
    private router: Router,
    private alert: AlertService
  ) { }
  ngOnChanges(): void {
    this.maxParticipant = this.eventInfoWithConsent.participantLimit || 5;
  }
  ngOnInit() {
    this.getParticipant();

  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getParticipant() {
    this.participantControllerService.getParticipantUsingGET1(this.registerCompanyId).subscribe(x => {
      this.participantData = x.data;
      this.isDisableContactPerson = this.participantData.filter(f => f.isContact).length > 0;
    });
  }

  drop(event: CdkDragDrop<any[]>) {
    const newParticipantArray = [];
    this.participantData.forEach(val => newParticipantArray.push(Object.assign({}, val)));
    moveItemInArray(newParticipantArray, event.previousIndex, event.currentIndex);
    this.participantControllerService.updateOrderParticipantUsingPOST(newParticipantArray).subscribe(x => {
      moveItemInArray(this.participantData, event.previousIndex, event.currentIndex);
      this.alert.showSuccess(msg.success.dataSaved)

    })
  }

  editClicked(element: ParticipantResponse) {
    if (!this.isCloseRegister) {
      this.addEditParticipantModalRef = this.modalService.show(RegisterCompanyAddEditParticipantModalComponent, {
        initialState: {
          isDisableContactPerson: element.isContact ? false : this.isDisableContactPerson,
          participant: element,
          maxParticipant: this.maxParticipant,
          participantData: this.participantData
        },
        backdrop: 'static',
        class: 'modal-dialog-centered modal-lg',
        keyboard: false
      });
      this.subscription.add(this.addEditParticipantModalRef.content.updateCompleted.subscribe(() => {
        this.getParticipant();
      }));
    }
  }

  addClicked() {
    this.addEditParticipantModalRef = this.modalService.show(RegisterCompanyAddEditParticipantModalComponent, {
      initialState: {
        registerCompanyId: this.registerCompanyId,
        isDisableContactPerson: this.isDisableContactPerson,
        participant: null,
        maxParticipant: this.maxParticipant,
        participantData: this.participantData
      },
      class: 'modal-dialog-centered modal-lg',
      backdrop: 'static',
      keyboard: false
    });
    this.subscription.add(this.addEditParticipantModalRef.content.updateCompleted.subscribe(() => {
      this.getParticipant();
    }));
  }

  rmClicked(event, element: ParticipantResponse) {
    event.stopPropagation();
    this.rmParticipantModalRef = this.modalService.show(RegisterCompanyConfirmRmParticipantModalComponent, {
      class: 'modal-dialog-centered',
      initialState: {
        participant: element
      },
      backdrop: 'static',
      keyboard: false
    });
    this.subscription.add(this.rmParticipantModalRef.content.updateCompleted.subscribe(() => {
      if (element.participantId == this.authService.getAuthDetails().id) {
        this.router.navigate(['login', this.authService.eventAlias, this.authService.loginType]);
        this.authService.clear();
      } else {
        this.getParticipant();
      }
    }));
  }

  public checkShowAddbtn(): boolean {
    return !this.isCloseRegister && (this.checkCanAddParticipant() || this.checkNotHasContactPerson());
  }

  public checkCanAddParticipant(): boolean {
    return this.participantData.filter(f => f.isParticipant || (!f.isContact && !f.isParticipant)).length < this.maxParticipant
  }
  public checkNotHasContactPerson(): boolean {
    return !(this.participantData.filter(f => f.isContact).length > 0);
  }
}

export class ParticipantDataSource extends DataSource<ParticipantResponse> {
  data = new BehaviorSubject<ParticipantResponse[]>([]);

  connect(): Observable<ParticipantResponse[]> {
    return this.data;
  }

  disconnect() {

  }

  update(x: ParticipantResponse[]) {
    this.data.next(x);
  }
}
