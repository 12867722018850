<footer class="text-muted" >
	<div class="container">
		<p class="clearfix blue-grey lighten-2 text-sm-center mb-0 px-2">
			<span id="footer-copy-text" class="float-md-left d-block d-md-inline-block" style="font-size: 0.8rem;">Copyright &copy;
				The Stock Exchange of Thailand.
				<a id="footer-pivacy-policy-link" href="https://www.set.or.th/en/privacy-policy.html" target="_blank" rel="noopener" class="text-bold-800 grey darken-2">Privacy Policy </a>
				The contents contained in this Web Site are provided for informative and educational purpose only. SET
				does not make any representations and hereby disclaims with respect to this Web Site. Retrieving any
				information indicates your acceptance of the
				<a id="footer-tterm-and-condition-link" href="https://www.set.or.th/en/terms-and-conditions.html" target="_blank" rel="noopener" class="text-bold-800 grey darken-2">Terms & Conditions of Use </a>
			</span>
			<!-- <span class="float-md-right d-block d-md-inline-blockd-none d-lg-block">Hand-crafted & Made with
          <i class="ft-heart pink"></i>
      </span> -->
		</p>
	</div>
</footer>
<!--Footer Ends-->