import { InfoComponent } from './info/info.component';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  RegisterCompanyControllerService, APIResultRegisterCompanyResponse,
  RegisterCompanyResponse, EventInfoWithConsentResponse, APIResultListManageCompanySlotResponse, ManageCompanySlotResponse
} from '@ent-regis/entregis-ts-angular';
import { EventService } from '../shared/services/event.service';
import { Subscription } from 'rxjs';
import { AuthService } from '../shared/services/auth.service';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { NgbTabChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { TimetableComponent } from './timetable/timetable.component';
import { BsModalService } from 'ngx-bootstrap';
import Swal from 'sweetalert2';
import { msg } from '../constants/msg';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit, OnDestroy {

  @ViewChild('tabSet', { static: false }) tabSet;
  @ViewChild(TimetableComponent, { static: false }) timetableComponent: TimetableComponent;
  @ViewChild(InfoComponent, { static: false }) infoComponent: InfoComponent;
  slots: ManageCompanySlotResponse[];
  private subscription: Subscription;

  public eventInfo: EventInfoWithConsentResponse
  public registerCompanyInfo: RegisterCompanyResponse;
  public eventAlias: string;
  public registerCompanyId: number;
  public personName: string;
  startDate: Date;
  endDate: Date;
  eventTypeEnum = EventInfoWithConsentResponse.EventTypeEnum;
  leave = true;

  faUser = faUser;

  constructor(
    private route: ActivatedRoute,
    private registerCompanyService: RegisterCompanyControllerService,
    private eventService: EventService,
    private authService: AuthService
  ) { }


  getRegisterCompanyInfo() {
    this.registerCompanyService.getRegisterCompanyByIdUsingGET(this.registerCompanyId)
      .subscribe((res: APIResultRegisterCompanyResponse) => {
        this.registerCompanyInfo = res.data;
      });
  }

  ngOnInit(): void {
    this.subscription = new Subscription();

    this.registerCompanyId = Number(this.route.snapshot.paramMap.get('registerCompanyId'));
    this.getRegisterCompanyInfo();
    this.subscription.add(this.eventService.eventSubject.subscribe(x => {
      this.eventInfo = x;
      if (this.eventInfo) {
        this.startDate = this.eventInfo.startDateRegisterCompany;
        this.endDate = this.eventInfo.endDateRegisterCompany;
      }
    }))
    this.personName = this.authService.getName();
  }

  onUpdateTimeTable() {
    this.timetableComponent.getCompanySlot(this.registerCompanyId);
  }
  onUpdateInfo() {
    this.infoComponent.getParticipant();
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  setCanLeave(event:boolean){
    this.leave = event;
  }

  tabChange($event: NgbTabChangeEvent) {
    if(this.leave){
      if ($event.nextId === 'ngb-tab-0') {
        this.onUpdateTimeTable();
      } else if ($event.nextId === 'ngb-tab-1') {
        this.onUpdateInfo();
      }
    }else{
      $event.preventDefault();   
      Swal.fire({
        confirmButtonColor: '#fbaf47',
        heightAuto: false,
        title: msg.confirmLeave.title,
        text: msg.confirmLeave.text,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: msg.confirmLeave.confirmButtonText,
        cancelButtonText: msg.confirmLeave.cancelButtonText,
        showClass: {
          popup: 'animate__animated animate__fadeIn animate__faster'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOut animate__faster'
        }
      }).then((result) => {
        if (result.value) {
          this.leave = true;
          if ($event.nextId === 'ngb-tab-0') {
            this.onUpdateTimeTable();
          } else if ($event.nextId === 'ngb-tab-1') {
            this.onUpdateInfo();
          }
          this.tabSet.select($event.nextId);
        }
      });
    }
    
  }
}
