import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { LoginType } from 'src/app/constants/login-type.enum';
import * as i0 from "@angular/core";
import * as i1 from "./auth.service";
import * as i2 from "@angular/router";
export class AuthGuardGuard {
    constructor(authService, router) {
        this.authService = authService;
        this.router = router;
    }
    canActivate(route, state) {
        const isAuthenticated = this.authService.isAuthenticated;
        if (typeof isAuthenticated === 'boolean') {
            if (isAuthenticated) {
                return true;
            }
            else {
                const eventAlias = route.paramMap.get('eventAlias');
                if (state.url.startsWith(`/regis/${eventAlias}/company`)) {
                    this.router.navigate(['login', eventAlias, LoginType.company]);
                }
                else if (state.url.startsWith(`/regis/${eventAlias}/investor`)) {
                    this.router.navigate(['login', eventAlias, LoginType.investor]);
                }
                return false;
            }
        }
        else {
            return isAuthenticated;
        }
    }
}
AuthGuardGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuardGuard_Factory() { return new AuthGuardGuard(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.Router)); }, token: AuthGuardGuard, providedIn: "root" });
