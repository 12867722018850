import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EventInfoWithConsentResponse } from '@ent-regis/entregis-ts-angular';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from 'src/app/shared/services/auth.service';
import { EventService } from 'src/app/shared/services/event.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  faSignOutAlt = faSignOutAlt;
  logoURL: string;
  eventName: string;
  eventInfo: EventInfoWithConsentResponse;

  constructor(
    private router: Router,
    private authService: AuthService,
    private eventService: EventService,
    private datePipe:DatePipe
  ) { }

  ngOnInit() {
    this.eventService.eventSubject.subscribe(x => {
      if (x) {
        this.logoURL = x.eventLogoUrl;
        this.eventName = x.eventName;
        this.eventInfo = x;
      }
    })
  }

  logout() {
    this.router.navigate(['login', this.authService.eventAlias, this.authService.loginType]);
    this.authService.clear();
  }

  getPeriodString(eventInfo: EventInfoWithConsentResponse){
    if(eventInfo){
      const tz = eventInfo.eventType == 'THAILAND_FOCUS' ? 'UTC +7' : null;
      const startMonth =  this.datePipe.transform(eventInfo.startDate, 'MMMM', tz)
      const endMonth = this.datePipe.transform(eventInfo.endDate, 'MMMM', tz)
      const year = this.datePipe.transform(eventInfo.endDate,'yyyy',tz);
      const startDay = this.datePipe.transform(eventInfo.startDate, 'dd', tz)
      const endDay = this.datePipe.transform(eventInfo.endDate, 'dd', tz)
      if(startMonth==endMonth){
        return `${startMonth} ${startDay}-${endDay}, ${year}, ${eventInfo.location}`;
      }else{
        return `${startMonth} ${startDay}-${endMonth} ${endDay}, ${year}, ${eventInfo.location}`;
      }
    }
    
  }

}
