import { FormControl } from "@angular/forms";

export function capitalizeFirstLetter(str: string): string {
  const capitalized = str.replace(/^./, str[0].toUpperCase());

  return capitalized;
}

export function keyPressCharacters(event: any): boolean {
  var inp = String.fromCharCode(event.keyCode);
  if (/[a-zA-Z .]/.test(inp)) {
    return true;
  } else {
    event.preventDefault();
    return false;
  }
}

export function checkAndRemoveTitleWording(keyword: string): string {
  if (keyword) {
    if (keyword.toUpperCase().includes('MISS') || keyword.toUpperCase().includes('MRS.')) {
      keyword = keyword.trim().substring(4);
    } else if (keyword.toUpperCase().includes('MS.') || keyword.toUpperCase().includes('MR.')) {
      keyword = keyword.trim().substring(3);
    }
    keyword = keyword.replace(/[^A-Za-z .]/ig, '');
    return keyword.trim();
  } else {
    return keyword;
  }
}

export function equalsIgnoringCase(text: string, other: any): boolean {
  return text.localeCompare(other, undefined, { sensitivity: 'accent' }) === 0;
}