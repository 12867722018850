import { ReplaySubject, BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { checkEqualOtherName } from 'src/app/functions/form.function';
import * as i0 from "@angular/core";
import * as i1 from "@ent-regis/entregis-ts-angular/api/registerCompanyPublicController.service";
import * as i2 from "@ent-regis/entregis-ts-angular/api/registerInvestorController.service";
import * as i3 from "@ent-regis/entregis-ts-angular/api/investorMeetingRequestController.service";
import * as i4 from "../../shared/services/alert.service";
import * as i5 from "../../shared/services/event.service";
import * as i6 from "@ent-regis/entregis-ts-angular/api/investorSlotController.service";
export class RegisterInvestorService {
    constructor(registerCompanyPublicService, registerInvestorControllerService, investorMeetingRequestControllerService, alert, eventService, investorSlotService) {
        this.registerCompanyPublicService = registerCompanyPublicService;
        this.registerInvestorControllerService = registerInvestorControllerService;
        this.investorMeetingRequestControllerService = investorMeetingRequestControllerService;
        this.alert = alert;
        this.eventService = eventService;
        this.investorSlotService = investorSlotService;
        this.registerInvestorId = new ReplaySubject(1);
        this.investorProfile = new ReplaySubject(1);
        this.investorMeetingRequest = new ReplaySubject(1);
        this.approvedCompany = new ReplaySubject(1);
        this.investorSlots = new ReplaySubject(1);
        this.isCloseRegister = new ReplaySubject(1);
        this.canLeavePage$ = new BehaviorSubject(true);
        this.fetchAllData();
        this.registerInvestorId.subscribe((id) => {
            this.investorId = id;
        });
    }
    fetchAllData() {
        this.registerInvestorId.subscribe((id) => {
            this.fetchRegisterInvestorProfileById();
            this.fetchInvestorMeetingRequest();
            this.fetchApprovedCompany();
            this.fetchInvestorSlot();
        });
    }
    fetchRegisterInvestorProfileById() {
        this.registerInvestorId.pipe(first()).subscribe(x => {
            this.getRegisterInvestorProfileById(x, (registerInvestor) => {
                this.investorProfile.next(registerInvestor);
            });
        });
    }
    fetchInvestorMeetingRequest() {
        this.registerInvestorId.pipe(first()).subscribe(x => {
            this.getMeetingRequest(x, (investorMeetingRequest) => {
                this.investorMeetingRequest.next(investorMeetingRequest);
            });
        });
    }
    fetchApprovedCompany() {
        this.eventService.eventSubject.pipe(first(event => event != null)).subscribe(event => {
            this.registerCompanyPublicService.getApprovedRegisterCompanyUsingGET(event.eventAlias).subscribe(res => {
                this.approvedCompany.next(res.data);
            });
        });
    }
    fetchInvestorSlot() {
        return this.investorSlotService.getInvestorSlotUsingGET1()
            .subscribe((reponse) => {
            this.investorSlots.next(reponse.data);
        });
    }
    saveInvestorMeetingRequest(investorMeetingRequest, complete) {
        this.investorMeetingRequestControllerService.createAndUpdateMeetingRequestUsingPOST1(investorMeetingRequest, this.investorId)
            .subscribe(() => {
            if (complete) {
                complete();
            }
        }, (err) => {
            this.alert.showError(err.error.message);
        });
    }
    saveRegisterInvestorInfo(investorProfile, complete) {
        this.registerInvestorControllerService.updateRegisterInvestorUsingPOST(this.investorId, investorProfile)
            .subscribe(() => {
            if (complete) {
                complete();
            }
        }, (err) => {
            this.alert.showError(err.error.message);
        });
    }
    cancelInvestorMeetingRequestById(investorMeetingRequestId, completion) {
        this.investorMeetingRequestControllerService.cancelInvestorMeetingRequestByIdUsingPOST1(investorMeetingRequestId)
            .subscribe((x) => {
            if (completion) {
                completion();
            }
        });
    }
    getRegisterInvestorProfileById(id, completion) {
        this.registerInvestorControllerService.getRegisterInvestorProfileUsingGET(id).subscribe(x => {
            if (completion) {
                completion(x.data);
            }
        });
    }
    getMeetingRequest(id, completion) {
        this.investorMeetingRequestControllerService.getMeetingRequestUsingGET1(id).subscribe((x) => {
            if (completion) {
                completion(x.data);
            }
        });
    }
    convertRegisterInvestorToFCValue(investorProfile) {
        return {
            personal: {
                firstname: investorProfile.firstName,
                lastname: investorProfile.lastName,
                company: investorProfile.companyName,
                jobTitle: investorProfile.jobTitle,
                email: investorProfile.email,
                department: investorProfile.department,
                mobile: {
                    number: investorProfile.mobileNumber,
                    countryCode: investorProfile.countryCode
                },
                extNumber: investorProfile.extNumber,
                country: investorProfile.countryId
            },
            assistant: {
                firstname: investorProfile.assistantFirstName,
                lastname: investorProfile.assistantLastName,
                email: investorProfile.assistantEmail,
                extNumber: investorProfile.assistantExtNumber,
                mobile: {
                    number: investorProfile.assistantMobileNumber,
                    countryCode: investorProfile.assistantCountryCode
                }
            },
            salePerson: {
                hasSalePerson: investorProfile.hasSalePerson,
                name: investorProfile.salePersonName,
                brokerName: investorProfile.salePersonBrokerName,
                brokerId: investorProfile.salePersonBrokerId
            }
        };
    }
    convertFCValueToRequest(fCValue) {
        var salePersonName = checkEqualOtherName(fCValue.salePerson.name) ? fCValue.salePerson.brokerName : fCValue.salePerson.name;
        return {
            assistantCountryCode: fCValue.assistant.mobile ? fCValue.assistant.mobile.countryCode : null,
            assistantEmail: fCValue.assistant.email,
            assistantFirstName: fCValue.assistant.firstname,
            assistantLastName: fCValue.assistant.lastname,
            assistantExtNumber: fCValue.assistant.extNumber,
            assistantMobileNumber: fCValue.assistant.mobile ? fCValue.assistant.mobile.e164Number : null,
            companyName: fCValue.personal.company,
            countryCode: fCValue.personal.mobile ? fCValue.personal.mobile.countryCode : null,
            countryId: fCValue.personal.country,
            email: fCValue.personal.email,
            firstName: fCValue.personal.firstname,
            hasSalePerson: fCValue.salePerson.hasSalePerson,
            jobTitle: fCValue.personal.jobTitle,
            department: fCValue.personal.department,
            lastName: fCValue.personal.lastname,
            mobileNumber: fCValue.personal.mobile ? fCValue.personal.mobile.e164Number : null,
            extNumber: fCValue.personal.extNumber,
            salePersonName: salePersonName,
            brokerName: salePersonName,
            brokerId: fCValue.salePerson.brokerId
        };
    }
}
RegisterInvestorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RegisterInvestorService_Factory() { return new RegisterInvestorService(i0.ɵɵinject(i1.RegisterCompanyPublicControllerService), i0.ɵɵinject(i2.RegisterInvestorControllerService), i0.ɵɵinject(i3.InvestorMeetingRequestControllerService), i0.ɵɵinject(i4.AlertService), i0.ɵɵinject(i5.EventService), i0.ɵɵinject(i6.InvestorSlotControllerService)); }, token: RegisterInvestorService, providedIn: "root" });
