<div class="tableFixHead table-card-form">
  <table id="company-time-table" class="table ">
    <thead class="thead-white-blue">
      <tr>
        <th id="company-time-field-period-column-text" scope="col">Start-End Time</th>
        <th id="company-time-field-participant-column-text" scope="col">Participants</th>
      </tr>
    </thead>
    <tbody class="bg-white">
      <ng-container *ngFor="let slot of slots; let i = index;">
        <tr style="background-color: #0064C5;color: white;">
          <td colspan="5"><strong id="company-time-field-slot-date-text-{{i}}">{{slot.slotDate | date:'MMM dd, yyyy':isEventTypeThailandFocus ? 'UTC +7' :
              null}}</strong></td>
        </tr>
        <tr *ngFor="let period of slot.slotPeriods;let s = index;"
          [ngClass]="(period.available === null && submited) ? 'invalid': 'dd' ">
          <td>
            <input id="company-time-field-period-available-input-{{i}}-{{s}}" type="checkbox" name="available_{{i}}_{{period.slotPeriodId}}" [disabled]="isCloseRegister"
              [checked]="period.available === 'Y'"
              (change)="onSelectionChange(i, period.slotPeriodId, $event.target.checked? 'Y':'N',period)">
            {{period.startTime | date:'H:mm':isEventTypeThailandFocus ? 'UTC +7' : null}} - {{period.endTime | date:'H:mm':isEventTypeThailandFocus ? 'UTC +7' : null}}
          </td>
          <td>
            <div *ngIf="period.available === 'Y'">
              <div *ngFor="let participant of period.participants; let j = index">
                <label class="d-flex flex-column">
                  <div><strong>
                    <span id="company-time-field-firstname-text-{{i}}-{{s}}-{{j}}" class="mr-2">{{participant.firstName}}</span> 
                    <span id="company-time-field-lastname-text-{{i}}-{{s}}-{{j}}">{{participant.lastName}}</span></strong></div>
                   <div id="company-time-field-job-text-{{i}}-{{s}}-{{j}}" class="text-muted">{{participant.jobTitle}}</div>
                </label>
              </div>
            </div>
          </td>
        </tr>
      </ng-container>

    </tbody>
  </table>
</div>
<div class="d-flex justify-content-center">
  <button id="company-time-save-button" type="button" class="btn btn-primary my-2" (click)="save()" [disabled]="isCloseRegister">Save</button>
</div>