import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  RegisterCompanyControllerService,
  RegisterInvestorControllerService,
  ConsentControllerService, EventPublicControllerService, APIResultEventInfoWithConsentResponse, EventInfoWithConsentResponse, APIResultRegisterCompanyResponse
} from '@ent-regis/entregis-ts-angular';
import { msg } from '../constants/msg';
import { BsModalService } from 'ngx-bootstrap/modal';
import { APIResultNumber } from '@ent-regis/entregis-ts-angular/model/aPIResultNumber';
import { ConsentComponent } from '../shared/modules/consent/consent.component';
import { AuthService } from '../shared/services/auth.service';
import { LoginType } from '../constants/login-type.enum';
import { ConsentAcceptanceRequest } from '@ent-regis/entregis-ts-angular/model/consentAcceptanceRequest';
import { AlertService } from '../shared/services/alert.service';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginType: LoginType;
  eventAlias: string;
  eventInfo: EventInfoWithConsentResponse;
  LoginType = LoginType;
  eventTextWarning: SafeHtml = "End of event.";
  canLogin: boolean = false;
  isBeforeEvent: boolean = false;
  faSignOutAlt = faSignOutAlt;

  today = new Date();


  form = new FormGroup({
    username: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required)
  });

  constructor(
    private router: Router,
    private authService: AuthService,
    private registerCompanyService: RegisterCompanyControllerService,
    private registerInvestorService: RegisterInvestorControllerService,
    private modalService: BsModalService,
    private consentService: ConsentControllerService,
    private activatedroute: ActivatedRoute,
    private eventPublicService: EventPublicControllerService,
    private alert: AlertService,
    private datePipe: DatePipe,
    private sanitizer:DomSanitizer
  ) { }

  ngOnInit() {
    this.loginType = this.activatedroute.snapshot.data.loginType;
    this.eventAlias = this.activatedroute.snapshot.paramMap.get('eventAlias');
    this.today.setHours(0, 0, 0, 0);
    // eventService fetch event data after login successfully
    // to this part will fetch event data separately
    this.eventPublicService.getInfoWithConsentUsingGET(this.eventAlias)
      .subscribe((response: APIResultEventInfoWithConsentResponse) => {
        this.eventInfo = response.data;
        if (this.eventInfo.eventType === EventInfoWithConsentResponse.EventTypeEnum.DIGITALROADSHOW && this.loginType == LoginType.company) {
          this.canLogin = false;
          this.eventTextWarning = this.sanitizer.bypassSecurityTrustHtml(`ท่านสามารถเข้าใช้งานระบบ SET Digital Roadshow ผ่านทางระบบ <a style="color:#007bff" href=${environment.setLink}> SETLink </a>`);
        } else {
          var eventStartDate = new Date(this.eventInfo.startDate);
          eventStartDate.setHours(0, 0, 0, 0)
          if (this.today < eventStartDate) {
            this.eventTextWarning = this.sanitizer.bypassSecurityTrustHtml("Event is not available.");
            this.isBeforeEvent = true;
          }
          if (this.eventInfo.isClose) {
            this.canLogin = this.isInPeriod(this.loginType, this.eventInfo);
          } else {
            this.canLogin = true;
          }
        }

      });

  }

  logout() { }

  login() {
    if (!this.form.valid) {
      this.alert.showError(msg.error.allRequiredData);
    } else {
      this.getUserLogin(() => {
        this.checkRole();
      });
    }
  }

  getUserLogin(onSuccess: (data: any) => void) {
    this.authService.getTokenWithUsername(this.form.get('username').value.trim(), this.form.get('password').value.trim(), this.loginType, this.eventInfo.eventId).subscribe(
      (res: any) => {
        const accessToken: string = res.access_token;
        const refreshToken: string = res.refresh_token;
        this.authService.updateToken(accessToken, refreshToken, this.eventAlias, this.loginType);
        onSuccess(res);
      },
      err => {
        this.alert.showError(msg.error.invalidLogin);
      }
    );

  }

  getPeriodString(eventInfo: EventInfoWithConsentResponse) {
    if (eventInfo) {
      const tz = eventInfo.eventType == 'THAILAND_FOCUS' ? 'UTC +7' : null;
      const startMonth = this.datePipe.transform(eventInfo.startDate, 'MMMM', tz)
      const endMonth = this.datePipe.transform(eventInfo.endDate, 'MMMM', tz)
      const year = this.datePipe.transform(eventInfo.endDate, 'yyyy', tz);
      const startDay = this.datePipe.transform(eventInfo.startDate, 'dd', tz)
      const endDay = this.datePipe.transform(eventInfo.endDate, 'dd', tz)
      const location = eventInfo.location ? ", " + eventInfo.location : "";
      if (startMonth == endMonth) {
        return `${startMonth} ${startDay}-${endDay}, ${year}${location}`;
      } else {
        return `${startMonth} ${startDay}-${endMonth} ${endDay}, ${year}${location}`;
      }
    }

  }

  checkRole() {
    if (this.loginType === LoginType.company) {
      this.registerCompanyService.getRegisterCompanyByCurrentAppUserAndEventUsingGET(this.eventAlias).subscribe(
        (res: APIResultRegisterCompanyResponse) => {
          this.getLatestConsent(this.eventInfo.eventId, () => {
            this.navigateToCompany(res);
          })
        }, () => {
          this.alert.showError(msg.error.invalidLogin);
        }
      );
    } else if (this.loginType === LoginType.investor) {
      this.registerInvestorService.getRegisterInvestorIdUsingGET().subscribe(
        (res: APIResultNumber) => {
          this.getLatestConsent(this.eventInfo.eventId, () => {
            this.navigateToInvestor(res)
          });
        }, () => {
          this.alert.showError(msg.error.invalidLogin);
        }
      );
    }
  }

  navigateToCompany(res: APIResultRegisterCompanyResponse) {
    this.router.navigate([`regis/${this.eventAlias}/${this.loginType}`, res.data.registerCompanyId]);
  }

  navigateToInvestor(res: APIResultNumber) {
    this.router.navigate([`regis/${this.eventAlias}/${this.loginType}`, res.data]);
  }

  getLatestConsent(eventId: number, onComplete: () => void) {
    this.consentService.getCheckAllActiveConsentUsingGET(eventId).subscribe(res => {
      const notAcceptedYetConsent = res.data.filter(x => x.didAccept === false);
      if (notAcceptedYetConsent.length > 0) {
        const ref = this.modalService.show(ConsentComponent, {
          initialState: {
            consents: notAcceptedYetConsent,
            onSuccess: (consents: ConsentAcceptanceRequest[]) => {
              this.consentService.acceptConsentUsingPOST(consents).subscribe(x => {
                ref.hide();
                onComplete();
              })
            }
          }
        });
      } else {
        onComplete();
      }
    }, () => {
      this.alert.showError(msg.error.contactAdmin);
    });
  }

  isEndOfRegistration(loginType: LoginType, eventInfo: EventInfoWithConsentResponse) {
    return ((loginType == LoginType.company && eventInfo.isCloseRegister) ||
      (loginType == LoginType.investor && eventInfo.isCloseRegisterInvestor))
  }

  isInPeriod(loginType: LoginType, eventInfo: EventInfoWithConsentResponse): boolean {
    var regisStartDate: Date;
    var regisEndDate: Date;
    if (loginType == LoginType.company) {
      regisStartDate = new Date(eventInfo.startDateRegisterCompany);
      regisEndDate = new Date(eventInfo.endDateRegisterCompany);
      regisStartDate.setHours(0, 0, 0, 0)
      regisEndDate.setHours(0, 0, 0, 0)
    } else {
      regisStartDate = new Date(eventInfo.startDateRegisterInvestor);
      regisStartDate.setHours(0, 0, 0, 0)
      regisEndDate = new Date(eventInfo.endDateRegisterInvestor);
      regisEndDate.setHours(0, 0, 0, 0)
    }
    return (regisStartDate <= this.today && this.today <= regisEndDate) ||
      (regisEndDate < this.today && this.isBeforeEvent);
  }
}
