import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { AuthService } from 'src/app/shared/services/auth.service';
import { catchError, first, switchMap } from 'rxjs/operators';
import { EntRegisAuthService } from 'src/app/shared/services/ent-regis-auth.service';
import { of, throwError } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private authService: AuthService,
    private adminAuthService: EntRegisAuthService
  ) { }

  private handleAuthError(req: HttpRequest<any>, next: HttpHandler, err: HttpErrorResponse): Observable<any> {
    if (err.status === 401) {
      const errorMessage = err.error != null ? err.error.message : err.message;
      console.error(errorMessage);
      this.router.navigateByUrl('/session-timeout');
      return of(null);
    } else {
      return throwError(err);
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.indexOf('refresh') !== -1) {
      return next.handle(req);
    }

    if (!req.headers.has('authorization')) {
      return next.handle(req);
    }

    if (req.url.indexOf('/oauth/token') !== -1) {
      return next.handle(req);
    }

    let accessExpired = this.authService.doesTokenExpired();
    let refreshExpired = this.authService.doesRefreshTokenExpired();
    const isAdmin = req.url.indexOf('admin') !== -1;
    if (isAdmin) {
      accessExpired = this.adminAuthService.doesTokenExpired();
      refreshExpired = this.adminAuthService.doesRefreshTokenExpired();
    }
    if (accessExpired && refreshExpired) {
      if (isAdmin) {
        this.router.navigateByUrl('/login/admin');
      } else {
        this.router.navigateByUrl('/session-timeout');
      }
      return next.handle(req);
    }
    if (accessExpired && !refreshExpired) {
      if (!this.authService.refreshTokenInProgress) {
        return this.authService.refresh().pipe(
          switchMap((authResponse) => {
            if (authResponse) {
              // means refreshing success
              return next.handle(
                req.clone({
                  setHeaders: {
                    Authorization: `Bearer ${this.authService.token}`
                  }
                })
              );
            } else {
              if (isAdmin) {
                this.router.navigateByUrl('/login/admin');
              } else {
              this.router.navigateByUrl('/session-timeout');
              }
              next.handle(req);
            }
          })
        );
      } else {
        return this.authService.accessTokenSubject.pipe(
          first((result) => result !== null),
          switchMap((res) => {
            return next.handle(
              req.clone({
                setHeaders: {
                  Authorization: `Bearer ${res}`
                }
              })
            );
          })
        );
      }
    }
    if (!accessExpired) {
      return next.handle(req).pipe(
        catchError(err => this.handleAuthError(req, next, err)));
    }
  }
}
