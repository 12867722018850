<div class="content" style="border-top: 2px solid #0064c5;margin-top: 1px;">
  <div class="content-container mt-2">
    <div class="content">
      <span id="investor-hotel-remark-text" class="text-danger">Remark : If you would like to stay at {{ event.location }} with special group rate, please follow the link below to make a reservation with hotel directly.</span>
      <div class="card-form mt-2">
        <div class="m-3">
          <h4 id="investor-hotel-location-text">{{ event.location }}</h4>
          <a id="investor-hotel-reservation-link" class="link-blue" target="_blank" href="https://www.marriott.com/events/start.mi?id=1716519936463&key=GRP">
            Hotel reservation link
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <div class="content">
  <div *ngIf="form">
    <fieldset [disabled]="isCloseRegisterInvestor">
      <form [formGroup]=form autocomplete="false">
        <p class="title">
          <a class="mr-3 align-self-center font-weight-bold link" target="_blank" 
            href="https://www.hyatt.com/en-US/group-booking/BANGH/G-TF24">
            {{ event.location }} Hotel reservation link
          </a>
        </p>
        <label class="title">Hotel Info</label>
        <hr>
        <div class="content">
          <div class="row">
            <div class="col-12 mb-3">
              <label for="hotelName">Hotel Name</label>
              <input type="text" id="hotelName" class="form-control" name="hotelName" formControlName="hotelName">
            </div>
            <div class="col-12 col-sm-6 mb-3">
              <label for="checkInDate">Check-in date</label>
              <input type="text" id="checkInDate" class="form-control" name="checkInDate" formControlName="checkInDate"
                placeholder="yyyy-mm-dd" bsDatepicker
                [bsConfig]="{ containerClass: 'theme-orange', dateInputFormat: 'YYYY-MM-DD'}">
            </div>
            <div class="col-12 col-sm-6 mb-3">
              <label for="checkOutDate">Check-Out date</label>
              <input type="text" id="checkOutDate" class="form-control" name="checkOutDate"
                formControlName="checkOutDate" placeholder="yyyy-mm-dd" bsDatepicker
                [bsConfig]="{ containerClass: 'theme-orange', dateInputFormat: 'YYYY-MM-DD'}">
            </div>
            <div class="col-12 col-sm-6 mb-3">
              <label for="arrivalDate">Arrival date</label>
              <input type="text" id="arrivalDate" class="form-control" name="arrivalDate" formControlName="arrivalDate"
                placeholder="yyyy-mm-dd" bsDatepicker
                [bsConfig]="{ containerClass: 'theme-orange', dateInputFormat: 'YYYY-MM-DD'}">
            </div>
            <div class="col-12 col-sm-6 mb-3 row no-gutters">
              <div class="col-12 col-sm-6 pr-lg-4">
                <label for="arrivalTime">Time</label>
                <ng-template #arrivalTimePopTemplate>
                  <timepicker formControlName="arrivalTime" [showMeridian]="false"></timepicker>
                </ng-template>
                <input class="form-control bg-white" [popover]="arrivalTimePopTemplate" [outsideClick]="true"
                  placement="bottom" container="body" [value]="form.get('arrivalTime').value | date:'HH:mm'"
                  [readonly]=true placeholder="HH:mm" />
              </div>
              <div class="col-12 col-sm-6 pt-xs-3">
                <label for="arrivalFight">Flight</label>
                <input type="text" id="arrivalFight" class="form-control" name="arrivalFight"
                  formControlName="arrivalFight">
              </div>
            </div>
            <div class="col-12 col-sm-6 mb-3">
              <label for="departmentDate">Departure date</label>
              <input type="text" id="departmentDate" class="form-control" name="departmentDate"
                formControlName="departmentDate" placeholder="yyyy-mm-dd" bsDatepicker
                [bsConfig]="{ containerClass: 'theme-orange', dateInputFormat: 'YYYY-MM-DD'}">
            </div>
            <div class="col-12 col-sm-6 mb-3 row no-gutters">
              <div class="col-12 col-sm-6 pr-lg-4">
                <label for="departmentTime">Time</label>
                <ng-template #departmentTimePopTemplate>
                  <timepicker formControlName="departmentTime" [showMeridian]="false"></timepicker>
                </ng-template>
                <input class="form-control bg-white" [popover]="departmentTimePopTemplate" [outsideClick]="true"
                  placement="bottom" container="body" [value]="form.get('departmentTime').value | date:'HH:mm'"
                  [readonly]=true placeholder="HH:mm" />
              </div>
              <div class="col-12 col-sm-6 pt-xs-3">
                <label for="departmentFight">Flight</label>
                <input type="text" id="departmentFight" class="form-control" name="departmentFight"
                  formControlName="departmentFight">
              </div>
            </div>
            <div class="col-12 col-sm-6 mb-3">
              <label for="dietaryRequirement">Dietary Requirements</label>
              <select id="dietaryRequirement" class="form-control" name="dietaryRequirement"
                formControlName="dietaryId">
                <option value="" [disabled]="!form.value.dietaryId">Other dietary</option>
                <hr>
                <option *ngFor="let item of dietaryList" [value]="item.dietaryId">{{item.dietaryName}}
                </option>
              </select>
            </div>
            <div class="col-12 col-sm-6 mb-3" *ngIf="!form.value.dietaryId">
              <label for="otherDietary">Other Dietary</label>
              <input type="text" id="otherDietary" class="form-control" name="otherDietary"
                formControlName="otherDietary">
            </div>
          </div>
          <button *ngIf="!isCloseRegisterInvestor" type="button" class="btn btn-primary" (click)="onSave()" [disabled]="form.pristine">SAVE</button>
        </div>
      </form>
    </fieldset>
  </div>
</div> -->