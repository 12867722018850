<div *ngIf="scheduleData" class="common-img default-img" style="min-height: 100%;" [style.background-image]="scheduleData.bgUrl ? 'url(' + scheduleData.bgUrl + ')': 
'url(../../assets/images/defaultBg1.png)'">
  <div>
    <div class="row bg-white banner-block">
      <div class="container">
        <img *ngIf="scheduleData.eventLogoUrl" id="schedule-investor-logo-image" height="80px" class="mt-3 mb-1"
          [src]="scheduleData.eventLogoUrl">
        <h4 *ngIf="!scheduleData.eventLogoUrl" id="schedule-investor-event-name-text" class="mt-4 mb-3">
          {{scheduleData.eventName}}</h4>
        <p id="schedule-investor-period-text" class="text-muted mb-3">{{getPeriodString(scheduleData)}}</p>
      </div>

    </div>
    <div class="pt-3 container">
      <h4 id="schedule-investor-investor-name-text">{{scheduleData.name}}</h4>
      <div class="d-flex justify-content-between">
        <p id="schedule-investor-company-name-text" class="text-muted">{{scheduleData.companyName}}</p>
        <!-- <p *ngIf="scheduleData.eventType === eventTypeEnum.THAILANDFOCUS" class="ml-auto text-muted">Location :
          {{scheduleData.location || '-'}}
        </p> -->
        <button type="button" class="btn btn-outline-light-blue no-print" (click)="convertToPDF()">Export PDF</button>
      </div>
      <!-- <h5>{{scheduleData.eventName}}</h5>
      <p *ngIf="scheduleData.eventType === eventTypeEnum.THAILANDFOCUS">Location : {{scheduleData.location || '-'}}</p> -->
      <div id="print-section"  class="table-responsive-lg mt-3 table-card-form tableScheduleFixHead">
        <table class="table">
          <thead class="thead-white">
            <tr class="text-title">
              <th scope="col" style="width: 15%;">DATE/TIME</th>
              <th id="schedule-investor-field-roome-column-text" scope="col">ROOM</th>
              <th id="schedule-investor-field-company-column-text" scope="col">COMPANY</th>
              <th id="schedule-investor-field-presentation-column-text" scope="col" *ngIf="haveAtLeaseOnePresentation">
                PRESENTATION</th>
              <th id="schedule-investor-field-preseentor-column-text" scope="col">PRESENTER</th>
              <th id="schedule-investor-field-attendee-column-text" scope="col">ATTENDEES</th>
              <th id="schedule-investor-field-meeting-type-column-text" scope="col" class="text-center"
                style="width: 15%;">MEETING TYPE</th>
              <!-- <th scope="col" class="text-center"></th> -->
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let slot of scheduleData.slots; let slotIndex = index;">
              <tr *ngIf="slot.slotPeriods.length > 0" style="background-color: #0064C5;color: white;">
                <td colspan="8"><strong id="schedule-investor-field-slot-date-text-{{slotIndex}}"
                    class="text-title">{{slot.slotDate | date:'mediumDate': scheduleData.eventType
                    === eventTypeEnum.THAILANDFOCUS ? 'UTC +7' : ''}}</strong></td>
              </tr>
              <ng-container *ngFor="let slotPeriod of slot.slotPeriods;let slotPeriodIndex = index;">
                <tr>
                  <td>
                    <strong id="schedule-investor-field-period-text-{{slotIndex}}-{{slotPeriodIndex}}"
                      class="text-title">{{slotPeriod.startTime | date:'HH:mm': scheduleData.eventType ===
                      eventTypeEnum.THAILANDFOCUS ? 'UTC +7' : ''}} - {{slotPeriod.endTime | date:'HH:mm':
                      scheduleData.eventType ===
                      eventTypeEnum.THAILANDFOCUS ? 'UTC +7' : ''}}</strong>
                    <span id="schedule-investor-field-timezone-text-{{slotIndex}}-{{slotPeriodIndex}}"
                      *ngIf="scheduleData.eventType === eventTypeEnum.THAILANDFOCUS"
                      class="text-muted"><br>{{slotPeriod.endTime |
                      date:'zzz': scheduleData.eventType ===
                      eventTypeEnum.THAILANDFOCUS ? 'UTC +7' : ''}} (Bangkok Time)</span>
                  </td>
                  <td class="text-title">
                    <div>
                      <div id="schedule-investor-field-room-link-text-{{slotIndex}}-{{slotPeriodIndex}}">
                        {{slotPeriod.room}}</div>
                      <a *ngIf="slotPeriod.roomLink" [tooltip]="slotPeriod.roomDesc" id="schedule-investor-field-meeting-room-link-text-{{slotIndex}}-{{slotPeriodIndex}}"
                        target="_blank" [href]="slotPeriod.roomLink" class="link-blue">Meeting Link <img
                          src="assets/images/video.svg" alt=""></a>
                    </div>
                    <ng-template #elseBlock>
                      <div id="schedule-investor-field-room-text-{{slotIndex}}-{{slotPeriodIndex}}"
                        [tooltip]="slotPeriod.roomDesc">{{slotPeriod.room}}</div>
                    </ng-template>
                  </td>
                  <td><strong id="schedule-investor-field-room-text-{{slotIndex}}-{{slotPeriodIndex}}"
                      class="text-title">{{slotPeriod.presenter}}</strong></td>
                  <!-- <td *ngIf="haveAtLeaseOnePresentation">
                    <a *ngIf="slotPeriod.presentationLink" target="_blank" class="link-blue" href="{{slotPeriod.presentationLink}}">Download</a>
                  </td> -->
                  <td class="text-title" *ngIf="haveAtLeaseOnePresentation">
                    <a *ngIf="slotPeriod.presentationLink" id="schedule-investor-field-presentation-link-{{slotIndex}}-{{slotPeriodIndex}}" target="_blank"
                      class="link-blue" href="{{slotPeriod.presentationLink}}">Download <fa-icon [icon]="faDownload"
                        class="pr-2"></fa-icon></a>
                  </td>
                  <td>
                    <div *ngFor="let participant of slotPeriod.participants; let participantIndex = index;">
                      <label class="d-flex flex-column">
                        <div><strong
                            id="schedule-investor-field-participant-name-text-{{slotIndex}}-{{slotPeriodIndex}}-{{participantIndex}}">{{participant.name}}</strong>
                        </div>
                        <div
                          id="schedule-investor-field-participant-text-job-{{slotIndex}}-{{slotPeriodIndex}}-{{participantIndex}}"
                          class="text-muted">{{participant.jobTitle}}</div>
                      </label>
                    </div>
                  </td>
                  <td>
                    <div *ngFor="let attendee of slotPeriod.attendees; let i = index">
                      <div *ngIf="i < 3">
                        <label [className]="attendee === scheduleData.name ? 
                        'text-blue-sky d-flext flex-column': 'd-flext flex-column'">
                          <div id="schedule-investor-field-attendee-text-{{slotIndex}}-{{slotPeriodIndex}}-{{i}}">
                            <strong>{{attendee}}</strong>
                          </div>
                        </label>
                        <a (click)="slotPeriod['isShow'] = true;"
                          *ngIf="slotPeriod.attendees.length > 3 && i == 2 && !slotPeriod['isShow']"
                          class="link-blue no-print"><br>
                          <small
                            id="schedule-investor-field-attendee-show-more-text-{{slotIndex}}-{{slotPeriodIndex}}-{{i}}"
                            class="pl-2">Show more</small></a>
                      </div>
                      <div *ngIf="i >= 3 && slotPeriod['isShow']">
                        <label [className]="attendee === scheduleData.name ? 
                        'text-blue-sky d-flext flex-column': 'd-flext flex-column'">
                          <div id="schedule-investor-field-attendee-text-{{slotIndex}}-{{slotPeriodIndex}}-{{i}}">
                            <strong>{{attendee}}</strong>
                          </div>
                        </label>
                        <a (click)="slotPeriod['isShow'] = false;" *ngIf="i === (slotPeriod.attendees.length - 1)"
                          class="link-blue no-print"><br><small
                            id="schedule-investor-field-attendee-show-less-text-{{slotIndex}}-{{slotPeriodIndex}}-{{i}}"
                            class="pl-2">Show less</small></a>
                      </div>
                    </div>
                  </td>
                  <td id="schedule-investor-field-meeting-type-text-{{slotIndex}}-{{slotPeriodIndex}}"
                    class="text-center"><span [className]="slotPeriod.meetingType === 'VIRTUALGROUP' ? 
                    'bg-primary font-weight-bold px-2 py-1 rounded': 'px-2 py-1'">{{slotPeriod.meetingType |
                      meetingType}}</span></td>
                  <!-- <td class="text-center">
                    <div class="btn-group" dropdown>
                      <button id="schedule-investor-add-calendar-button-{{slotIndex}}-{{slotPeriodIndex}}"
                        dropdownToggle type="button" class="btn btn-sm btn-outline-light-blue dropdown-toggle"
                        aria-controls="dropdown-basic">
                        <fa-icon [icon]="faCalendar" class="pr-1"></fa-icon> Add to calendar <span class="caret"></span>
                      </button>
                      <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu"
                        aria-labelledby="button-basic">
                        <li role="menuitem"><a
                            id="schedule-investor-add-calendar-apple-button-{{slotIndex}}-{{slotPeriodIndex}}"
                            class="dropdown-item" (click)="addAppleEvent(slotPeriod, slot.slotDate)">
                            <fa-icon [icon]="faApple" class="pr-2"></fa-icon> Apple</a></li>
                        <li role="menuitem"><a
                            id="schedule-investor-add-calendar-google-button-{{slotIndex}}-{{slotPeriodIndex}}"
                            class="dropdown-item" (click)="addGoogleEvent(slotPeriod, slot.slotDate)">
                            <fa-icon [icon]="faGoogle" class="pr-1"></fa-icon> Gmail</a></li>
                        <li role="menuitem"><a
                            id="schedule-investor-add-calendar-office-button-{{slotIndex}}-{{slotPeriodIndex}}"
                            class="dropdown-item" (click)="addOffice365Event(slotPeriod, slot.slotDate)">
                            <fa-icon [icon]="faOutlook" class="pr-1"></fa-icon> Outlook</a></li>
                        <li role="menuitem"><a
                            id="schedule-investor-add-calendar-yahoo-button-{{slotIndex}}-{{slotPeriodIndex}}"
                            class="dropdown-item" (click)="addYahooEvent(slotPeriod, slot.slotDate)">
                            <fa-icon [icon]="faYahoo" class="pr-1"></fa-icon> Yahoo</a></li>
                      </ul>
                    </div>
                  </td> -->
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="mx-5 px-5 pt-4" *ngIf="isErr">
    <h6 id="schedule-investor-line1-error-text">ERROR</h6>
    <h3 id="schedule-investor-line2-error-text">PAGE NOT FOUND</h3>
    <p id="schedule-investor-line3-error-text">The page you requested was not found.</p>
  </div>
</div>
<!-- <div class="container pt-4 position-relative" *ngIf="scheduleData">
  <img class="position-absolute" style="top: 0; right: 0;" height="80px" [src]="scheduleData.eventLogoUrl">
  <h4>{{scheduleData.name}}</h4>
  <p class="text-muted">{{scheduleData.companyName}}</p>
  <hr>

</div> -->