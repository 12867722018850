import { LoginComponent } from './login/login.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { CompanyComponent } from './company/company.component';
import { InvestorComponent } from './investor/investor.component';
import { MeetingRequestComponent } from './investor/meeting-request/meeting-request.component';
import { AvailableTimeComponent } from './investor/available-time/available-time.component';
import { InvestorInfoComponent } from './investor/investor-info/investor-info.component';
import { HotelComponent } from './investor/hotel/hotel.component';
import { ScheduleCompanyComponent } from './schedule-company/schedule-company.component';
import { ScheduleInvestorComponent } from './schedule-investor/schedule-investor.component';
import { AuthGuardGuard } from './shared/services/auth-guard.guard';
import { LoginType } from './constants/login-type.enum';
import { LoginAdminComponent } from './login-admin/login-admin.component';
import { UnauthorizeComponent } from './unauthorize/unauthorize.component';

const routes: Routes = [
  { path: 'register-company/:eventAlias', loadChildren: () => import('./register-company/register-company.module').then(m => m.RegisterCompanyModule) },
  { path: 'register-investor/:eventAlias', loadChildren: () => import('./register-investor/register-investor.module').then(m => m.RegisterInvestorModule) },
  { path: 'register-seminar-investor/:eventAlias', loadChildren: () => import('./register-seminar-investor/register-seminar-investor.module').then(m => m.RegisterSeminarInvestorModule) },
  { path: 'external/company/:token', component: ScheduleCompanyComponent },
  { path: 'external/investor/:token', component: ScheduleInvestorComponent },
  { path: 'login/:eventAlias/company', component: LoginComponent, data: { loginType: LoginType.company} },
  { path: 'login/:eventAlias/investor', component: LoginComponent, data: { loginType: LoginType.investor} },
  { path: 'login/admin', component: LoginAdminComponent, data: { loginType: LoginType.admin} },
  {
    path: 'regis/:eventAlias', component: LayoutComponent, canActivate: [AuthGuardGuard],
    children: [
      { path: 'company/:registerCompanyId', component: CompanyComponent },
      {
        path: 'investor/:registerInvestorId', component: InvestorComponent,
        children: [
          { path: '', redirectTo: 'meeting-request', pathMatch: 'full' },
          { path: 'meeting-request', component: MeetingRequestComponent },
          { path: 'available-time', component: AvailableTimeComponent },
          { path: 'investor-info', component: InvestorInfoComponent },
          { path: 'hotel', component: HotelComponent },
        ]
      },
    ]
  },
  { path: 'checkin/:eventAlias', loadChildren: () => import('./check-in/check-in.module').then(m => m.CheckInModule) },
  { path: 'session-timeout', component: UnauthorizeComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
