import { environment } from './environment';

export const config = {
    adalConfig: {
        tenant: environment.tenant,
        clientId: environment.clientId,
        cacheLocation: 'localStorage',
        redirectUri: environment.redirectUrl,
        endpoints: {
            'https://graph.microsoft.com': 'graph',
        }
    },
    apiEndPoint: environment.baseUrl
};
