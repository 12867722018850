import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { ScheduleCompanyResponse, SchedulePublicControllerService } from '@ent-regis/entregis-ts-angular';
import { map } from 'rxjs/operators';
import { b64toBlob, saveFileBlob } from '../functions/file.function';

@Component({
  selector: 'app-schedule-company',
  templateUrl: './schedule-company.component.html',
  styleUrls: ['./schedule-company.component.scss']
})
export class ScheduleCompanyComponent implements OnInit {
  public scheduleData: ScheduleCompanyResponse;
  public eventTypeEnum = ScheduleCompanyResponse.EventTypeEnum;
  public isErr: boolean = false;
  private token: string;

  constructor(
    private route: ActivatedRoute,
    private scheduleService: SchedulePublicControllerService,
    private datePipe:DatePipe
  ) { 
    this.route.paramMap
      .pipe(map((x: ParamMap) => x.get('token')))
      .subscribe((x: string) => {
        if (x) {
          this.token = x;
          this.scheduleService.getScheduleCompanyInfoUsingGET(this.token)
          .subscribe( result => {
            this.scheduleData = result.data;
          }, () => {
            this.isErr = true;
          });
        }
      });
  }

  ngOnInit() {
  }

  getPeriodString(scheduleData: ScheduleCompanyResponse){
    if(scheduleData){
      const tz = scheduleData.eventType == 'THAILAND_FOCUS' ? 'UTC +7' : null;
      const startMonth =  this.datePipe.transform(scheduleData.startDate, 'MMMM', tz)
      const endMonth = this.datePipe.transform(scheduleData.endDate, 'MMMM', tz)
      const year = this.datePipe.transform(scheduleData.endDate,'yyyy',tz);
      const startDay = this.datePipe.transform(scheduleData.startDate, 'dd', tz)
      const endDay = this.datePipe.transform(scheduleData.endDate, 'dd', tz)
      const location = scheduleData.location ? ", " + scheduleData.location : "";
      if(startMonth==endMonth){
        return `${startMonth} ${startDay}-${endDay}, ${year}${location}`;
      }else{
        return `${startMonth} ${startDay}-${endMonth} ${endDay}, ${year}${location}`;
      }
    }
  }

  print(): void {
    window.print();
  }

  public convertToPDF() {
    const offset = (new Date().getTimezoneOffset() / -60);
    this.scheduleService.generatePDFScheduleCompanyUsingGET(offset, this.token).subscribe((result) => {
      const blob = b64toBlob(result.data.data, result.data.fileType);
      saveFileBlob(blob, result.data.fileName);
    });
  }

}

