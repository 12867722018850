<!-- <div class="formcontrol">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="table-responsive-lg">
      <table class="table">
        <thead >
          <tr>
            <th scope="col" style="width: 35%;">CORPORATES</th>
            <th scope="col" style="width: 30%;">SECTOR</th>
            <th scope="col" class="text-center" style="width: 10%;">INTEREST</th>
            <th scope="col" class="text-center" style="width: 10%;">MEETING TYPE</th>
            <th scope="col" class="text-center" style="width: 10%;">PRIORITY</th>
          </tr>
        </thead>
        <tbody formArrayName="meetings">
          <ng-container [formGroupName]="i" *ngFor="let item of formArray.controls; let i = index;">
            <tr>
              <td class="border-bottom-0 text-nowrap">
                <span class="font-weight-bold">{{ i + 1 }}</span>
                &nbsp;
                <span *ngIf="item.value.symbol" class="font-weight-bold">({{item.value.symbol}})</span>
                &nbsp;&nbsp;
                <span>{{item.value.registerCompanyName}}</span>
                &nbsp;
                <a href="JavaScript:void(0)" (click)="openCompanyDescription(item.value); $event.stopPropagation();">
                  <fa-icon [icon]="faQuestionCircle"></fa-icon>
                </a>
              </td>
              <td class="border-bottom-0">
                {{item.value.registerCompanySector}}
              </td>
              <td class="border-bottom-0 text-center">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="checkbox" formControlName="interest"
                    (change)="onCheckboxChange2(item)">
                </div>
              </td>
              <td class="border-bottom-0">
                <ng-select placeholder="Select meeting type" formControlName="meetingType">
                  <ng-option *ngFor="let meeting of meetingTypes" [value]="meeting.type">{{ meeting.type | meetingType}}
                  </ng-option>
                </ng-select>
              </td>
              <td class="border-bottom-0">
                <ng-select placeholder="Select priority" formControlName="priority">
                  <ng-option *ngFor="let priority of priorities" [value]="priority.level">{{ priority.level }}
                  </ng-option>
                </ng-select>
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <div class="d-flex flex-column flex-wrap" *ngIf="approvedCompanies">
                  <div class="border border-primary p-1 rounded mb-2 mr-auto"
                    *ngFor="let slotString of generateArrayOfSlotStringInSlotPeriodIds(approvedCompanies[item.value.registerCompanyId].slotPeriods)">
                    <span>{{slotString.date | date:'dd MMM':isEventTypeThailandFocus ? 'UTC +7' : null}}</span>
                    &bull;
                    <span *ngFor="let slotPeriodString of slotString.slotPeriodStrings; let i = index">
                      <span *ngIf="i > 0" class="text-secondary">|</span>
                      {{slotPeriodString}}
                    </span>
                  </div>
                </div>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
      <div>
        <button class="btn btn-primary mt-2" type="submit" *ngIf="!isCloseRegisterInvestor">NEXT</button>
      </div>
    </div>
  </form>
</div> -->
<form [formGroup]="form" (ngSubmit)="onSubmit()" style="border-top: 2px solid #0064c5;margin-top: 1px;">
  <div class="w-100 p-3" style="height: calc(30vh + 200px); overflow: auto">
    <div formArrayName="meetings">
      <ng-container [formGroupName]="i" *ngFor="let item of formArray.controls; let i = index;">
        <div class="d-flex flex-row rounded company-box p-2 mb-2"
          [ngClass]="{'bg-company-box-highlight':item.value.interest === true}" (click)="onCheckboxChange(i)">
          <div class="mr-2">
            <input id="investor-meeting-request-field-interest-input-{{i}}" class="check-orange mt-1" type="checkbox" formControlName="interest"
              (click)="onCheckboxChange(i,$event)">
          </div>
          <div class="d-flex flex-column col-4 pr-0">
            <div>
              <span id="investor-meeting-request-field-no-text-{{i}}" class="font-weight-bold">{{ i + 1 }}</span>
              &nbsp;
              <span id="investor-meeting-request-field-symbol-text-{{i}}" *ngIf="item.value.symbol" class="font-weight-bold">({{item.value.symbol}})</span>
              &nbsp;&nbsp;
              <span id="investor-meeting-request-field-company-name-text-{{i}}">{{item.value.registerCompanyName}}</span>
              &nbsp;
              <a href="JavaScript:void(0)" (click)="openCompanyDescription(item.value); $event.stopPropagation();">
                <img id="investor-meeting-request-field-book-image-{{i}}" src="/assets/images/book_icon.png" width="16px">
              </a>
            </div>
            <div>
              <span id="investor-meeting-request-field-company-sector-text-{{i}}" *ngIf="item.value.registerCompanySector" class="font-weight-lighter">Sector:
                {{item.value.registerCompanySector}}</span>
            </div>
            <div class="mb-3"></div>
            <div *ngIf="item.value.interest === true" class="d-flex box-type mb-2" (click)="$event.stopPropagation()">
              <div class="d-flex flex-column">
                <label id="investor-meeting-request-field-meeting-type-text-{{i}}" class="col-form-label"><strong>Meeting Type</strong></label>
                <div>
                  <div class="form-check form-check-inline">
                    <label class="form-check-label">
                      <input id="investor-meeting-request-field-meeting-type-one-on-one-input-{{i}}" class="form-check-input check-orange" type="radio" formControlName="meetingType"
                        [value]="MeetingTypeEnum.ONEONONE" (change)="setCanLeave(false)"> 1 on 1
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <label class="form-check-label">
                      <input id="investor-meeting-request-field-meeting-type-group-input-{{i}}" class="form-check-input check-orange" type="radio" formControlName="meetingType"
                        [value]="MeetingTypeEnum.GROUP" (change)="setCanLeave(false)"> Group
                    </label>
                  </div>
                  <div class="form-check form-check-inline" *ngIf="isEventTypeThailandFocus">
                    <label class="form-check-label">
                      <input id="investor-meeting-request-field-meeting-type-virtual-input-{{i}}" class="form-check-input check-orange" type="radio" formControlName="meetingType"
                        [value]="MeetingTypeEnum.VIRTUALGROUP" (change)="setCanLeave(false)"> Virtual (Group)
                    </label>
                  </div>
                </div>
                <label id="investor-meeting-request-field-priority-text-{{i}}" class="col-form-label"><strong>Priority</strong></label>
                <div>
                  <div class="form-check form-check-inline">
                    <label class="form-check-label">
                      <input id="investor-meeting-request-field-priority-high-input-{{i}}" class="form-check-input check-orange" type="radio" formControlName="priority"
                        [value]="MeetingPriorityEnum.HIGH" (change)="setCanLeave(false)"> High
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <label class="form-check-label">
                      <input id="investor-meeting-request-field-priority-meduim-input-{{i}}" class="form-check-input check-orange" type="radio" formControlName="priority"
                        [value]="MeetingPriorityEnum.MEDIUM" (change)="setCanLeave(false)"> Medium
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <label class="form-check-label">
                      <input id="investor-meeting-request-field-priority-low-input-{{i}}" class="form-check-input check-orange" type="radio" formControlName="priority"
                        [value]="MeetingPriorityEnum.LOW" (change)="setCanLeave(false)"> Low
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex col-8 flex-wrap">
            <div class="col-4 mb-2 line-left"
              *ngFor="let slotString of generateArrayOfSlotStringInSlotPeriodIds(approvedCompanies[item.value.registerCompanyId].slotPeriods); let j = index;">
              <div id="investor-meeting-request-field-slot-text-{{i}}-{{j}}" class="text-day">{{slotString.date | date:'MMM dd, yyyy':isEventTypeThailandFocus ? 'UTC +7' : null}}
              </div>
              <!-- <div *ngFor="let slotPeriodString of slotString.slotPeriodStrings; let i = index" class="d-flex">
               
                {{slotPeriodString}}
              </div> -->
              <div class="d-flex" [class.mb-2]="(slotString.slotPeriodMorningString != null || slotString.slotAfternoonPeriodsString != null)">
                <div 
                    class="col-6 p-0" *ngIf="slotString.slotPeriodMorningString && slotString.slotPeriodMorningString.length > 0">
                    <!-- <div *ngIf="i > 0" class="text-secondary"></div> -->
                    <div id="investor-meeting-request-field-period-morning-text-{{i}}-{{j}}-{{k}}" class="d-flex flex-column" *ngFor="let slotPeriodString of slotString.slotPeriodMorningString; let k = index">
                        {{slotPeriodString}}
                    </div>
                </div>
                <div 
                    class="col-6 p-0">
                    <!-- <div *ngIf="i > 0" class="text-secondary"></div> -->
                    <div id="investor-meeting-request-field-period-afternoon-text-{{i}}-{{j}}-{{k}}" class="d-flex flex-column" *ngFor="let slotPeriodString of slotString.slotAfternoonPeriodsString; let k = index">
                        {{slotPeriodString}}
                    </div>
                </div>
            </div>
            <div *ngIf="slotString.slotPeriodMorningStringVirtual.length > 0 || slotString.slotAfternoonPeriodsStringVirtual.length" [ngClass]="(slotString.slotPeriodMorningString?.length == 0 && slotString.slotAfternoonPeriodsString?.length == 0)?'mt-5':'mt-4'">
                <div id="investor-meeting-request-field-virtual-slot-text-{{i}}-{{j}}" class="badge badge-orange mb-2">Virtual</div>
                <div class="d-flex">
                    <div 
                        class="col-6 p-0" *ngIf="slotString.slotPeriodMorningStringVirtual && slotString.slotPeriodMorningStringVirtual.length > 0">
                        <!-- <div *ngIf="i > 0" class="text-secondary"></div> -->
                        <div id="investor-meeting-request-field-virtual-period-morning-text-{{i}}-{{j}}-{{k}}" class="d-flex flex-column" *ngFor="let slotPeriodString of slotString.slotPeriodMorningStringVirtual; let k = index">
                            {{slotPeriodString}}
                        </div>
                    </div>
                    <div 
                        class="col-6 p-0">
                        <!-- <div *ngIf="i > 0" class="text-secondary"></div> -->
                        <div id="investor-meeting-request-field-virtual-afternoon-morning-text-{{i}}-{{j}}-{{k}}" class="d-flex flex-column" *ngFor="let slotPeriodString of slotString.slotAfternoonPeriodsStringVirtual; let k = index">
                            {{slotPeriodString}}
                        </div>
                    </div>
                </div>
            </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="d-flex justify-content-center">
    <button id="investor-meeting-request-next-button" class="btn btn-primary mt-2" type="submit" *ngIf="!isCloseRegisterInvestor">SAVE</button>
  </div>
</form>