<div class="container" *ngIf="registerCompanyInfo">
  <h4 id="company-person-name-text">{{personName}}</h4>
  <div class="d-flex">
    <p id="company-name-text" class="text-muted">{{registerCompanyInfo.companyName}}</p>
  </div>
  <!-- <blockquote class="heading blockquote">
    <p class="mb-0"><span class="h1">{{personName}}</span></p>
    <footer class="blockquote-footer">{{registerCompanyInfo.companyName}}</footer>
  </blockquote> -->
  <p *ngIf="registerCompanyInfo.isCloseRegister" id="company-remark-text" class="text-blue">Remark: Unable to edit 'Available Time, Registration Data' after registration period {{startDate | date: 'MMM dd, yyyy'}} - {{endDate | date: 'MMM dd, yyyy'}}</p>
  

  <ngb-tabset [destroyOnHide]="false" #tabSet="ngbTabset" (tabChange)="tabChange($event)">
    <ngb-tab title="Available Time">
      <ng-template ngbTabContent>
        <!-- <br>
        <p>Select your company's available time. </p> -->
        <app-timetable [registerCompanyId]="registerCompanyId" 
        [isCloseRegister]="registerCompanyInfo.isCloseRegister"
        (setCanLeave)="setCanLeave($event)"
        [isEventTypeThailandFocus]="eventInfo.eventType === eventTypeEnum.THAILANDFOCUS"></app-timetable>
      </ng-template>
    </ngb-tab>
    <ngb-tab title="Registration Data">
      <ng-template ngbTabContent>
        <app-info [registerCompanyId]="registerCompanyId" [isCloseRegister]="registerCompanyInfo.isCloseRegister" [eventInfoWithConsent]="eventInfo"></app-info>
      </ng-template>
    </ngb-tab>
    <ngb-tab title="Presentation">
      <ng-template ngbTabContent>
        
        <app-presentation [registerCompanyInfo]="registerCompanyInfo" (onUpload)="getRegisterCompanyInfo()"></app-presentation>
      </ng-template>
    </ngb-tab>
  </ngb-tabset>

</div>