import { Injectable } from '@angular/core';
import { Configuration } from '@ent-regis/entregis-ts-angular';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { appConst } from 'src/app/constants/app-const';
import jwt_decode from 'jwt-decode';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, map } from 'rxjs/operators';
import { LoginType } from 'src/app/constants/login-type.enum';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  refreshTokenInProgress = false;
  accessTokenSubject = new BehaviorSubject<string>(null);
  refreshTokenSubject = new BehaviorSubject<string>(null);
  eventAliasSubject = new BehaviorSubject<string>(null);
  
  get token(): string {
    return localStorage.getItem(appConst.accessTokenKey);
  }

  get refreshToken(): string {
    return localStorage.getItem(appConst.refreshTokenKey);
  }

  get eventAlias(): string {
    return localStorage.getItem(appConst.eventAliasKey);
  }

  get loginType(): LoginType {
    const loginTypeString = localStorage.getItem(appConst.loginTypeKey);
    return LoginType[loginTypeString];
  }

  constructor(
    private apiConfig: Configuration,
    private httpClient: HttpClient
  ) {
    this.accessTokenSubject.next(this.token);
    this.refreshTokenSubject.next(this.refreshToken);
    this.eventAliasSubject.next(this.eventAlias);
  }

  get isAuthenticated(): Observable<boolean> | boolean {
    if (this.token === null || this.token === undefined) {
      return false;
    } else if (this.doesTokenExpired()) {
      if (this.doesRefreshTokenExpired()) {
        return false;
      } else {
        return this.refresh();
      }
    } else {
      return true;
    }
  }

  doesTokenExpired(): boolean {
    return this.token == null
      ? true
      : (jwt_decode(this.token) as any).exp < Date.now() / 1000;
  }

  doesRefreshTokenExpired(): boolean {
    return this.refreshToken == null
      ? true
      : (jwt_decode(this.refreshToken) as any).exp < Date.now() / 1000;
  }

  getUsername(): string {
    return this.token == null ? null : (jwt_decode(this.token) as any).user_name;
  }

  getAuthDetails(): AuthDetails {
    return this.token == null ? null : (jwt_decode(this.token) as any).details;
  }

  getName(): string {
    if (this.token == null) {
      return null;
    } else {
      const decodedToken = (jwt_decode(this.token) as any);
      return `${decodedToken.details.name}`
    }
  }

  getRoles(): string[] {
    return this.token == null ? null : (jwt_decode(this.token) as any).authorities;
  }

  updateToken(token: string, refreshToken: string, eventAlias: string, loginType: LoginType) {
    this.accessTokenSubject.next(token);
    this.refreshTokenSubject.next(refreshToken);
    this.eventAliasSubject.next(eventAlias);

    localStorage.setItem(appConst.accessTokenKey, token);
    localStorage.setItem(appConst.refreshTokenKey, refreshToken);
    localStorage.setItem(appConst.eventAliasKey, eventAlias);
    localStorage.setItem(appConst.loginTypeKey, loginType);

    this.updateSwaggerToken();
  }

  updateSwaggerToken() {
    this.apiConfig.apiKeys.Authorization = 'Bearer ' + this.token;
  }

  refresh() {
    const refreshToken = this.refreshToken;
    const eventAlias = this.eventAlias;
    const loginType = this.loginType;

    this.clear();
    this.refreshTokenInProgress = true;

    return this.getTokenWithRefresh(refreshToken)
      .pipe(
        map((x: any) => {
          const accessToken: string = x.access_token;
          const refreshToken: string = x.refresh_token;

          this.updateToken(accessToken, refreshToken, eventAlias, loginType);

          this.refreshTokenInProgress = false;
          return true;
        }),
        catchError(() => of(false))
      );
  }

  getTokenWithUsername(username: string, password: string, requestRole, eventId): Observable<any> {
    const formData = new FormData();
    formData.append('grant_type', 'password');
    formData.append('username', username);
    formData.append('password', password);
    formData.append('request_role', requestRole);
    formData.append('event_id', eventId);

    return this.httpClient.post(environment.baseUrl + '/oauth/token', formData, {
      headers: {
        Authorization: 'Basic ZW50LXJlZ2lzOmVudC1yZWdpcy1zZWNyZXQ='
      }
    });
  }

  getTokenWithRefresh(refreshToken: string): Observable<any> {
    const formData = new FormData();
    formData.append('grant_type', 'refresh_token');
    formData.append('refresh_token', refreshToken);
    return this.httpClient.post(environment.baseUrl + '/oauth/token', formData, {
      headers: {
        Authorization: 'Basic ZW50LXJlZ2lzOmVudC1yZWdpcy1zZWNyZXQ='
      }
    });
  }

  clear() {
    this.refreshTokenSubject.next(null);
    this.accessTokenSubject.next(null);
    this.eventAliasSubject.next(null);

    localStorage.removeItem(appConst.accessTokenKey);
    localStorage.removeItem(appConst.refreshTokenKey);
    localStorage.removeItem(appConst.eventAliasKey);
    localStorage.removeItem(appConst.loginTypeKey);

    this.apiConfig.apiKeys.Authorization = null;
  }

  
}
export interface AuthDetails {
  role: string,
  id: number,
  name: string
}