import { BehaviorSubject, of } from 'rxjs';
import { appConst } from 'src/app/constants/app-const';
import jwt_decode from 'jwt-decode';
import { environment } from 'src/environments/environment';
import { catchError, map } from 'rxjs/operators';
import { LoginType } from 'src/app/constants/login-type.enum';
import * as i0 from "@angular/core";
import * as i1 from "@ent-regis/entregis-ts-angular/configuration";
import * as i2 from "@angular/common/http";
export class AuthService {
    constructor(apiConfig, httpClient) {
        this.apiConfig = apiConfig;
        this.httpClient = httpClient;
        this.refreshTokenInProgress = false;
        this.accessTokenSubject = new BehaviorSubject(null);
        this.refreshTokenSubject = new BehaviorSubject(null);
        this.eventAliasSubject = new BehaviorSubject(null);
        this.accessTokenSubject.next(this.token);
        this.refreshTokenSubject.next(this.refreshToken);
        this.eventAliasSubject.next(this.eventAlias);
    }
    get token() {
        return localStorage.getItem(appConst.accessTokenKey);
    }
    get refreshToken() {
        return localStorage.getItem(appConst.refreshTokenKey);
    }
    get eventAlias() {
        return localStorage.getItem(appConst.eventAliasKey);
    }
    get loginType() {
        const loginTypeString = localStorage.getItem(appConst.loginTypeKey);
        return LoginType[loginTypeString];
    }
    get isAuthenticated() {
        if (this.token === null || this.token === undefined) {
            return false;
        }
        else if (this.doesTokenExpired()) {
            if (this.doesRefreshTokenExpired()) {
                return false;
            }
            else {
                return this.refresh();
            }
        }
        else {
            return true;
        }
    }
    doesTokenExpired() {
        return this.token == null
            ? true
            : jwt_decode(this.token).exp < Date.now() / 1000;
    }
    doesRefreshTokenExpired() {
        return this.refreshToken == null
            ? true
            : jwt_decode(this.refreshToken).exp < Date.now() / 1000;
    }
    getUsername() {
        return this.token == null ? null : jwt_decode(this.token).user_name;
    }
    getAuthDetails() {
        return this.token == null ? null : jwt_decode(this.token).details;
    }
    getName() {
        if (this.token == null) {
            return null;
        }
        else {
            const decodedToken = jwt_decode(this.token);
            return `${decodedToken.details.name}`;
        }
    }
    getRoles() {
        return this.token == null ? null : jwt_decode(this.token).authorities;
    }
    updateToken(token, refreshToken, eventAlias, loginType) {
        this.accessTokenSubject.next(token);
        this.refreshTokenSubject.next(refreshToken);
        this.eventAliasSubject.next(eventAlias);
        localStorage.setItem(appConst.accessTokenKey, token);
        localStorage.setItem(appConst.refreshTokenKey, refreshToken);
        localStorage.setItem(appConst.eventAliasKey, eventAlias);
        localStorage.setItem(appConst.loginTypeKey, loginType);
        this.updateSwaggerToken();
    }
    updateSwaggerToken() {
        this.apiConfig.apiKeys.Authorization = 'Bearer ' + this.token;
    }
    refresh() {
        const refreshToken = this.refreshToken;
        const eventAlias = this.eventAlias;
        const loginType = this.loginType;
        this.clear();
        this.refreshTokenInProgress = true;
        return this.getTokenWithRefresh(refreshToken)
            .pipe(map((x) => {
            const accessToken = x.access_token;
            const refreshToken = x.refresh_token;
            this.updateToken(accessToken, refreshToken, eventAlias, loginType);
            this.refreshTokenInProgress = false;
            return true;
        }), catchError(() => of(false)));
    }
    getTokenWithUsername(username, password, requestRole, eventId) {
        const formData = new FormData();
        formData.append('grant_type', 'password');
        formData.append('username', username);
        formData.append('password', password);
        formData.append('request_role', requestRole);
        formData.append('event_id', eventId);
        return this.httpClient.post(environment.baseUrl + '/oauth/token', formData, {
            headers: {
                Authorization: 'Basic ZW50LXJlZ2lzOmVudC1yZWdpcy1zZWNyZXQ='
            }
        });
    }
    getTokenWithRefresh(refreshToken) {
        const formData = new FormData();
        formData.append('grant_type', 'refresh_token');
        formData.append('refresh_token', refreshToken);
        return this.httpClient.post(environment.baseUrl + '/oauth/token', formData, {
            headers: {
                Authorization: 'Basic ZW50LXJlZ2lzOmVudC1yZWdpcy1zZWNyZXQ='
            }
        });
    }
    clear() {
        this.refreshTokenSubject.next(null);
        this.accessTokenSubject.next(null);
        this.eventAliasSubject.next(null);
        localStorage.removeItem(appConst.accessTokenKey);
        localStorage.removeItem(appConst.refreshTokenKey);
        localStorage.removeItem(appConst.eventAliasKey);
        localStorage.removeItem(appConst.loginTypeKey);
        this.apiConfig.apiKeys.Authorization = null;
    }
}
AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.Configuration), i0.ɵɵinject(i2.HttpClient)); }, token: AuthService, providedIn: "root" });
