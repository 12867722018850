import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { AbstractControl, FormArray, FormGroup, ValidatorFn } from '@angular/forms';

export function markFormGroupDirty(form: FormGroup): FormGroup {
  Object.values(form.controls).forEach(control => {
    control.markAsDirty();
    if ((control as any).controls) {
      markFormGroupDirty(control as FormGroup);
    }
  });

  return form;
}
export function moveItemInFormArray(formArray: FormArray, event: CdkDragDrop<AbstractControl[]>): void {
  const from = clamp(event.previousIndex, formArray.length - 1);
  const to = clamp(event.currentIndex, formArray.length - 1);

  if (from === to) {
    return;
  }

  const target = formArray.at(from);
  const delta = to < from ? -1 : 1;

  for (let i = from; i !== to; i += delta) {
    const current = formArray.at(i + delta);
    formArray.setControl(i, current);
  }

  formArray.setControl(to, target);
}

export function clamp(value: number, max: number): number {
  return Math.max(0, Math.min(max, value));
}

// Array Validators
export class ArrayValidators {

  // max length
  public static maxLength(max: number): ValidatorFn | any {
      return (control: AbstractControl[]) => {
          if (!(control instanceof FormArray)) return;
          return control.length > max ? { maxLength: true } : null;
      }
  }

  // min length
  public static minLength(min: number): ValidatorFn | any {
      return (control: AbstractControl[]) => {
          if (!(control instanceof FormArray)) return;
          return control.length < min ? { minLength: true } : null;
      }
  }
}

export function checkEqualOtherName(value: string): boolean {
  if (value) {
    return value.trim().toLowerCase() === 'others';
  } else {
    return false;
  }
}

