import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { RegisterCompanyControllerService, RegisterCompanyResponse } from '@ent-regis/entregis-ts-angular';
import { faDownload,faEdit,faLink,faTrash } from '@fortawesome/free-solid-svg-icons';
import { msg } from 'src/app/constants/msg';
import { ConfirmModalComponent } from 'src/app/shared/modules/reused-component/confirm-modal/confirm-modal.component';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-presentation',
  templateUrl: './presentation.component.html',
  styleUrls: ['./presentation.component.scss']
})
export class PresentationComponent implements OnInit {

  @Input() registerCompanyInfo: RegisterCompanyResponse;

  faDownload = faDownload;
  faEdit = faEdit;
  faLink = faLink;
  faTrash = faTrash;
  isShowWarning: boolean = false;

  @ViewChild('presentationFile', { static: false}) presentationFile: ElementRef;

  @Output() onUpload = new EventEmitter<void>();

  @ViewChild("confirmModal", { static: false }) confirmModal: ConfirmModalComponent;


  constructor(
    private registerCompanyService: RegisterCompanyControllerService,
    private alert: AlertService,
  ) { }

  ngOnInit() {
  }

  download() {
    const url = this.registerCompanyInfo.presentationUrl;
    window.open(url, '_blank').focus();
  }

  delete() {
    this.confirmModal.title = "Confirm Delete";
      this.confirmModal.message = `Are you sure you want to delete presentation ?`;
      this.confirmModal.btnConfirm = "Delete";
      this.confirmModal.openModal();
      this.confirmModal.confirm = () => {
        this.registerCompanyService.deleteCompanyPresentationUsingDELETE(this.registerCompanyInfo.registerCompanyId).subscribe(() => {
          this.confirmModal.onClose();
          this.alert.showSuccess(msg.success.deleteFile);
          this.presentationFile.nativeElement.value = "";
          this.onUpload.emit();
        });
      };
  }

  uploadPresentationFile(file: File) {
    if (file.size > 20971520) {
     // this.isShowWarning = true;
     this.alert.showError(msg.error.uploadFileLarge);
      this.presentationFile.nativeElement.value = "";
    } else {
      this.isShowWarning = false;
      this.registerCompanyService.uploadPresentationUsingPOST(file, this.registerCompanyInfo.registerCompanyId).subscribe(res => {
        this.alert.showSuccess(msg.success.uploadFile);
        this.presentationFile.nativeElement.value = "";
        this.onUpload.emit();
      })
    }
    
  }
}
