import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegisterResultComponent } from './register-result/register-result.component';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';



@NgModule({
  declarations: [
    RegisterResultComponent, 
    ConfirmModalComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    RegisterResultComponent,
    ConfirmModalComponent
  ]
})
export class ReusedComponentModule { }
