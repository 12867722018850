<div class="content" id="anchor" style="border-top: 2px solid #0064c5;margin-top: 1px;">
  <form role="form" [formGroup]=infoFG (ngSubmit)="onSubmit()">
    <div class="content-container mt-2">
      <div class="content">
        <div class="card-form" formGroupName="personal">
          <!-- <h4>Personal Info</h4> -->
          <div class="row">
            <div class="col-12">
              <label id="investor-info-field-personal-company-text" for="investor-info-field-personal-company-input"
                class="col-form-label">Company</label>
              <input id="investor-info-field-personal-company-input" formControlName="company" [typeahead]="companies"
                [typeaheadOptionsLimit]="10" (change)="setCanLeave(false)" [adaptivePosition]="true"
                placeholder="specify your company name." typeaheadOptionField="companyName" class="form-control">
              <div id="investor-info-field-personal-company-required-error-text" class="text-danger mt-1"
                *ngIf="personCompany.errors?.required">Company name is required</div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-6">
              <label id="investor-info-field-personal-name-text" for="investor-info-field-personal-name-input"
                class="col-form-label">Name</label>
              <input id="investor-info-field-personal-name-input" class="form-control" formControlName="firstname"
                (change)="setCanLeave(false)" (blur)="onCheckAndRemoveWording('personal.firstname')"
                (keypress)="onKeyPressCharacters($event)">
              <div id="investor-info-field-personal-name-required-error-text" class="text-danger mt-1"
                *ngIf="personFirstName.errors?.required">First name is required</div>
            </div>
            <div class="col-12 col-sm-6">
              <label id="investor-info-field-personal-lastname-text" for="investor-info-field-personal-lastname-input"
                class="col-form-label">Surname</label>
              <input id="investor-info-field-personal-lastname-input" class="form-control" formControlName="lastname"
                (change)="setCanLeave(false)" (blur)="onCheckAndRemoveWording('personal.lastname')"
                (keypress)="onKeyPressCharacters($event)">
              <div id="investor-info-field-personal-lastname-error-text" class="text-danger mt-1"
                *ngIf="personLastName.errors?.required">Last name is required</div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-6">
              <label id="investor-info-field-personal-email-text" for="investor-info-field-personal-email-input"
                class="col-form-label">Email</label>
              <input id="investor-info-field-personal-email-input" class="form-control"
                [value]="infoFG.value.personal.email" (change)="setCanLeave(false)" disabled>
              <div id="investor-info-field-personal-email-already-used-error-text" class="text-danger"
                *ngIf="personEmail.errors?.duplicated">Email already in use.</div>
              <div *ngIf="(submitted || personEmail.errors?.dirty) && personEmail.invalid">
                <div id="investor-info-field-personal-email-required-error-text" class="text-danger mt-1"
                  *ngIf="personEmail.errors?.required">Email is required</div>
                <div id="investor-info-field-personal-email-pattern-error-text" class="text-danger mt-1"
                  *ngIf="personEmail.errors?.pattern">Email must be a valid email address
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-3">
              <label id="investor-info-field-personal-mobile-text" for="investor-info-field-personal-mobile-input"
                class="col-form-label">Mobile</label>
              <div>
                <ngx-intl-tel-input [cssClass]="'form-control intl-tel-input'" [preferredCountries]="preferredCountries"
                  [searchCountryFlag]="true" [searchCountryField]="searchCountryField" [tooltipField]="tooltipLabel"
                  [phoneValidation]="true" [inputId]="'investor-info-field-personal-mobile-input'"
                  formControlName="mobile" required mobile (change)="setCanLeave(false)" [enablePlaceholder]="true">
                </ngx-intl-tel-input>
                <div id="investor-info-field-personal-mobile-required-error-text" class="text-danger mt-1"
                  *ngIf="personMobile.errors?.required">Mobile number is required</div>
                <div id="investor-info-field-personal-mobile-pattern-error-text" class="text-danger mt-1"
                  *ngIf="personMobile.errors?.validatePhoneNumber">Mobile number is invalid
                  pattern
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-3">
              <label id="investor-info-field-personal-ext-number-text"
                for="investor-info-field-personal-ext-number-input" class="col-form-label">Ext. Number</label>
              <input id="investor-info-field-personal-ext-number-input" class="form-control" formControlName="extNumber"
                (change)="setCanLeave(false)">
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-6">
              <label id="investor-info-field-personal-job-text" for="investor-info-field-personal-job-input"
                class="col-form-label">Job Title</label>
              <input id="investor-info-field-personal-job-input" class="form-control" formControlName="jobTitle"
                (change)="setCanLeave(false)">
              <div id="investor-info-field-personal-job-required-error-text" class="text-danger mt-1"
                *ngIf="personJobTitle.errors?.required">Job title is required</div>
            </div>
            <div class="col-12 col-sm-6">
              <label id="investor-info-field-personal-company-required-error-text" for="personDepartment"
                class="col-form-label">Department</label>
              <input id="personDepartment" class="form-control" formControlName="department"
                (change)="setCanLeave(false)">
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-sm-12">
              <label id="investor-info-field-personal-country-text" for="investor-info-field-personal-country-selector"
                class="col-form-label">Country</label>
              <ng-select id="investor-info-field-personal-country-selector" placeholder="Please Select"
                formControlName="country" (change)="setCanLeave(false)">
                <ng-option *ngFor="let country of countries" [value]="country.id">{{country.name}}</ng-option>
              </ng-select>
              <div id="investor-info-field-personal-country-required-error-text" class="text-danger mt-1"
                *ngIf="personCountry.errors?.required">Country is required</div>
            </div>
            <div class="col-12 col-sm-6">
            </div>
          </div>
        </div>
        <div class="card-form mt-3" formGroupName="assistant">
          <h4 id="investor-info-field-assistant-title-text">Assistant Info</h4>
          <div class="row">
            <div class="col-12 col-sm-6">
              <label id="investor-info-field-assistant-name-text" for="investor-info-field-assistant-name-input"
                class="col-form-label">Name</label>
              <input id="investor-info-field-assistant-name-input" class="form-control" formControlName="firstname"
                (change)="setCanLeave(false)" (blur)="onCheckAndRemoveWording('assistant.firstname')"
                (keypress)="onKeyPressCharacters($event)">
            </div>
            <div class="col-12 col-sm-6">
              <label id="investor-info-field-assistant-lastname-text" for="investor-info-field-assistant-lastname-input"
                class="col-form-label">Surname</label>
              <input id="investor-info-field-assistant-lastname-input" class="form-control" formControlName="lastname"
                (change)="setCanLeave(false)" (blur)="onCheckAndRemoveWording('assistant.lastname')"
                (keypress)="onKeyPressCharacters($event)">
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-6">
              <label id="investor-info-field-assistant-email-text" for="investor-info-field-assistant-email-input"
                class="col-form-label">Email</label>
              <input id="investor-info-field-assistant-email-input" class="form-control" formControlName="email"
                (change)="setCanLeave(false)">
              <div id="investor-info-field-assistant-email-duplicated-error-text" class="text-danger"
                *ngIf="assistantEmail.errors?.duplicated">Email already in use.</div>
              <div *ngIf="(submitted || assistantEmail.errors?.dirty) && assistantEmail.invalid">
                <div id="investor-info-field-assistant-email-pattern-error-text" class="text-danger mt-1"
                  *ngIf="assistantEmail.errors?.pattern">Email must be a valid email address
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-3">
              <label id="investor-info-field-assistant-mobile-text" for="investor-info-field-assistant-mobile-input"
                class="col-form-label">Mobile</label>
              <div>
                <ngx-intl-tel-input [cssClass]="'form-control intl-tel-input'" [preferredCountries]="preferredCountries"
                  [searchCountryFlag]="true" [searchCountryField]="searchCountryField" [tooltipField]="tooltipLabel"
                  [phoneValidation]="true" formControlName="mobile"
                  [inputId]="'investor-info-field-assistant-mobile-input'" (change)="setCanLeave(false)"
                  [enablePlaceholder]="true">
                </ngx-intl-tel-input>
              </div>
            </div>
            <div class="col-12 col-sm-3">
              <label id="investor-info-field-assistant-ext-number-text"
                for="investor-info-field-assistant-ext-number-input" class="col-form-label">Ext. Number</label>
              <input id="investor-info-field-assistant-ext-number-input" class="form-control"
                formControlName="extNumber" (change)="setCanLeave(false)">
            </div>
          </div>
        </div>
        <div class="card-form mt-3" *ngIf="event?.isShowSalePersonForInvestorRegistration">
          <h4 id="investor-info-field-assistant-saleperson-title-text">Invited by</h4>
          <div class="content" formGroupName="salePerson">
            <div class="row">
              <div class="col-12 col-sm-6">
                <ng-select id="investor-info-field-assistant-saleperson-selector" formControlName="hasSalePerson"
                  [searchable]="false" [clearable]="false" (change)="setCanLeave(false)" placeholder="Please Select">
                  <ng-option [value]="false">No Invited by</ng-option>
                  <ng-option [value]="true">Invited by</ng-option>
                </ng-select><br>
              </div>
            </div>

            <div *ngIf="salePersonHasSalePerson.value === true">
              <div class="row">
                <div class="col-12 col-sm-6">
                  <label id="investor-info-field-saleperson-name-text"
                    for="investor-info-field-saleperson-name-input" class="col-form-label">Name</label>
                    <ng-select id="investor-info-field-saleperson-name-selector" placeholder="Please Select" formControlName="name"
                      (change)="setCanLeave(false)">
                      <ng-option *ngFor="let item of listInvited" [value]="item.name">{{item.name}}</ng-option>
                  </ng-select>
                  <div id="investor-info-field-saleperson-name-required-error-text" class="text-danger mt-1"
                    *ngIf="salePersonName.errors?.required && salePersonName.touched">Name is required</div>
                </div>
                <div class="col-12 col-sm-6" *ngIf="checkSalePersonName()">
                  <label id="investor-info-field-broker-name-text"
                    for="investor-info-field-broker-name-input" class="col-form-label">Securities Name Of
                    Invited by</label>
                  <input id="investor-info-field-broker-name-input" class="form-control"
                    formControlName="brokerName" (change)="setCanLeave(false)" placeholder="Please specify">
                  <div id="investor-info-field-broker-name-required-error-text" class="text-danger"
                    *ngIf="salePersonBrokerName.errors?.required && salePersonBrokerName.touched">Securities Name is required</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center mt-3">
          <button id="investor-info-save-button" type="submit" class="btn btn-primary"
            *ngIf="!isCloseRegisterInvestor">{{isShowHotel ? 'SAVE' :
            'SAVE'}}</button>
        </div>
      </div>
    </div>
  </form>
</div>