import { Injectable } from '@angular/core';
import { APIResultListDietaryResponse, DietaryPublicControllerService, DietaryResponse } from '@ent-regis/entregis-ts-angular';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DietaryService {

  dietaries = new BehaviorSubject<DietaryResponse[]>([]);
  noneDietaryId = new BehaviorSubject<number>(null);

  constructor(
    private dietaryPublicService: DietaryPublicControllerService,
  ) {
    this.dietaryPublicService.getDietaryUsingGET().subscribe((response: APIResultListDietaryResponse) => {
      this.dietaries.next(response.data);
      const noneDietaryIndex = response.data.findIndex(x => x.dietaryName === 'None');
      if (noneDietaryIndex) {
        this.noneDietaryId.next(response.data[noneDietaryIndex].dietaryId);
      }
    });
  }
}
