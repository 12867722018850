<div *ngIf="scheduleData" class="common-img default-img" style="min-height: 100%;" [style.background-image]="scheduleData.bgUrl ? 'url(' + scheduleData.bgUrl + ')': 
'url(../../assets/images/defaultBg1.png)'">
  <div id="print-section">
    <div class="row bg-white banner-block">
      <div class="container">
        <img *ngIf="scheduleData.eventLogoUrl" id="schedule-company-logo-image" height="80px" class="mt-3 mb-1"
          [src]="scheduleData.eventLogoUrl">
        <h4 *ngIf="!scheduleData.eventLogoUrl" id="schedule-company-event-text" class="mt-4 mb-3">
          {{scheduleData.eventName}}</h4>
        <p id="schedule-company-period-text" class="text-muted mb-3">{{getPeriodString(scheduleData)}}</p>
      </div>

    </div>
    <!-- <img class="position-absolute" style="top: 0; right: 0;" height="80px" [src]="scheduleData.eventLogoUrl">
    <h4>{{scheduleData.name}}</h4>
    <p class="text-muted">{{scheduleData.companyName}}</p>
    <hr> -->
    <div class="pt-3 container">
      <h4 id="schedule-company-participant-text">{{scheduleData.name}}</h4>
      <div class="d-flex justify-content-between">
        <p id="schedule-company-company-text" class="text-muted">{{scheduleData.companyName}}</p>
        <!-- <p *ngIf="scheduleData.eventType === eventTypeEnum.THAILANDFOCUS" class="ml-auto text-muted">Location :
          {{scheduleData.location || '-'}}
        </p> -->
        <button type="button" class="btn btn-outline-light-blue no-print" (click)="convertToPDF()">Export PDF</button>
      </div>

      <div class="table-responsive-lg mt-3 table-card-form tableScheduleFixHead">
        <table class="table">
          <thead class="thead-white">
            <tr class="text-title">
              <th id="schedule-company-feild-date-column-text" scope="col" style="width: 15%;">DATE/TIME</th>
              <th id="schedule-company-feild-room-column-text" scope="col">ROOM</th>
              <!-- <th scope="col" class="text-center">FLOOR</th> -->
              <th id="schedule-company-feild-participant-column-text" scope="col">PARTICIPANTS</th>
              <th id="schedule-company-feild-investor-column-text" scope="col">INVESTORS</th>
              <th id="schedule-company-feild-meeting-type-column-text" scope="col" class="text-center"
                style="width: 15%;">MEETING TYPE</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let slot of scheduleData.slots; let slotIndex = index;">
              <tr *ngIf="slot.slotPeriods.length > 0" style="background-color: #0064C5;color: white;">
                <td colspan="6"><strong id="schedule-company-field-slot-date-text-{{slotIndex}}"
                    class="text-title">{{slot.slotDate | date:'mediumDate': scheduleData.eventType
                    === eventTypeEnum.THAILANDFOCUS ? 'UTC +7' : ''}}</strong></td>
              </tr>
              <ng-container *ngFor="let slotPeriod of slot.slotPeriods let periodIndex = index;">
                <tr>
                  <td>
                    <strong id="schedule-company-field-period-text-{{slotIndex}}-{{periodIndex}}"
                      class="text-title">{{slotPeriod.startTime | date:'HH:mm': scheduleData.eventType ===
                      eventTypeEnum.THAILANDFOCUS ? 'UTC +7' : ''}} - {{slotPeriod.endTime | date:'HH:mm':
                      scheduleData.eventType ===
                      eventTypeEnum.THAILANDFOCUS ? 'UTC +7' : ''}}</strong>
                    <span id="schedule-company-field-timezone-text-{{slotIndex}}-{{periodIndex}}"
                      *ngIf="scheduleData.eventType === eventTypeEnum.THAILANDFOCUS"
                      class="text-muted"><br>{{slotPeriod.endTime |
                      date:'zzz': scheduleData.eventType ===
                      eventTypeEnum.THAILANDFOCUS ? 'UTC +7' : ''}} (Bangkok Time)</span>
                  </td>
                  <td class="text-title">
                    <div>
                      <div id="schedule-company-field-room-link-text-{{slotIndex}}-{{periodIndex}}">{{slotPeriod.room}}
                      </div>
                      <a *ngIf="slotPeriod.roomLink" [tooltip]="slotPeriod.roomDesc" id="schedule-company-field-meetinig-room-link-text-{{slotIndex}}-{{periodIndex}}"
                        target="_blank" [href]="slotPeriod.roomLink" class="link-blue">Meeting Link <img
                          src="assets/images/video.svg" alt=""></a>
                    </div>
                    <ng-template #elseBlock>
                      <div id="schedule-company-field-room-text-{{slotIndex}}-{{periodIndex}}"
                        [tooltip]="slotPeriod.roomDesc">{{slotPeriod.room}}</div>
                    </ng-template>
                    <!-- {{slotPeriod.room}} -->
                  </td>
                  <!-- <td class="text-center">{{slotPeriod.floor}}</td> -->
                  <td>
                    <div *ngFor="let participant of slotPeriod.participants; let participantIndex = index;">
                      <!-- <label [className]="participant.name === scheduleData.name ? 
                      'bg-primary d-inline-flex px-2 py-1 rounded font-weight-bold': 'px-2'">{{participant.name}}
                        <span class="font-italic">{{' (' + participant.jobTitle + ') '}}</span></label> -->
                      <label class="d-flex flex-column">
                        <div
                          id="schedule-company-field-participant-name-text-{{slotIndex}}-{{periodIndex}}-{{participantIndex}}">
                          <strong [className]="participant.name === scheduleData.name ? 
                          'text-light-blue': ''">{{participant.name}}</strong>
                        </div>
                        <div
                          id="schedule-company-field-participant-job-text-{{slotIndex}}-{{periodIndex}}-{{participantIndex}}"
                          class="text-muted">{{participant.jobTitle}}</div>
                      </label>
                    </div>
                  </td>
                  <td>
                    <div *ngFor="let attendee of slotPeriod.attendees; let i = index">
                      <div *ngIf="i < 3">
                        <label class="d-flext flex-column">
                          <div id="schedule-company-field-attendee-name-text-{{slotIndex}}-{{periodIndex}}-{{i}}">
                            <strong>{{attendee.name}}</strong>
                          </div>
                          <div id="schedule-company-field-attendee-company-text-{{slotIndex}}-{{periodIndex}}-{{i}}"
                            class="text-muted">{{attendee.companyName}}</div>
                        </label>
                        <a (click)="slotPeriod['isShow'] = true;"
                          id="schedule-company-field-attendee-show-more-text-{{slotIndex}}-{{periodIndex}}-{{i}}"
                          *ngIf="slotPeriod.attendees.length > 3 && i == 2 && !slotPeriod['isShow']"
                          class="link-blue"><br>
                          <h6>Show more</h6>
                        </a>
                      </div>
                      <div *ngIf="i >= 3 && slotPeriod['isShow']">
                        <label class="d-flext flex-column">
                          <div id="schedule-company-field-attendee-name-text-{{slotIndex}}-{{periodIndex}}-{{i}}">
                            <strong>{{attendee.name}}</strong>
                          </div>
                          <div id="schedule-company-field-attendee-company-text-{{slotIndex}}-{{periodIndex}}-{{i}}"
                            class="text-muted">{{attendee.companyName}}</div>
                        </label>
                        <a (click)="slotPeriod['isShow'] = false;" *ngIf="i === (slotPeriod.attendees.length - 1)"
                          id="schedule-company-field-attendee-show-less-text-{{slotIndex}}-{{periodIndex}}-{{i}}"
                          class="link-blue"><br>
                          <h6>Show less</h6>
                        </a>
                      </div>
                    </div>
                  </td>
                  <td class="text-center"><span [className]="slotPeriod.meetingType === 'VIRTUALGROUP' ? 
                    'bg-primary font-weight-bold px-2 py-1 rounded': 'px-2 py-1'">{{slotPeriod.meetingType |
                      meetingType}}</span></td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="mx-5 px-5 pt-4" *ngIf="isErr">
    <h6 id="schedule-company-line1-error-text">ERROR</h6>
    <h3 id="schedule-company-line2-error-text">PAGE NOT FOUND</h3>
    <p id="schedule-company-line3-error-text">The page you requested was not found.</p>
  </div>
</div>