import { Injectable } from '@angular/core';
import { Configuration } from '@ent-regis/entregis-ts-angular';
import { appConst } from 'src/app/constants/app-const';
import { AuthService } from 'src/app/shared/services/auth.service';
import { EntRegisAuthService } from 'src/app/shared/services/ent-regis-auth.service';

@Injectable()
export class AuthProvider {
  constructor(
    private apiConfig: Configuration,
    private authService: AuthService,
    private entRegisAuthService: EntRegisAuthService,
  ) { }

  async onAppLoaded(): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      const loginType = localStorage.getItem(appConst.loginTypeKey);

      if (!this.apiConfig.apiKeys) {
        this.apiConfig.apiKeys = {};
      }
      if (loginType === 'company') {
        this.authService.updateSwaggerToken();
      } else {
        this.entRegisAuthService.updateSwaggerToken();
      }
      resolve();
    });
  }
}
