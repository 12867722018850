/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./presentation.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i3 from "@fortawesome/angular-fontawesome";
import * as i4 from "@angular/platform-browser";
import * as i5 from "@angular/common";
import * as i6 from "../../shared/modules/reused-component/confirm-modal/confirm-modal.component.ngfactory";
import * as i7 from "../../shared/modules/reused-component/confirm-modal/confirm-modal.component";
import * as i8 from "ngx-bootstrap/modal";
import * as i9 from "./presentation.component";
import * as i10 from "@ent-regis/entregis-ts-angular/api/registerCompanyController.service";
import * as i11 from "../../shared/services/alert.service";
var styles_PresentationComponent = [i0.styles];
var RenderType_PresentationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PresentationComponent, data: {} });
export { RenderType_PresentationComponent as RenderType_PresentationComponent };
function View_PresentationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "card-form"], ["style", "min-width: 350px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 13, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "span", [["class", "text-light-blue mr-2"], ["id", "comopany-presentation-file-name-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "fa-icon", [["class", "ng-fa-icon"]], [[1, "title", 0], [8, "innerHTML", 1]], null, null, i2.View_FaIconComponent_0, i2.RenderType_FaIconComponent)), i1.ɵdid(4, 573440, null, 0, i3.FaIconComponent, [i4.DomSanitizer, i3.FaConfig, i3.FaIconLibrary, [2, i3.FaStackItemSizeDirective]], { icon: [0, "icon"] }, null), (_l()(), i1.ɵted(5, null, [" ", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "button", [["class", "btn btn-outline-dark border-0"], ["id", "comopany-presentation-download-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.download() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "fa-icon", [["class", "ng-fa-icon"]], [[1, "title", 0], [8, "innerHTML", 1]], null, null, i2.View_FaIconComponent_0, i2.RenderType_FaIconComponent)), i1.ɵdid(8, 573440, null, 0, i3.FaIconComponent, [i4.DomSanitizer, i3.FaConfig, i3.FaIconLibrary, [2, i3.FaStackItemSizeDirective]], { icon: [0, "icon"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 2, "button", [["class", "btn btn-outline-primary border-0"], ["id", "comopany-presentation-edit-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v.parent, 9).click() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "fa-icon", [["class", "ng-fa-icon"]], [[1, "title", 0], [8, "innerHTML", 1]], null, null, i2.View_FaIconComponent_0, i2.RenderType_FaIconComponent)), i1.ɵdid(11, 573440, null, 0, i3.FaIconComponent, [i4.DomSanitizer, i3.FaConfig, i3.FaIconLibrary, [2, i3.FaStackItemSizeDirective]], { icon: [0, "icon"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 2, "button", [["class", "btn btn-outline-danger border-0"], ["id", "comopany-presentation-delete-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.delete() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "fa-icon", [["class", "ng-fa-icon"]], [[1, "title", 0], [8, "innerHTML", 1]], null, null, i2.View_FaIconComponent_0, i2.RenderType_FaIconComponent)), i1.ɵdid(14, 573440, null, 0, i3.FaIconComponent, [i4.DomSanitizer, i3.FaConfig, i3.FaIconLibrary, [2, i3.FaStackItemSizeDirective]], { icon: [0, "icon"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.faLink; _ck(_v, 4, 0, currVal_2); var currVal_6 = _co.faDownload; _ck(_v, 8, 0, currVal_6); var currVal_9 = _co.faEdit; _ck(_v, 11, 0, currVal_9); var currVal_12 = _co.faTrash; _ck(_v, 14, 0, currVal_12); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 4).title; var currVal_1 = i1.ɵnov(_v, 4).renderedIconHTML; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_3 = _co.registerCompanyInfo.originalPresentationFileName; _ck(_v, 5, 0, currVal_3); var currVal_4 = i1.ɵnov(_v, 8).title; var currVal_5 = i1.ɵnov(_v, 8).renderedIconHTML; _ck(_v, 7, 0, currVal_4, currVal_5); var currVal_7 = i1.ɵnov(_v, 11).title; var currVal_8 = i1.ɵnov(_v, 11).renderedIconHTML; _ck(_v, 10, 0, currVal_7, currVal_8); var currVal_10 = i1.ɵnov(_v, 14).title; var currVal_11 = i1.ɵnov(_v, 14).renderedIconHTML; _ck(_v, 13, 0, currVal_10, currVal_11); }); }
export function View_PresentationComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { presentationFile: 0 }), i1.ɵqud(671088640, 2, { confirmModal: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 9, "div", [["class", "d-flex flex-column align-items-start p-2"], ["style", "border-top: 2px solid #0064c5;margin-top: 1px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "p", [["id", "comopany-presentation-warning-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Manage your presentation here "])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", "text-danger"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["(.png,.jpg,.pdf,.ppt,.pptx Maximum upload file size: 20MB.)"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "card-form"], ["style", "min-width: 350px;"]], [[2, "d-none", null]], null, null, null, null)), (_l()(), i1.ɵeld(9, 0, [[1, 0], ["presentationFile", 1]], null, 0, "input", [["accept", "image/x-png,image/jpeg,application/pdf,application/pdf,\n        application/vnd.ms-powerpoint,\n        application/vnd.openxmlformats-officedocument.presentationml.slideshow,\n        application/vnd.openxmlformats-officedocument.presentationml.presentation"], ["id", "presentationFile"], ["name", "presentationFile"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.uploadPresentationFile(i1.ɵnov(_v, 9).files[0]) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PresentationComponent_1)), i1.ɵdid(11, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 1, "app-confirm-modal", [], null, null, null, i6.View_ConfirmModalComponent_0, i6.RenderType_ConfirmModalComponent)), i1.ɵdid(13, 114688, [[2, 4], ["confirmModal", 4]], 0, i7.ConfirmModalComponent, [i8.BsModalService], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (((_co.registerCompanyInfo == null) ? null : _co.registerCompanyInfo.presentationUrl) && ((_co.registerCompanyInfo == null) ? null : _co.registerCompanyInfo.originalPresentationFileName)); _ck(_v, 11, 0, currVal_1); _ck(_v, 13, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.registerCompanyInfo == null) ? null : _co.registerCompanyInfo.presentationUrl) && ((_co.registerCompanyInfo == null) ? null : _co.registerCompanyInfo.originalPresentationFileName)); _ck(_v, 8, 0, currVal_0); }); }
export function View_PresentationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-presentation", [], null, null, null, View_PresentationComponent_0, RenderType_PresentationComponent)), i1.ɵdid(1, 114688, null, 0, i9.PresentationComponent, [i10.RegisterCompanyControllerService, i11.AlertService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PresentationComponentNgFactory = i1.ɵccf("app-presentation", i9.PresentationComponent, View_PresentationComponent_Host_0, { registerCompanyInfo: "registerCompanyInfo" }, { onUpload: "onUpload" }, []);
export { PresentationComponentNgFactory as PresentationComponentNgFactory };
