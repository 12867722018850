import { Injectable } from '@angular/core';
import { APIResultEventInfoWithConsentResponse, EventInfoWithConsentResponse, EventPublicControllerService } from '@ent-regis/entregis-ts-angular';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  eventSubject = new BehaviorSubject<EventInfoWithConsentResponse>(null);
  
  constructor(
        private eventPublicService: EventPublicControllerService,
        private authService: AuthService
  ) {
    this.authService.eventAliasSubject.subscribe(x => {
      if (x) {
        this.eventPublicService.getInfoWithConsentUsingGET(x)
        .subscribe((response: APIResultEventInfoWithConsentResponse) => {
          this.eventSubject.next(response.data);
        });
      } else {
        this.eventSubject.next(null);
      }
    })
  }

  
}
