import * as tslib_1 from "tslib";
import { appConst } from 'src/app/constants/app-const';
export class AuthProvider {
    constructor(apiConfig, authService, entRegisAuthService) {
        this.apiConfig = apiConfig;
        this.authService = authService;
        this.entRegisAuthService = entRegisAuthService;
    }
    onAppLoaded() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                const loginType = localStorage.getItem(appConst.loginTypeKey);
                if (!this.apiConfig.apiKeys) {
                    this.apiConfig.apiKeys = {};
                }
                if (loginType === 'company') {
                    this.authService.updateSwaggerToken();
                }
                else {
                    this.entRegisAuthService.updateSwaggerToken();
                }
                resolve();
            }));
        });
    }
}
