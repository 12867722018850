/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./investor.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/router";
import * as i4 from "./investor.component";
import * as i5 from "../core/service/register-investor.service";
import * as i6 from "../shared/services/event.service";
import * as i7 from "../shared/services/alert.service";
var styles_InvestorComponent = [i0.styles];
var RenderType_InvestorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InvestorComponent, data: {} });
export { RenderType_InvestorComponent as RenderType_InvestorComponent };
function View_InvestorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [["id", "investor-filed-name-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", " ", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "d-flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "p", [["class", "text-muted"], ["id", "investor-filed-company-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.investorProfile.firstName; var currVal_1 = _co.investorProfile.lastName; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.investorProfile.companyName; _ck(_v, 5, 0, currVal_2); }); }
function View_InvestorComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "h6 text-danger"], ["id", "investor-filed-timezone-for-digital-roadshow-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" Timezone : ", " (Your local time) "])), i1.ɵppd(2, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), _co.now, "zzz")); _ck(_v, 1, 0, currVal_0); }); }
function View_InvestorComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "text-danger mb-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "h6 mb-0"], ["id", "investor-filed-timezone-for-thailand-focus-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["Timezone : ", " (Bangkok time)"])), i1.ɵppd(3, 3), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "h6"], ["id", "investor-filed-timezone-for-thailand-focus-warning-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["The event will be conducted in physical event, unless selected virtual"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent, 0), _co.now, "zzz", "UTC +7")); _ck(_v, 2, 0, currVal_0); }); }
function View_InvestorComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "p", [["class", "text-blue"], ["id", "investor-remark-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["Remark: Unable to edit after registration period ", " - ", ""])), i1.ɵppd(2, 2), i1.ɵppd(3, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), _co.startDate, "MMM dd, yyyy")); var currVal_1 = i1.ɵunv(_v, 1, 1, _ck(_v, 3, 0, i1.ɵnov(_v.parent, 0), _co.endDate, "MMM dd, yyyy")); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_InvestorComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "li", [["class", "nav-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "a", [["class", "nav-link"], ["id", "investor-available-time-nav-link"]], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.gotoTab(["available-time"]) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵpad(2, 1), (_l()(), i1.ɵted(-1, null, ["Available Time"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isActive(_ck(_v, 2, 0, "available-time")); _ck(_v, 1, 0, currVal_0); }); }
function View_InvestorComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "li", [["class", "nav-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "a", [["class", "nav-link"], ["id", "investor-hotel-nav-link"]], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.gotoTab(["hotel"]) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵpad(2, 1), (_l()(), i1.ɵted(-1, null, ["Hotel"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isActive(_ck(_v, 2, 0, "hotel")); _ck(_v, 1, 0, currVal_0); }); }
export function View_InvestorComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 29, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InvestorComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 9, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InvestorComponent_2)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InvestorComponent_3)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "div", [["class", "h6"], ["id", "investor-filed-timezone-available-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Time show on this page is only company's availability"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InvestorComponent_4)), i1.ɵdid(13, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(14, 0, null, null, 16, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 13, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 12, "ul", [["class", "nav nav-tabs"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 3, "li", [["class", "nav-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 2, "a", [["class", "nav-link"], ["id", "investor-meeting-request-nav-link"]], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.gotoTab(["meeting-request"]) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵpad(19, 1), (_l()(), i1.ɵted(-1, null, ["Meeting Request"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InvestorComponent_5)), i1.ɵdid(22, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(23, 0, null, null, 3, "li", [["class", "nav-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 2, "a", [["class", "nav-link"], ["id", "investor-investor-info-nav-link"]], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.gotoTab(["investor-info"]) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵpad(25, 1), (_l()(), i1.ɵted(-1, null, ["Info"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InvestorComponent_6)), i1.ɵdid(28, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(29, 16777216, null, null, 1, "router-outlet", [], null, [[null, "activate"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("activate" === en)) {
        var pd_0 = (_co.onActiveTab($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(30, 212992, null, 0, i3.RouterOutlet, [i3.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, { activateEvents: "activate" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.investorProfile; _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.eventType === _co.eventTypeEnum.DIGITALROADSHOW); _ck(_v, 6, 0, currVal_1); var currVal_2 = (_co.eventType !== _co.eventTypeEnum.DIGITALROADSHOW); _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.isClosedRegis; _ck(_v, 13, 0, currVal_3); var currVal_5 = (_co.eventType !== _co.eventTypeEnum.DIGITALROADSHOW); _ck(_v, 22, 0, currVal_5); var currVal_7 = (_co.isShowHotel && (_co.eventType !== _co.eventTypeEnum.DIGITALROADSHOW)); _ck(_v, 28, 0, currVal_7); _ck(_v, 30, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.isActive(_ck(_v, 19, 0, "meeting-request")); _ck(_v, 18, 0, currVal_4); var currVal_6 = _co.isActive(_ck(_v, 25, 0, "investor-info")); _ck(_v, 24, 0, currVal_6); }); }
export function View_InvestorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-investor", [], null, null, null, View_InvestorComponent_0, RenderType_InvestorComponent)), i1.ɵdid(1, 114688, null, 0, i4.InvestorComponent, [i3.ActivatedRoute, i5.RegisterInvestorService, i6.EventService, i7.AlertService, i3.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InvestorComponentNgFactory = i1.ɵccf("app-investor", i4.InvestorComponent, View_InvestorComponent_Host_0, {}, {}, []);
export { InvestorComponentNgFactory as InvestorComponentNgFactory };
