import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-alert-collect-badge-modal',
  templateUrl: './alert-collect-badge-modal.component.html',
  styleUrls: ['./alert-collect-badge-modal.component.scss']
})
export class AlertCollectBadgeModalComponent implements OnInit {
  color: string
  constructor(
    public bsModalRef: BsModalRef,
  ) { }
  @Output() timeout = new EventEmitter<null>();
  @ViewChild('template', { static: false }) template: TemplateRef<any>;

  ngOnInit() {
    let timeout = Number(localStorage.getItem('collectBadgeTimeout'));
    if (!timeout) { timeout = 10000;}
    setTimeout(() => {
      this.timeout.emit();
      this.bsModalRef.hide();
    }, timeout)
  }

}
