import { Component, OnInit } from '@angular/core';
import { EventService } from '../shared/services/event.service';
import { EventInfoWithConsentResponse } from '@ent-regis/entregis-ts-angular';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  eventInfo: EventInfoWithConsentResponse;
  constructor(private eventService: EventService) { }

  ngOnInit() {
    this.eventService.eventSubject.subscribe(x => {
      if (x) {
        this.eventInfo = x;
      }
    })
  }

}
