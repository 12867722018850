import { ParticipantResponse, ParticipantControllerService } from '@ent-regis/entregis-ts-angular';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-register-company-confirm-rm-participant-modal',
  templateUrl: './register-company-confirm-rm-participant-modal.component.html',
  styleUrls: ['./register-company-confirm-rm-participant-modal.component.scss']
})
export class RegisterCompanyConfirmRmParticipantModalComponent implements OnInit {

  @Output() updateCompleted = new EventEmitter<null>();

  participant: ParticipantResponse;

  constructor(
    public bsModalRef: BsModalRef,
    private participantControllerService: ParticipantControllerService,
  ) { }

  ngOnInit() {
  }

  cancel() {
    this.bsModalRef.hide();
  }

  confirm() {
    this.participantControllerService.deleteParticipantByIdUsingDELETE1(this.participant.participantId).subscribe((x) => {
      this.bsModalRef.hide();
      this.updateCompleted.emit();
    });
  }
}
