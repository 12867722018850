/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./login-admin.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./login-admin.component";
import * as i3 from "adal-angular8/adal8.service";
import * as i4 from "@angular/router";
import * as i5 from "../shared/services/ent-regis-auth.service";
var styles_LoginAdminComponent = [i0.styles];
var RenderType_LoginAdminComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoginAdminComponent, data: {} });
export { RenderType_LoginAdminComponent as RenderType_LoginAdminComponent };
export function View_LoginAdminComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "container-custom h-100 d-flex justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "jumbotron my-auto"], ["id", "my-card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h4", [["class", "d-flex justify-content-center"], ["id", "admin-login-title-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Enterprise Registration"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "p", [["class", "d-flex justify-content-center mb-4"], ["id", "admin-login-welcome-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Welcome to Stock Exchange of Thailand Registration"])), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [["class", "d-flex justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "button", [["class", "btn btn-lean"], ["id", "admin-login-office-365-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.login() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "img", [["alt", ""], ["height", "36px"], ["src", "/assets/images/ms-logo.png"], ["width", "36px"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["LOGIN to SET MS Office 365"]))], null, null); }
export function View_LoginAdminComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-login-admin", [], null, null, null, View_LoginAdminComponent_0, RenderType_LoginAdminComponent)), i1.ɵdid(1, 114688, null, 0, i2.LoginAdminComponent, [i3.Adal8Service, i4.Router, i5.EntRegisAuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoginAdminComponentNgFactory = i1.ɵccf("app-login-admin", i2.LoginAdminComponent, View_LoginAdminComponent_Host_0, {}, {}, []);
export { LoginAdminComponentNgFactory as LoginAdminComponentNgFactory };
