<form *ngIf="consentForm" [formGroup]="consentForm" (ngSubmit)="acceptConsent()">
  <div formArrayName="consentArray">
    <div [formGroup]="consent" class="my-3 p-3 rounded border bg-white"
      [ngClass]="{'border-danger': !(consent | formGroup).valid && (consent | formGroup).touched}"
      *ngFor="let consent of consentListFA.controls; let i = index;">
      <h6 class="text-center" id="consent-field-name-text-{{i}}">
        {{(consent | formGroup).controls.name.value}}
      </h6>
      <div class="pre-line-text" id="consent-field-content-text-{{i}}" [innerHTML]="(consent | formGroup).controls.content.value">
      </div>
      <div>
        <input [id]="'consent-field-accept-radio-input-' + (consent | formGroup).controls.versionString.value" type="radio" [value]=true
          formControlName="accept"><label id="consent-field-accept-radio-text-{{i}}"
          [for]="'consent-field-accept-radio-input-' + (consent | formGroup).controls.versionString.value">Accept</label>
      </div>
      <div *ngIf="!(consent | formGroup).controls.isRequired.value">
        <input [id]="'consent-field-decline-radio-input-' + (consent | formGroup).controls.versionString.value" type="radio" [value]=false
          formControlName="accept"><label id="consent-field-decline-radio-text-{{i}}"
          [for]="'consent-field-decline-radio-input-' + (consent | formGroup).controls.versionString.value">Decline</label>
      </div>
    </div>
  </div>
  <div class="text-center">
    <button id="consent-next-button" class="btn btn-primary" type="submit">Next</button>
  </div>

</form>