/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./alert-collect-badge-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./alert-collect-badge-modal.component";
import * as i4 from "ngx-bootstrap/modal";
var styles_AlertCollectBadgeModalComponent = [i0.styles];
var RenderType_AlertCollectBadgeModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AlertCollectBadgeModalComponent, data: {} });
export { RenderType_AlertCollectBadgeModalComponent as RenderType_AlertCollectBadgeModalComponent };
export function View_AlertCollectBadgeModalComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { template: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "modal-body text-center m-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "border border-dark box"], ["id", "checkin-alert-collect-badge-modal-background"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(4, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(5, { "background-color": 0 }), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h4", [["id", "checkin-alert-collect-badge-modal-warning-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Please collect your badge."])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "p", [["class", "text-title"], ["id", "checkin-alert-collect-badge-modal-remark-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["This badge is valid for the 3 day of conference. Please wear it throughout the conference period."]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 5, 0, _co.color); _ck(_v, 4, 0, currVal_0); }, null); }
export function View_AlertCollectBadgeModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-alert-collect-badge-modal", [], null, null, null, View_AlertCollectBadgeModalComponent_0, RenderType_AlertCollectBadgeModalComponent)), i1.ɵdid(1, 114688, null, 0, i3.AlertCollectBadgeModalComponent, [i4.BsModalRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AlertCollectBadgeModalComponentNgFactory = i1.ɵccf("app-alert-collect-badge-modal", i3.AlertCollectBadgeModalComponent, View_AlertCollectBadgeModalComponent_Host_0, {}, { timeout: "timeout" }, []);
export { AlertCollectBadgeModalComponentNgFactory as AlertCollectBadgeModalComponentNgFactory };
