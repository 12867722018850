/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./login.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "ngx-intl-tel-input";
import * as i4 from "@angular/common";
import * as i5 from "../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i6 from "@fortawesome/angular-fontawesome";
import * as i7 from "@angular/platform-browser";
import * as i8 from "./login.component";
import * as i9 from "@angular/router";
import * as i10 from "../shared/services/auth.service";
import * as i11 from "@ent-regis/entregis-ts-angular/api/registerCompanyController.service";
import * as i12 from "@ent-regis/entregis-ts-angular/api/registerInvestorController.service";
import * as i13 from "ngx-bootstrap/modal";
import * as i14 from "@ent-regis/entregis-ts-angular/api/consentController.service";
import * as i15 from "@ent-regis/entregis-ts-angular/api/eventPublicController.service";
import * as i16 from "../shared/services/alert.service";
var styles_LoginComponent = [i0.styles];
var RenderType_LoginComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoginComponent, data: {} });
export { RenderType_LoginComponent as RenderType_LoginComponent };
function View_LoginComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["class", "my-3"], ["id", "event-logo"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [["class", "text-muted mb-3"], ["id", "login-period-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.eventInfo.eventLogoUrl, ""); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.getPeriodString(_co.eventInfo); _ck(_v, 3, 0, currVal_1); }); }
function View_LoginComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [["class", "my-3"], ["id", "login-event-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [["class", "text-muted mb-3"], ["id", "login-period-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.eventInfo.eventName; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.getPeriodString(_co.eventInfo); _ck(_v, 4, 0, currVal_1); }); }
function View_LoginComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "alert alert-danger"], ["id", "login-event-warning-text"], ["role", "alert"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.eventTextWarning; _ck(_v, 0, 0, currVal_0); }); }
function View_LoginComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 26, "div", [["class", "mt-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 25, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i2.ɵangular_packages_forms_forms_z, [], null, null), i1.ɵdid(3, 540672, null, 0, i2.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i2.ControlContainer, null, [i2.FormGroupDirective]), i1.ɵdid(5, 16384, null, 0, i2.NgControlStatusGroup, [[4, i2.ControlContainer]], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "label", [["class", "col-form-label"], ["for", "login-field-email-input"], ["id", "login-field-email-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Email"])), (_l()(), i1.ɵeld(8, 0, null, null, 6, "input", [["class", "form-control mb-2"], ["formControlName", "username"], ["id", "login-field-email-input"], ["placeholder", "Enter your email"], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 9).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 9)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 9)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i1.ɵdid(11, 671744, null, 0, i2.FormControlName, [[3, i2.ControlContainer], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR], [2, i2.ɵangular_packages_forms_forms_q]], { name: [0, "name"] }, null), i1.ɵprd(2048, null, i2.NgControl, null, [i2.FormControlName]), i1.ɵdid(13, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), i1.ɵdid(14, 81920, null, 0, i3.NativeElementInjectorDirective, [i2.NgControl, i1.ElementRef], null, null), (_l()(), i1.ɵeld(15, 0, null, null, 1, "label", [["class", "col-form-label"], ["for", "login-field-password-input"], ["id", "login-field-password-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Password"])), (_l()(), i1.ɵeld(17, 0, null, null, 6, "input", [["class", "form-control mb-2"], ["formControlName", "password"], ["id", "login-field-password-input"], ["placeholder", "Enter your password"], ["type", "password"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 18)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 18).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 18)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 18)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(18, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i1.ɵdid(20, 671744, null, 0, i2.FormControlName, [[3, i2.ControlContainer], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR], [2, i2.ɵangular_packages_forms_forms_q]], { name: [0, "name"] }, null), i1.ɵprd(2048, null, i2.NgControl, null, [i2.FormControlName]), i1.ɵdid(22, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), i1.ɵdid(23, 81920, null, 0, i3.NativeElementInjectorDirective, [i2.NgControl, i1.ElementRef], null, null), (_l()(), i1.ɵeld(24, 0, null, null, 2, "div", [["class", "justify-content-center mt-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 1, "button", [["class", "btn btn-orange d-block w-100"], ["id", "login-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.login() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["LOGIN"]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.form; _ck(_v, 3, 0, currVal_7); var currVal_15 = "username"; _ck(_v, 11, 0, currVal_15); _ck(_v, 14, 0); var currVal_23 = "password"; _ck(_v, 20, 0, currVal_23); _ck(_v, 23, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 5).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 5).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 5).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 5).ngClassValid; var currVal_5 = i1.ɵnov(_v, 5).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 5).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_8 = i1.ɵnov(_v, 13).ngClassUntouched; var currVal_9 = i1.ɵnov(_v, 13).ngClassTouched; var currVal_10 = i1.ɵnov(_v, 13).ngClassPristine; var currVal_11 = i1.ɵnov(_v, 13).ngClassDirty; var currVal_12 = i1.ɵnov(_v, 13).ngClassValid; var currVal_13 = i1.ɵnov(_v, 13).ngClassInvalid; var currVal_14 = i1.ɵnov(_v, 13).ngClassPending; _ck(_v, 8, 0, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14); var currVal_16 = i1.ɵnov(_v, 22).ngClassUntouched; var currVal_17 = i1.ɵnov(_v, 22).ngClassTouched; var currVal_18 = i1.ɵnov(_v, 22).ngClassPristine; var currVal_19 = i1.ɵnov(_v, 22).ngClassDirty; var currVal_20 = i1.ɵnov(_v, 22).ngClassValid; var currVal_21 = i1.ɵnov(_v, 22).ngClassInvalid; var currVal_22 = i1.ɵnov(_v, 22).ngClassPending; _ck(_v, 17, 0, currVal_16, currVal_17, currVal_18, currVal_19, currVal_20, currVal_21, currVal_22); }); }
function View_LoginComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "my-auto justify-content-center border card-form"], ["id", "login-card"], ["style", "width: 612px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h4", [["class", "d-flex justify-content-center"], ["id", "login-title-text"], ["style", "color: #009FD4;"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", " Login"])), i1.ɵppd(3, 1), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoginComponent_5)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoginComponent_6)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.canLogin; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.canLogin; _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent, 0), _co.loginType)); _ck(_v, 2, 0, currVal_0); }); }
function View_LoginComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "text-danger"], ["id", "login-contact-admin-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["If you forgot password please contact ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.eventInfo.emailFrom; _ck(_v, 1, 0, currVal_0); }); }
function View_LoginComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "text-center mt-2"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoginComponent_8)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.eventInfo.emailFrom; _ck(_v, 2, 0, currVal_0); }, null); }
function View_LoginComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "common-img default-img h-100"]], [[4, "background-image", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "row bg-white banner-block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 8, "div", [["class", "container d-flex"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoginComponent_2)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoginComponent_3)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [["class", "my-auto ml-auto"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "button", [["class", "btn btn-outline-danger border-0"], ["id", "login-logout-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "fa-icon", [["class", "ng-fa-icon"]], [[1, "title", 0], [8, "innerHTML", 1]], null, null, i5.View_FaIconComponent_0, i5.RenderType_FaIconComponent)), i1.ɵdid(10, 573440, null, 0, i6.FaIconComponent, [i7.DomSanitizer, i6.FaConfig, i6.FaIconLibrary, [2, i6.FaStackItemSizeDirective]], { icon: [0, "icon"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "container d-flex justify-content-center login-box"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoginComponent_4)), i1.ɵdid(13, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoginComponent_7)), i1.ɵdid(15, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.eventInfo.eventLogoUrl; _ck(_v, 4, 0, currVal_1); var currVal_2 = !_co.eventInfo.eventLogoUrl; _ck(_v, 6, 0, currVal_2); var currVal_5 = _co.faSignOutAlt; _ck(_v, 10, 0, currVal_5); var currVal_6 = _co.eventInfo; _ck(_v, 13, 0, currVal_6); var currVal_7 = _co.eventInfo; _ck(_v, 15, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.eventInfo.bgUrl ? (("url(" + _co.eventInfo.bgUrl) + ")") : "url(../../assets/images/defaultBg1.png)"); _ck(_v, 0, 0, currVal_0); var currVal_3 = i1.ɵnov(_v, 10).title; var currVal_4 = i1.ɵnov(_v, 10).renderedIconHTML; _ck(_v, 9, 0, currVal_3, currVal_4); }); }
export function View_LoginComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i4.TitleCasePipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoginComponent_1)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.eventInfo; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_LoginComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-login", [], null, null, null, View_LoginComponent_0, RenderType_LoginComponent)), i1.ɵdid(1, 114688, null, 0, i8.LoginComponent, [i9.Router, i10.AuthService, i11.RegisterCompanyControllerService, i12.RegisterInvestorControllerService, i13.BsModalService, i14.ConsentControllerService, i9.ActivatedRoute, i15.EventPublicControllerService, i16.AlertService, i4.DatePipe, i7.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoginComponentNgFactory = i1.ɵccf("app-login", i8.LoginComponent, View_LoginComponent_Host_0, {}, {}, []);
export { LoginComponentNgFactory as LoginComponentNgFactory };
