import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConsentComponent } from './consent.component';
import { ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '../pipes/pipes.module';
import { ModalModule } from 'ngx-bootstrap';



@NgModule({
  entryComponents: [
    ConsentComponent
  ],
  declarations: [
    ConsentComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    PipesModule,
    ModalModule.forRoot(),
  ],
  exports: [
    ConsentComponent
  ]
})
export class ConsentModule { }
