import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  InvestorHotelControllerService, InvestorHotelRequest, DietaryPublicControllerService,
  DietaryResponse,
  EventInfoWithConsentResponse
} from '@ent-regis/entregis-ts-angular';
import { msg } from 'src/app/constants/msg';
import { forkJoin, Subscription } from 'rxjs';
import { RegisterInvestorService } from 'src/app/core/service/register-investor.service';
import { first } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { AlertService } from 'src/app/shared/services/alert.service';
import { EventService } from 'src/app/shared/services/event.service';
@Component({
  selector: 'app-hotel',
  templateUrl: './hotel.component.html',
  styleUrls: ['./hotel.component.scss']
})
export class HotelComponent implements OnInit, OnDestroy {

  private subscription = new Subscription();

  form: FormGroup;
  dietaryList: DietaryResponse[] = [];
  registerInvestorId: number;
  isCloseRegisterInvestor: boolean;
  event: EventInfoWithConsentResponse;

  constructor(
    private investorHotelService: InvestorHotelControllerService,
    private dietaryService: DietaryPublicControllerService,
    private registerInvestorService: RegisterInvestorService,
    private datePipe: DatePipe,
    private alert: AlertService,
    private eventService: EventService
  ) {
  }

  ngOnInit() {
    this.subscription.add(this.eventService.eventSubject.pipe(first(x => x != null))
    .subscribe(event => {
      this.event = event;
    }));
    this.subscription.add(this.registerInvestorService.investorProfile.subscribe(profile => {
      this.registerInvestorId = profile.registerInvestorId ? profile.registerInvestorId : 0;
      this.getDetail();
    }));
    this.checkIsCloseRegister();
  }

  ngOnDestroy() {

  }

  checkIsCloseRegister() {
    this.registerInvestorService.isCloseRegister.pipe(first(x => x !== null))
      .subscribe(isClose => this.isCloseRegisterInvestor = isClose);
  }

  getDetail(): void {
    const dietaryAPI = this.dietaryService.getDietaryUsingGET();
    const investorHotelAPI = this.investorHotelService.getInvestorHotelUsingGET1(this.registerInvestorId);
    this.subscription.add(forkJoin([dietaryAPI, investorHotelAPI]).subscribe(
      (results) => {
        this.dietaryList = results[0].data;
        this.form = new FormGroup({
          hotelName: new FormControl(results[1].data.hotelName, Validators.maxLength(255)),
          checkInDate: new FormControl(this.dateStringToDate(results[1].data.checkInDate)),
          checkOutDate: new FormControl(this.dateStringToDate(results[1].data.checkOutDate)),
          arrivalDate: new FormControl(this.dateStringToDate(results[1].data.arrivalDate)),
          arrivalTime: new FormControl(this.timeStringToDate(results[1].data.arrivalTime)),
          arrivalFight: new FormControl(results[1].data.arrivalFlight),
          departmentDate: new FormControl(this.dateStringToDate(results[1].data.departmentDate)),
          departmentTime: new FormControl(this.timeStringToDate(results[1].data.departmentTime)),
          departmentFight: new FormControl(results[1].data.departmentFlight),
          dietaryId: new FormControl(results[1].data.dietaryId ? results[1].data.dietaryId : ''),
          otherDietary: new FormControl(results[1].data.otherDietary)
        });
      }
    ));
  }

  onSave(): void {
    if (this.form.valid) {
      const obj: InvestorHotelRequest = {
        hotelName: this.form.value.hotelName,
        checkInDate: this.dateToDateString(this.form.value.checkInDate),
        checkOutDate: this.dateToDateString(this.form.value.checkOutDate),
        arrivalDate: this.dateToDateString(this.form.value.arrivalDate),
        arrivalTime: this.dateToTimeString(this.form.value.arrivalTime),
        arrivalFlight: this.form.value.arrivalFight,
        departmentDate: this.dateToDateString(this.form.value.departmentDate),
        departmentTime: this.dateToTimeString(this.form.value.departmentTime),
        departmentFlight: this.form.value.departmentFight,
        dietaryId: this.form.value.dietaryId,
        otherDietary: this.form.value.otherDietary
      };
      this.investorHotelService.createAndUpdateInvestorHotelUsingPOST1(obj, this.registerInvestorId)
        .subscribe(
          (result) => {
            this.alert.showSuccess(msg.success.dataSaved);
          }
        );
    }
  }

  dateToDateString(date: Date): string {
    if (date == null) return null;
    return this.datePipe.transform(date, 'yyyy-MM-dd');
  }

  dateToTimeString(date: Date): string {
    if (date == null) return null;
    return this.datePipe.transform(date, 'HH:mm');
  }

  dateStringToDate(dateString: string): Date {
    if (this.stringNotBlank(dateString)) return null;
    const re  = /(\d{4})-(\d{2})-(\d{2})/;
    if (re.test(dateString)) {
      return new Date(+RegExp.$1, +RegExp.$2 - 1, +RegExp.$3);
    }
    return null;
  }

  timeStringToDate(timeString: string): Date {
    if (this.stringNotBlank(timeString)) return null;

    const hour = +timeString.split(':')[0];
    const min = +timeString.split(':')[1];

    const date = new Date();
    date.setHours(hour);
    date.setMinutes(min);

    return date;
  }

  stringNotBlank(str: String) {
    return !(str && str.trim().length > 0);
  }
}
