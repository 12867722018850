/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./not-found-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./not-found-modal.component";
import * as i3 from "ngx-bootstrap/modal";
import * as i4 from "@angular/router";
var styles_NotFoundModalComponent = [i0.styles];
var RenderType_NotFoundModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotFoundModalComponent, data: {} });
export { RenderType_NotFoundModalComponent as RenderType_NotFoundModalComponent };
export function View_NotFoundModalComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { template: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "modal-body text-center m-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["class", "m-2"], ["height", "100px"], ["id", "checkin-not-found-modal-image"], ["src", "assets/images/Ico_InfoNotFound.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [["class", "text-title"], ["id", "checkin-not-found-modal-not-found-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Your information is not found."])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [["class", "text-body"], ["id", "checkin-not-found-modal-warning-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Please proceed to the Conference Help Desk for assistance."])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "button", [["class", "btn btn-lg btn-secondary-white mx-2 mt-1"], ["id", "checkin-not-found-modal-home-button"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.home() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Main Menu"])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "button", [["class", "btn btn-lg btn-primary-white mt-1"], ["id", "checkin-not-found-modal-retry-button"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.retry() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Try Again"]))], null, null); }
export function View_NotFoundModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-not-found-modal", [], null, null, null, View_NotFoundModalComponent_0, RenderType_NotFoundModalComponent)), i1.ɵdid(1, 114688, null, 0, i2.NotFoundModalComponent, [i3.BsModalRef, i4.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NotFoundModalComponentNgFactory = i1.ɵccf("app-not-found-modal", i2.NotFoundModalComponent, View_NotFoundModalComponent_Host_0, {}, { retryScan: "retryScan" }, []);
export { NotFoundModalComponentNgFactory as NotFoundModalComponentNgFactory };
