/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./register-company-confirm-rm-participant-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./register-company-confirm-rm-participant-modal.component";
import * as i3 from "ngx-bootstrap/modal";
import * as i4 from "@ent-regis/entregis-ts-angular/api/participantController.service";
var styles_RegisterCompanyConfirmRmParticipantModalComponent = [i0.styles];
var RenderType_RegisterCompanyConfirmRmParticipantModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RegisterCompanyConfirmRmParticipantModalComponent, data: {} });
export { RenderType_RegisterCompanyConfirmRmParticipantModalComponent as RenderType_RegisterCompanyConfirmRmParticipantModalComponent };
export function View_RegisterCompanyConfirmRmParticipantModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "modal-body text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "p", [["id", "company-confirm-remove-modal-title-text"], ["style", "font-size: 16px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Confirm Delete"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "button", [["class", "btn btn-secondary mt-1"], ["id", "company-confirm-remove-modal-cancel-button"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["CANCEL"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "button", [["class", "btn btn-primary ml-2 mt-1"], ["id", "company-confirm-remove-modal-confirm-button"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["CONFIRM"]))], null, null); }
export function View_RegisterCompanyConfirmRmParticipantModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-register-company-confirm-rm-participant-modal", [], null, null, null, View_RegisterCompanyConfirmRmParticipantModalComponent_0, RenderType_RegisterCompanyConfirmRmParticipantModalComponent)), i1.ɵdid(1, 114688, null, 0, i2.RegisterCompanyConfirmRmParticipantModalComponent, [i3.BsModalRef, i4.ParticipantControllerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RegisterCompanyConfirmRmParticipantModalComponentNgFactory = i1.ɵccf("app-register-company-confirm-rm-participant-modal", i2.RegisterCompanyConfirmRmParticipantModalComponent, View_RegisterCompanyConfirmRmParticipantModalComponent_Host_0, {}, { updateCompleted: "updateCompleted" }, []);
export { RegisterCompanyConfirmRmParticipantModalComponentNgFactory as RegisterCompanyConfirmRmParticipantModalComponentNgFactory };
