import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { MeetingRequestComponent } from './meeting-request/meeting-request.component';
import { AvailableTimeComponent } from './available-time/available-time.component';
import { InvestorInfoComponent } from './investor-info/investor-info.component';
import { first, map } from 'rxjs/operators';
import { RegisterInvestorService } from '../core/service/register-investor.service';
import { EventInfoWithConsentResponse, InvestorMeetingUpdateRequest, RegisterInvestorResponse } from '@ent-regis/entregis-ts-angular';
import { msg } from '../constants/msg';
import { EventService } from '../shared/services/event.service';
import { AlertService } from '../shared/services/alert.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-investor',
  templateUrl: './investor.component.html',
  styleUrls: ['./investor.component.scss']
})
export class InvestorComponent implements OnInit {

  public now = new Date();
  eventTypeEnum = EventInfoWithConsentResponse.EventTypeEnum;
  eventType: EventInfoWithConsentResponse.EventTypeEnum;
  registerInvestorId: number;
  investorProfile: RegisterInvestorResponse;
  isShowHotel: boolean;
  startDate: Date;
  endDate: Date;
  isClosedRegis: boolean = false;
  leave: boolean = true;

  constructor(
    private route: ActivatedRoute,
    private registerInvestorService: RegisterInvestorService,
    private eventService: EventService,
    private alert: AlertService,
    private router: Router
  ) { }

  ngOnInit() {
    this.getRegisterInvestorId();
    this.getRegisterInvestorInfo();
    this.getEventInfo();
    this.registerInvestorService.canLeavePage$.subscribe(x=>{
      this.leave = x;
    })
  }

  getRegisterInvestorId() {
    this.route.paramMap
      .pipe(map((x: ParamMap) => x.get('registerInvestorId')))
      .subscribe((x: string) => {
        this.registerInvestorService.registerInvestorId.next(+x);
      });
  }

  getRegisterInvestorInfo() {
    this.registerInvestorService.investorProfile.pipe(first()).subscribe(data => {
      this.investorProfile = data;
      this.registerInvestorService.isCloseRegister.next(this.investorProfile.isCloseRegister);
    });
  }

  getEventInfo() {
    this.eventService.eventSubject.pipe(first(x => x != null)).subscribe(event => {
      this.isShowHotel = event.isShowHotel;
      this.startDate = event.startDateRegisterInvestor;
      this.endDate = event.endDateRegisterInvestor;
      this.isClosedRegis = event.isCloseRegisterInvestor;
      this.eventType = event.eventType;
    });
  }

  saveRegisterInvestorProfile(investorProfile: RegisterInvestorResponse) {
    this.registerInvestorService.saveRegisterInvestorInfo(investorProfile, () => {
      this.alert.showSuccess(msg.success.dataSaved).then(() => {
        this.registerInvestorService.fetchAllData();
      });
    });
  }

  saveMeetingRequest(meetingUpdateRequest: InvestorMeetingUpdateRequest) {
    this.registerInvestorService.saveInvestorMeetingRequest(meetingUpdateRequest, () => {
      this.alert.showSuccess(msg.success.dataSaved).then(() => {
        this.registerInvestorService.fetchInvestorMeetingRequest();
      });
    });
  }

  cancelMeetingRequest(meetingRequestIds: number) {
    this.registerInvestorService.cancelInvestorMeetingRequestById(meetingRequestIds, () => {
      this.registerInvestorService.fetchInvestorMeetingRequest();
    });
  }

  isActive(path: any[]): boolean {
    let urlTree = this.router.createUrlTree(path, {relativeTo: this.route})
    return this.router.isActive(urlTree,false);
  }
  gotoTab(path: any[]){
    if(this.leave){
      let urlTree = this.router.createUrlTree(path, {relativeTo: this.route})
      this.router.navigateByUrl(urlTree);
    }else{
      Swal.fire({
        confirmButtonColor: '#fbaf47',
        heightAuto: false,
        title: msg.confirmLeave.title,
        text: msg.confirmLeave.text,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: msg.confirmLeave.confirmButtonText,
        cancelButtonText: msg.confirmLeave.cancelButtonText,
        showClass: {
          popup: 'animate__animated animate__fadeIn animate__faster'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOut animate__faster'
        }
      }).then((result) => {
        if (result.value) {
          this.registerInvestorService.canLeavePage$.next(true);
          let urlTree = this.router.createUrlTree(path, {relativeTo: this.route})
          this.router.navigateByUrl(urlTree);
        }
      });
    }
  }

  onActiveTab(componentReference: Component) {
    if (componentReference instanceof MeetingRequestComponent) {
      const meetingRequestComponent = componentReference as MeetingRequestComponent;
      meetingRequestComponent.willSave.subscribe((x: InvestorMeetingUpdateRequest) => {
        this.saveMeetingRequest(x);
      });

      meetingRequestComponent.willCancel.subscribe((x: number) => {
        this.cancelMeetingRequest(x);
      });

    } else if (componentReference instanceof AvailableTimeComponent) {
      const availableTimeComponent = componentReference as AvailableTimeComponent;

    } else if (componentReference instanceof InvestorInfoComponent) {
      const investorInfoComponent = componentReference as InvestorInfoComponent;

      investorInfoComponent.willSave.subscribe((x: RegisterInvestorResponse) => {
        this.saveRegisterInvestorProfile(x);
      });
    }
  }
  

}
