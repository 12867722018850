/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./timetable.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./timetable.component";
import * as i4 from "@ent-regis/entregis-ts-angular/api/companySlotController.service";
import * as i5 from "@ent-regis/entregis-ts-angular/api/participantController.service";
import * as i6 from "../../shared/services/alert.service";
var styles_TimetableComponent = [i0.styles];
var RenderType_TimetableComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TimetableComponent, data: {} });
export { RenderType_TimetableComponent as RenderType_TimetableComponent };
function View_TimetableComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "label", [["class", "d-flex flex-column"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "mr-2"]], [[8, "id", 0]], null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [], [[8, "id", 0]], null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "text-muted"]], [[8, "id", 0]], null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(3, "company-time-field-firstname-text-", _v.parent.parent.parent.context.index, "-", _v.parent.parent.context.index, "-", _v.context.index, ""); _ck(_v, 4, 0, currVal_0); var currVal_1 = _v.context.$implicit.firstName; _ck(_v, 5, 0, currVal_1); var currVal_2 = i1.ɵinlineInterpolate(3, "company-time-field-lastname-text-", _v.parent.parent.parent.context.index, "-", _v.parent.parent.context.index, "-", _v.context.index, ""); _ck(_v, 6, 0, currVal_2); var currVal_3 = _v.context.$implicit.lastName; _ck(_v, 7, 0, currVal_3); var currVal_4 = i1.ɵinlineInterpolate(3, "company-time-field-job-text-", _v.parent.parent.parent.context.index, "-", _v.parent.parent.context.index, "-", _v.context.index, ""); _ck(_v, 8, 0, currVal_4); var currVal_5 = _v.context.$implicit.jobTitle; _ck(_v, 9, 0, currVal_5); }); }
function View_TimetableComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TimetableComponent_4)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.participants; _ck(_v, 2, 0, currVal_0); }, null); }
function View_TimetableComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "tr", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 4, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "input", [["type", "checkbox"]], [[8, "id", 0], [8, "name", 0], [8, "disabled", 0], [8, "checked", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onSelectionChange(_v.parent.context.index, _v.context.$implicit.slotPeriodId, ($event.target.checked ? "Y" : "N"), _v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(5, null, [" ", " - ", " "])), i1.ɵppd(6, 3), i1.ɵppd(7, 3), (_l()(), i1.ɵeld(8, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TimetableComponent_3)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_v.context.$implicit.available === null) && _co.submited) ? "invalid" : "dd"); _ck(_v, 2, 0, currVal_0); var currVal_7 = (_v.context.$implicit.available === "Y"); _ck(_v, 10, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵinlineInterpolate(2, "company-time-field-period-available-input-", _v.parent.context.index, "-", _v.context.index, ""); var currVal_2 = i1.ɵinlineInterpolate(2, "available_", _v.parent.context.index, "_", _v.context.$implicit.slotPeriodId, ""); var currVal_3 = _co.isCloseRegister; var currVal_4 = (_v.context.$implicit.available === "Y"); _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = i1.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i1.ɵnov(_v.parent.parent, 0), _v.context.$implicit.startTime, "H:mm", (_co.isEventTypeThailandFocus ? "UTC +7" : null))); var currVal_6 = i1.ɵunv(_v, 5, 1, _ck(_v, 7, 0, i1.ɵnov(_v.parent.parent, 0), _v.context.$implicit.endTime, "H:mm", (_co.isEventTypeThailandFocus ? "UTC +7" : null))); _ck(_v, 5, 0, currVal_5, currVal_6); }); }
function View_TimetableComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "tr", [["style", "background-color: #0064C5;color: white;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "td", [["colspan", "5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "strong", [], [[8, "id", 0]], null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵppd(5, 3), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TimetableComponent_2)), i1.ɵdid(7, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_2 = _v.context.$implicit.slotPeriods; _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "company-time-field-slot-date-text-", _v.context.index, ""); _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v.parent, 0), _v.context.$implicit.slotDate, "MMM dd, yyyy", (_co.isEventTypeThailandFocus ? "UTC +7" : null))); _ck(_v, 4, 0, currVal_1); }); }
export function View_TimetableComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "tableFixHead table-card-form"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 9, "table", [["class", "table "], ["id", "company-time-table"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "thead", [["class", "thead-white-blue"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "th", [["id", "company-time-field-period-column-text"], ["scope", "col"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Start-End Time"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "th", [["id", "company-time-field-participant-column-text"], ["scope", "col"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Participants"])), (_l()(), i1.ɵeld(9, 0, null, null, 2, "tbody", [["class", "bg-white"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TimetableComponent_1)), i1.ɵdid(11, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 2, "div", [["class", "d-flex justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "button", [["class", "btn btn-primary my-2"], ["id", "company-time-save-button"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.save() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Save"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.slots; _ck(_v, 11, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.isCloseRegister; _ck(_v, 13, 0, currVal_1); }); }
export function View_TimetableComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-timetable", [], null, null, null, View_TimetableComponent_0, RenderType_TimetableComponent)), i1.ɵdid(1, 114688, null, 0, i3.TimetableComponent, [i4.CompanySlotControllerService, i5.ParticipantControllerService, i6.AlertService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TimetableComponentNgFactory = i1.ɵccf("app-timetable", i3.TimetableComponent, View_TimetableComponent_Host_0, { isEventTypeThailandFocus: "isEventTypeThailandFocus", registerCompanyId: "registerCompanyId", isCloseRegister: "isCloseRegister" }, { setCanLeave: "setCanLeave" }, []);
export { TimetableComponentNgFactory as TimetableComponentNgFactory };
