<div class="d-flex flex-column align-items-start p-2" style="border-top: 2px solid #0064c5;margin-top: 1px;">
    <br>
    <p id="comopany-presentation-warning-text">Manage your presentation here <span class="text-danger">(.png,.jpg,.pdf,.ppt,.pptx Maximum upload file size: 20MB.)</span></p>
    <div class="card-form" style="min-width: 350px;" [class.d-none]="registerCompanyInfo?.presentationUrl && registerCompanyInfo?.originalPresentationFileName">
        <input id="comopany-presentation-input" type="file" #presentationFile name="presentationFile" accept="image/x-png,image/jpeg,application/pdf,application/pdf,
        application/vnd.ms-powerpoint,
        application/vnd.openxmlformats-officedocument.presentationml.slideshow,
        application/vnd.openxmlformats-officedocument.presentationml.presentation" id="presentationFile"
            (change)=uploadPresentationFile(presentationFile.files[0])>
    </div>
    <div class="card-form" style="min-width: 350px;" *ngIf="registerCompanyInfo?.presentationUrl && registerCompanyInfo?.originalPresentationFileName">
        <!-- <span class="text-danger"
            *ngIf="registerCompanyInfo?.presentationUrl && registerCompanyInfo?.originalPresentationFileName">* Upload new presentation will replace the current one.</span> -->
        <!-- <span class="text-danger" *ngIf="isShowWarning"><br> * Unable to upload file over 20MB.</span> -->
        <div>
            <span id="comopany-presentation-file-name-text" class="text-light-blue mr-2"><fa-icon [icon]="faLink"></fa-icon> {{registerCompanyInfo.originalPresentationFileName}}</span>
            
            <button id="comopany-presentation-download-button" class="btn btn-outline-dark border-0" (click)=download()>
                <fa-icon [icon]="faDownload"></fa-icon>
            </button>
            <button id="comopany-presentation-edit-button" class="btn btn-outline-primary border-0" (click)=presentationFile.click()>
                <fa-icon [icon]="faEdit"></fa-icon>
            </button>
            <button id="comopany-presentation-delete-button" class="btn btn-outline-danger border-0" (click)=delete()>
                <fa-icon [icon]="faTrash"></fa-icon>
            </button>
        </div>
    </div>
</div>

<app-confirm-modal #confirmModal></app-confirm-modal>
