import { EventEmitter, Input, Output } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ConsentResponse } from '@ent-regis/entregis-ts-angular';
import { ConsentAcceptanceRequest } from '@ent-regis/entregis-ts-angular/model/consentAcceptanceRequest';

@Component({
  selector: 'app-consent',
  templateUrl: './consent.component.html',
  styleUrls: ['./consent.component.scss']
})
export class ConsentComponent implements OnInit {

  consentForm: FormGroup = new FormGroup({
    consentArray: new FormArray([])
  })

  get consentListFA() { return this.consentForm.controls.consentArray as FormArray; }

  @Input() onSuccess: (consents: ConsentAcceptanceRequest[]) => void = undefined;
  @Input() consents: ConsentResponse[] = [];
  @Output() onSuccessEvent = new EventEmitter<ConsentAcceptanceRequest[]>();

  constructor() { }

  ngOnInit() {
    this.consents.forEach(consent => {
      this.consentListFA.push(new FormGroup({
        name: new FormControl(consent.consentName),
        content: new FormControl(consent.consentContent),
        isRequired: new FormControl(consent.isRequired),
        versionString: new FormControl(consent.consentVersionString),
        consentId: new FormControl(consent.consentVersionId),
        accept: new FormControl(null, Validators.required)
      }))
    });
  }

  get consentAcceptedForm() { return this.consentForm.controls.consentAccepted; }

  acceptConsent() {
    if (!this.consentForm.valid) {
      this.consentForm.markAllAsTouched();
      return;
    }

    const arr = this.consentListFA.controls.map((x: FormGroup) => {
      return {
        consentVersionId: x.controls.consentId.value,
        isAccept: x.controls.accept.value
      }
    });

    if (this.onSuccess) {
      this.onSuccess(arr)
    }
    this.onSuccessEvent.emit(arr);
  }

}
