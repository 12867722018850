/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./confirm-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./confirm-modal.component";
import * as i3 from "ngx-bootstrap/modal";
var styles_ConfirmModalComponent = [i0.styles];
var RenderType_ConfirmModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfirmModalComponent, data: {} });
export { RenderType_ConfirmModalComponent as RenderType_ConfirmModalComponent };
function View_ConfirmModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "modal-body text-center"], ["id", "confirm-modal"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "p", [["id", "confirm-title-text"], ["style", "font-size: 16px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "message"], ["id", "confirm-content-text"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "button", [["class", "btn btn-secondary mt-1 text-uppercase"], ["id", "confirm-cancle-button"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClose() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["CANCEL"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "button", [["class", "btn btn-primary ml-2 mt-1 text-uppercase"], ["id", "confirm-button"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSubmit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(8, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.message; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.btnConfirm; _ck(_v, 8, 0, currVal_2); }); }
export function View_ConfirmModalComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { template: 0 }), (_l()(), i1.ɵand(0, [[1, 2], ["template", 2]], null, 0, null, View_ConfirmModalComponent_1))], null, null); }
export function View_ConfirmModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-confirm-modal", [], null, null, null, View_ConfirmModalComponent_0, RenderType_ConfirmModalComponent)), i1.ɵdid(1, 114688, null, 0, i2.ConfirmModalComponent, [i3.BsModalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConfirmModalComponentNgFactory = i1.ɵccf("app-confirm-modal", i2.ConfirmModalComponent, View_ConfirmModalComponent_Host_0, {}, {}, []);
export { ConfirmModalComponentNgFactory as ConfirmModalComponentNgFactory };
