import { Configuration } from '@ent-regis/entregis-ts-angular';
import { Injectable } from '@angular/core';

@Injectable()
export class AppState {

    constructor(
        private apiConfig: Configuration
    ) { }


    logout() {

    }

}
