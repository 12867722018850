import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Adal8Service } from 'adal-angular8';
import { EntRegisAuthService } from '../shared/services/ent-regis-auth.service';

@Component({
  selector: 'app-login-admin',
  templateUrl: './login-admin.component.html',
  styleUrls: ['./login-admin.component.scss']
})
export class LoginAdminComponent implements OnInit {

  eventAlias: string;
  constructor(
    private adalService: Adal8Service,
    private router: Router,
    private entRegisAuthService: EntRegisAuthService,
  ) { }

  async ngOnInit() {

    if (this.adalService.userInfo.authenticated) {
      const isAuthenticated = this.entRegisAuthService.isAuthenticated;
      if (typeof isAuthenticated === 'boolean') {
        this.decide(isAuthenticated);
      } else {
        const isAuthen = await isAuthenticated.toPromise();
        this.decide(isAuthen);
      }
    }
  }

  private async decide(isAuthen: boolean) {
    if (isAuthen) {
      this.goInside();
    } else {
      const response = await this.entRegisAuthService.getTokenWithAzureToken(this.adalService.getToken()).toPromise();
      const accessToken: string = response.access_token;
      const refreshToken: string = response.refresh_token;
      this.entRegisAuthService.updateToken(accessToken, refreshToken);
      this.goInside();
    }
  }

  login() {
    this.adalService.login();
  }

  goInside() {
    const eventAlias = localStorage.getItem('eventAlias');
    this.router.navigate(['checkin', eventAlias]);
  }

}
