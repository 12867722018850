import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { ScheduleInvestorResponse, ScheduleInvestorSlotPeriodResponse, SchedulePublicControllerService } from '@ent-regis/entregis-ts-angular';
import { faApple, faGoogle, faMicrosoft, faYahoo } from '@fortawesome/free-brands-svg-icons';
import { faAt, faCalendar, faDownload } from '@fortawesome/free-solid-svg-icons';
import { map } from 'rxjs/operators';
import { MeetingTypePipe } from '../shared/modules/pipes/meeting-type.pipe';
import { b64toBlob, saveFileBlob } from '../functions/file.function';

@Component({
  selector: 'app-schedule-investor',
  templateUrl: './schedule-investor.component.html',
  styleUrls: ['./schedule-investor.component.scss']
})
export class ScheduleInvestorComponent implements OnInit {
  public scheduleData: ScheduleInvestorResponse;
  public eventTypeEnum = ScheduleInvestorResponse.EventTypeEnum;
  public isErr: boolean = false;
  public haveAtLeaseOnePresentation = false;
  public faCalendar = faCalendar;
  public faApple = faApple;
  public faDownload = faDownload;
  public faGoogle = faGoogle;
  public faOutlook = faAt;
  public faYahoo = faYahoo;
  public faMicrosoft = faMicrosoft;
  public isoString = "yyyyMMddTHHmmss";
  private token: string;

  constructor(
    private route: ActivatedRoute,
    private scheduleService: SchedulePublicControllerService,
    private meetingTypePipe: MeetingTypePipe,
    private datePipe: DatePipe
  ) {
    this.route.paramMap
      .pipe(map((x: ParamMap) => x.get('token')))
      .subscribe((x: string) => {
        if (x) {
          this.token = x;
          this.scheduleService.getScheduleInvestorInfoUsingGET(this.token)
          .subscribe( result => {
            this.scheduleData = result.data;
            this.checkPresentation(result.data);
          }, () => {
            this.isErr = true;
          });
        }
      });
  }

  ngOnInit() {
  }

  checkPresentation(data: ScheduleInvestorResponse) {
    data.slots.forEach(slot => {
      slot.slotPeriods.forEach(p => {
        if (p.presentationLink) {
          this.haveAtLeaseOnePresentation = true;
        }
      })
    })
  }

  private getTitle(companyName: String): string {
    return `${companyName} : ${this.scheduleData.eventName} Meeting`;
  }

  private getDetail(event: ScheduleInvestorSlotPeriodResponse): string {
    const roomDetail = `Room Detail: ${event.room || ' - '}` + (event.roomDesc ? `(${event.roomDesc})` : '');
    const room = event.roomLink ? `Room: ${event.roomLink}` : roomDetail;
    const detail = `Meeting type: ${this.meetingTypePipe.transform(event.meetingType)}, ${room}`;
    return detail;
  }

  private getTime(time: Date, slotDate: Date): Date {
    const date = new Date(slotDate);
    date.setTime(new Date(time).getTime());
    return date;
  }

  public downloadIcs(start: Date, end: Date, title: string, description: string, url: string, location: string, organization?: string, locale?: string) {
    const startString = this.datePipe.transform(start, this.isoString);
    const endString = this.datePipe.transform(end, this.isoString);
    const ics = this.generateIcs(startString, endString, title, description, url, location, organization || 'default', locale || 'EN');
    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/calendar;charset=utf-8,' + encodeURIComponent(ics));
    element.setAttribute('download', `${title}.ics`);
    element.setAttribute('target', '_blank');
    element.style.display = 'none';
    element.click();
  }

  private generateIcs(start: string, end: string, title: string, description: string, url: string, location: string, organization: string, locale: string) {
    const timeStamp = this.datePipe.transform(new Date(), this.isoString);
    const uuid = `${timeStamp}Z-uid@${organization}`;
    // Don't ever format this string template
    const event = `BEGIN:VCALENDAR
PRODID:-//Events Calendar//${organization} 1.0//${locale}
VERSION:2.0
BEGIN:VEVENT
DTSTAMP:${timeStamp}Z
DTSTART;TZID=Asia/Bangkok:${start}
DTEND;TZID=Asia/Bangkok:${end}
SUMMARY:${title}
DESCRIPTION:${description}
LOCATION:${location}
UID:${uuid}
URL:${url}
END:VEVENT
END:VCALENDAR`;
    return event;
  }

  addAppleEvent(event: ScheduleInvestorSlotPeriodResponse, slotDate: Date): void {
    const title = this.getTitle(event.presenter);
    const body = this.getDetail(event);
    const startdt = this.getTime(event.startTime, slotDate);
    const enddt = this.getTime(event.endTime, slotDate);
    this.downloadIcs(startdt, enddt, title, body, '', '', '', 'TH');
  }

  addGoogleEvent(event: ScheduleInvestorSlotPeriodResponse, slotDate: Date): void {
    const title = this.getTitle(event.presenter);
    const startdt = this.getTime(event.startTime, slotDate);
    const enddt = this.getTime(event.endTime, slotDate);
    const isoString = "yyyyMMddTHHmmssZ";
    const date = `${this.datePipe.transform(startdt, isoString)}/${this.datePipe.transform(enddt, isoString)}`;
    const body = this.getDetail(event);

    const url= `http://www.google.com/calendar/event?action=TEMPLATE&text=${title}&dates=${date}&details=${body}`;
    window.open(url);
  }

  addOutlookEvent(event: ScheduleInvestorSlotPeriodResponse, slotDate: Date): void {
    const subject = this.getTitle(event.presenter);
    const body = this.getDetail(event);
    const startdt = this.getTime(event.startTime, slotDate);
    const enddt = this.getTime(event.endTime, slotDate);
    const url= `https://outlook.live.com/calendar/0/deeplink/compose?allday=false&body=${body}&enddt=${enddt.toISOString()}&rru=addevent&startdt=${startdt.toISOString()}&subject=${subject}`;
    window.open(url);
  }

  addOffice365Event(event: ScheduleInvestorSlotPeriodResponse, slotDate: Date): void {
    const subject = this.getTitle(event.presenter);
    const body = this.getDetail(event);
    const startdt = this.getTime(event.startTime, slotDate);
    const enddt = this.getTime(event.endTime, slotDate);
    const url= `https://outlook.office.com/calendar/deeplink/compose?allday=false&body=${body}&enddt=${enddt.toISOString()}&rru=addevent&startdt=${startdt.toISOString()}&subject=${subject}`;
    window.open(url);
  }

  addYahooEvent(event: ScheduleInvestorSlotPeriodResponse, slotDate: Date): void {
    const title = this.getTitle(event.presenter);
    const body = this.getDetail(event);
    const startdt = this.getTime(event.startTime, slotDate);
    const enddt = this.getTime(event.endTime, slotDate);
    const isoString = "yyyyMMddTHHmmss";
    const et = this.datePipe.transform(enddt, isoString);
    const st = this.datePipe.transform(startdt, isoString);
    const url= `https://calendar.yahoo.com/?v=60&view=d&type=20&title=${title}&st=${st}&et=${et}&desc=${body}`;
    window.open(url);
  }

  getPeriodString(scheduleData: ScheduleInvestorResponse){
    if(scheduleData){
      const tz = scheduleData.eventType == 'THAILAND_FOCUS' ? 'UTC +7' : null;
      const startMonth =  this.datePipe.transform(scheduleData.startDate, 'MMMM', tz)
      const endMonth = this.datePipe.transform(scheduleData.endDate, 'MMMM', tz)
      const year = this.datePipe.transform(scheduleData.endDate,'yyyy',tz);
      const startDay = this.datePipe.transform(scheduleData.startDate, 'dd', tz)
      const endDay = this.datePipe.transform(scheduleData.endDate, 'dd', tz)
      const location = scheduleData.location ? ", " + scheduleData.location : "";
      if(startMonth==endMonth){
        return `${startMonth} ${startDay}-${endDay}, ${year}${location}`;
      }else{
        return `${startMonth} ${startDay}-${endMonth} ${endDay}, ${year}${location}`;
      }
    }
    
  }

  print(): void {
    window.print();
  }

  public convertToPDF() {
    const offset = (new Date().getTimezoneOffset() / -60);
    this.scheduleService.generatePDFScheduleInvestorUsingGET(offset, this.token).subscribe((result) => {
      const blob = b64toBlob(result.data.data, result.data.fileType);
      saveFileBlob(blob, result.data.fileName);
    });
  }
}
