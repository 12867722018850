import {
  ParticipantResponse, DietaryResponse, DietaryPublicControllerService, ParticipantControllerService,
  ParticipantUpdateRequest, ParticipantRequest, RegisterCompanyControllerService, CheckEmailDuplicateByIdRequest
} from '@ent-regis/entregis-ts-angular';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EventService } from 'src/app/shared/services/event.service';
import { appConst } from 'src/app/constants/app-const';
import { Subscription } from 'rxjs';
import { DietaryService } from 'src/app/shared/services/dietary.service';
import { keyPressCharacters, checkAndRemoveTitleWording } from 'src/app/functions/string-formatter.function';
import { AlertService } from 'src/app/shared/services/alert.service';
@Component({
  selector: 'app-register-company-add-edit-participant-modal',
  templateUrl: './register-company-add-edit-participant-modal.component.html',
  styleUrls: ['./register-company-add-edit-participant-modal.component.scss']
})
export class RegisterCompanyAddEditParticipantModalComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();

  @Output() updateCompleted = new EventEmitter<null>();
  searchCountryField = [SearchCountryField.Iso2, SearchCountryField.Name];
  tooltipLabel = TooltipLabel.Name;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.Thailand];
  form: FormGroup;
  registerCompanyId: number;
  eventId: number;

  participant: ParticipantResponse;
  isDisableContactPerson: boolean;
  dietaries: DietaryResponse[] = [];
  noneDietaryIndex: number;
  maxParticipant: number;
  participantData: ParticipantResponse[] = [];

  get mobileNumber() { return this.form.get('mobileNumber'); }
  get emailF() { return this.form.get('email'); }

  constructor(
    public bsModalRef: BsModalRef,
    private dietaryService: DietaryService,
    private participantControllerService: ParticipantControllerService,
    private registerCompanyControllerService: RegisterCompanyControllerService,
    private eventService: EventService,
    private alert: AlertService
  ) {
  }

  ngOnInit() {
    this.getEvent();
    this.subscription.add(this.dietaryService.dietaries.subscribe(x => {
      this.dietaries = x;
    }));
    this.subscription.add(this.dietaryService.noneDietaryId.subscribe(x => {
      this.noneDietaryIndex = x;
      if (this.form && this.form.controls.dietaryRequirementId.value == null) {
        this.form.controls.dietaryRequirementId.setValue(x);
      }
    }));
    if (this.participant) {
      this.form = new FormGroup({
        participantId: new FormControl(this.participant.participantId),
        dietaryRequirementId: new FormControl(this.participant.dietary ? this.participant.dietary.dietaryId : ''),
        otherDietary: new FormControl(this.participant.otherDietary),
        email: new FormControl(this.participant.email, Validators.required),
        willReceiveEmail: new FormControl(this.participant.willReceiveEmail, Validators.required),
        firstName: new FormControl(this.participant.firstName, Validators.required),
        jobTitle: new FormControl(this.participant.jobTitle, Validators.required),
        lastName: new FormControl(this.participant.lastName, Validators.required),
        mobileNumber: new FormControl({ number: this.participant.mobileNumber, countryCode: this.participant.countryCode }, Validators.required),
        extNumber: new FormControl(this.participant.extNumber),
        isContact: new FormControl({ value:this.participant.isContact, disabled: this.isDisableContactPerson}),
        isParticipant: new FormControl(this.participant.isParticipant)
      });
    } else {
      this.form = new FormGroup({
        participantId: new FormControl(),
        dietaryRequirementId: new FormControl(this.noneDietaryIndex),
        otherDietary: new FormControl(),
        email: new FormControl(null, [Validators.required, Validators.pattern(appConst.emailPattern)]),
        willReceiveEmail: new FormControl(true, Validators.required),
        firstName: new FormControl(null, Validators.required),
        jobTitle: new FormControl(null, Validators.required),
        lastName: new FormControl(null, Validators.required),
        mobileNumber: new FormControl(null, Validators.required),
        extNumber: new FormControl(),
        isContact: new FormControl({ value: false, disabled: this.isDisableContactPerson}),
        isParticipant: new FormControl(true)
      });
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getEvent() {
    this.subscription.add(this.eventService.eventSubject.subscribe(event => this.eventId = event.eventId));
  }

  onSubmit() {
    if (this.form.valid) {      
      if (this.participant) {
        this.update();
      } else {
        this.checkEmailDuplicated(() =>this.create());
      }
    } else {
      this.form.markAllAsTouched();
    }
  }
  public checkCanAddParticipant(participantData:ParticipantResponse[]): boolean {
    return participantData.filter(f => f.isParticipant || (!f.isContact && !f.isParticipant)).length < this.maxParticipant
  }
  public checkNotHasContactPerson(participantData:ParticipantResponse[]): boolean {
    return !(participantData.filter(f => f.isContact).length > 0);
  }

  public checkIsParticipant(value:ParticipantRequest): boolean {
    if(value.isParticipant){
      return true;
    }else if(!value.isParticipant && !value.isContact){
      return true;
    }else{
      return false;
    }
  }

  public checkIsContact(value:ParticipantRequest): boolean {
    if(value.isContact){
      return true;
    }else return false;
  }

  create() {
    var value = this.form.getRawValue();
    const participantRequest: ParticipantRequest = {
      countryCode: value.mobileNumber.countryCode,
      dietaryRequirementId: value.dietaryRequirementId,
      email: value.email,
      willReceiveEmail: value.willReceiveEmail,
      firstName: value.firstName,
      isContact: value.isContact,
      isParticipant: value.isParticipant,
      jobTitle: value.jobTitle,
      lastName: value.lastName,
      mobileNumber: value.mobileNumber.e164Number,
      extNumber: value.extNumber,
      otherDietary: value.otherDietary,
      registerCompanyId: this.registerCompanyId
    };
    if(!this.checkCanAddParticipant(this.participantData) && this.checkIsParticipant(participantRequest)){
      this.alert.showError('Cannot add more participant. Maximum participant is '+this.maxParticipant+'.');
      return;
    }
    if(!this.checkNotHasContactPerson(this.participantData) && this.checkIsContact(participantRequest)){
      this.alert.showError('Cannot add more contact person');
      return;
    }

    this.participantControllerService
      .createParticipantUsingPOST1(participantRequest).subscribe((x) => {
        this.bsModalRef.hide();
        this.updateCompleted.emit();
      });
  }

  update() {
    var value = this.form.getRawValue();
    const participantUpdateRequest: ParticipantUpdateRequest = {
      countryCode: value.mobileNumber.countryCode,
      dietaryRequirementId: value.dietaryRequirementId,
      firstName: value.firstName,
      jobTitle: value.jobTitle,
      willReceiveEmail: value.willReceiveEmail,
      lastName: value.lastName,
      mobileNumber: value.mobileNumber.e164Number,
      extNumber: value.extNumber,
      otherDietary: value.otherDietary,
      isParticipant: value.isParticipant,
      isContact: value.isContact,
    };

    let partiOther = this.participantData.filter(f => f.participantId != this.participant.participantId);
    if(!this.checkCanAddParticipant(partiOther) && this.checkIsParticipant(participantUpdateRequest)){
      this.alert.showError('Cannot add more participant. Maximum participant is '+this.maxParticipant+'.');
      return;
    }
    if(!this.checkNotHasContactPerson(partiOther) && this.checkIsContact(participantUpdateRequest)){
      this.alert.showError('Cannot add more contact person.');
      return;
    }

    this.participantControllerService
      .updateParticipantByIdUsingPOST1(this.participant.participantId, participantUpdateRequest)
      .subscribe(() => {
        this.bsModalRef.hide();
        this.updateCompleted.emit();
      });
  }

  checkEmailDuplicated(complete: () => void) {
    if (this.emailF.value) {
      const request: CheckEmailDuplicateByIdRequest = {
        email: this.emailF.value,
        eventId: this.eventId,
        id: this.registerCompanyId
      };
      this.registerCompanyControllerService.checkEmailDuplicateInEventUsingPOST1(request)
        .subscribe((isDuplicated) => {
          if (isDuplicated.data) {
            this.emailF.setErrors({ duplicated: isDuplicated.data });
          } else {
            this.emailF.setErrors(null);
            if (complete) { complete(); }
          }
        });
    }
  }

  onKeyPressCharacters(event: any): boolean {
    return keyPressCharacters(event);
   }
 
   onCheckAndRemoveWording(name: string): void {
     const value = this.form.get(`${name}`).value;
     this.form.get(`${name}`).setValue(checkAndRemoveTitleWording(value));
   }

}
