<!-- <div style="background-color: #eeeeee;">
    <div class="container bg-white">
        <app-header></app-header>
        <div class="content">
            <app-body></app-body>
        </div>
        <app-footer></app-footer>    
    </div>
</div> -->

<div *ngIf="eventInfo" class="common-img default-img" style="min-height: 100%;"  [style.background-image]="eventInfo.bgUrl ? 
'url(' + eventInfo.bgUrl + ')': 
'url(../../assets/images/defaultBg1.png)'">
    <div>
        <app-header></app-header>
        <div class="py-3 container">
            <app-body></app-body>
        </div>
        <app-footer></app-footer>
    </div>

</div>