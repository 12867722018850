/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./available-time.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./available-time.component";
import * as i4 from "@ent-regis/entregis-ts-angular/api/investorSlotController.service";
import * as i5 from "../../core/service/register-investor.service";
import * as i6 from "@angular/router";
import * as i7 from "../../shared/services/alert.service";
var styles_AvailableTimeComponent = [i0.styles];
var RenderType_AvailableTimeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AvailableTimeComponent, data: {} });
export { RenderType_AvailableTimeComponent as RenderType_AvailableTimeComponent };
function View_AvailableTimeComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeIsAvalable(_v.parent.parent.context.index, _v.parent.context.$implicit.slotPeriodId, _v.parent.context.$implicit.available) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 0, "input", [["type", "checkbox"]], [[8, "checked", 0], [8, "id", 0], [8, "name", 0]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "span", [["class", "description"]], [[8, "id", 0]], null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", " - ", ""])), i1.ɵppd(6, 3), i1.ɵppd(7, 3)], function (_ck, _v) { var currVal_0 = ((_v.parent.context.$implicit.available == "Y") ? "time-box-item is-check" : "time-box-item"); _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = (_v.parent.context.$implicit.available === "Y"); var currVal_2 = i1.ɵinlineInterpolate(2, "investor-time-field-slot-period-input-", _v.parent.parent.context.index, "-", _v.parent.context.index, ""); var currVal_3 = i1.ɵinlineInterpolate(2, "available_", _v.parent.parent.context.index, "_", _v.parent.context.$implicit.slotPeriodId, ""); _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3); var currVal_4 = i1.ɵinlineInterpolate(2, "investor-time-field-slot-period-text-", _v.parent.parent.context.index, "-", _v.parent.context.index, ""); _ck(_v, 4, 0, currVal_4); var currVal_5 = i1.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i1.ɵnov(_v.parent.parent.parent, 0), _v.parent.context.$implicit.startTime, "H:mm", (_co.isEventTypeThailandFocus ? "UTC +7" : null))); var currVal_6 = i1.ɵunv(_v, 5, 1, _ck(_v, 7, 0, i1.ɵnov(_v.parent.parent.parent, 0), _v.parent.context.$implicit.endTime, "H:mm", (_co.isEventTypeThailandFocus ? "UTC +7" : null))); _ck(_v, 5, 0, currVal_5, currVal_6); }); }
function View_AvailableTimeComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "time-box-item-disable"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "input", [["disabled", ""], ["type", "checkbox"]], [[8, "checked", 0], [8, "id", 0], [8, "name", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "span", [["class", "description"]], [[8, "id", 0]], null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", " - ", ""])), i1.ɵppd(4, 3), i1.ɵppd(5, 3)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.parent.context.$implicit.available === "Y"); var currVal_1 = i1.ɵinlineInterpolate(2, "investor-time-field-slot-period-input-", _v.parent.parent.context.index, "-", _v.parent.context.index, ""); var currVal_2 = i1.ɵinlineInterpolate(2, "available_", _v.parent.parent.context.index, "_", _v.parent.context.$implicit.slotPeriodId, ""); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = i1.ɵinlineInterpolate(2, "investor-time-field-slot-period-text-", _v.parent.parent.context.index, "-", _v.parent.context.index, ""); _ck(_v, 2, 0, currVal_3); var currVal_4 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent.parent.parent, 0), _v.parent.context.$implicit.startTime, "H:mm", (_co.isEventTypeThailandFocus ? "UTC +7" : null))); var currVal_5 = i1.ɵunv(_v, 3, 1, _ck(_v, 5, 0, i1.ɵnov(_v.parent.parent.parent, 0), _v.parent.context.$implicit.endTime, "H:mm", (_co.isEventTypeThailandFocus ? "UTC +7" : null))); _ck(_v, 3, 0, currVal_4, currVal_5); }); }
function View_AvailableTimeComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AvailableTimeComponent_3)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AvailableTimeComponent_4)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isCloseRegister; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.isCloseRegister; _ck(_v, 4, 0, currVal_1); }, null); }
function View_AvailableTimeComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "row mb-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "strong", [], [[8, "id", 0]], null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵppd(4, 3), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "row mb-3"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AvailableTimeComponent_2)), i1.ɵdid(7, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_2 = _v.context.$implicit.slotPeriods; _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "investor-time-field-slot-date-text-", _v.context.index, ""); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent, 0), _v.context.$implicit.slotDate, "MMM dd, yyyy", (_co.isEventTypeThailandFocus ? "UTC +7" : null))); _ck(_v, 3, 0, currVal_1); }); }
function View_AvailableTimeComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-primary"], ["id", "investor-time-next-button"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.save() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["SAVE"]))], null, null); }
export function View_AvailableTimeComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 11, "div", [["class", "content"], ["style", "border-top: 2px solid #0064c5;margin-top: 1px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 10, "div", [["class", "content-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 9, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 5, "div", [["class", "card-form"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "m-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "card-xl-title row mb-3"], ["id", "investor-time-warning-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Please select your available times"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AvailableTimeComponent_1)), i1.ɵdid(9, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "d-flex justify-content-center mt-2"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AvailableTimeComponent_5)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.slots; _ck(_v, 9, 0, currVal_0); var currVal_1 = !_co.isCloseRegister; _ck(_v, 12, 0, currVal_1); }, null); }
export function View_AvailableTimeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-available-time", [], null, null, null, View_AvailableTimeComponent_0, RenderType_AvailableTimeComponent)), i1.ɵdid(1, 245760, null, 0, i3.AvailableTimeComponent, [i4.InvestorSlotControllerService, i5.RegisterInvestorService, i6.ActivatedRoute, i6.Router, i7.AlertService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AvailableTimeComponentNgFactory = i1.ɵccf("app-available-time", i3.AvailableTimeComponent, View_AvailableTimeComponent_Host_0, {}, {}, []);
export { AvailableTimeComponentNgFactory as AvailableTimeComponentNgFactory };
