export const appConst = {
  // auth: {
  //   storageKey: 'auth',
  //   apiAuthorization: 'apiAuthorization'
  // },
  emailPattern: '^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$',
  telPattern: '^(0|[1-9][0-9]*)$',
  accessTokenKey: 'ent-regis-external-access-token',
  refreshTokenKey: 'ent-regis-external-refresh-token',
  eventAliasKey: 'ent-regis-login-event-alias',
  loginTypeKey: 'ent-regis-login-type',
  companyTail: 'PUBLIC COMPANY LIMITED'
};
