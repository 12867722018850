import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { msg } from 'src/app/constants/msg';
import { CompanySlotControllerService } from '@ent-regis/entregis-ts-angular/api/companySlotController.service';
import { APIResultListManageCompanySlotResponse } from '@ent-regis/entregis-ts-angular/model/aPIResultListManageCompanySlotResponse';
import { ManageCompanySlotResponse } from '@ent-regis/entregis-ts-angular/model/manageCompanySlotResponse';
import { CompanySlotUpdateRequest } from '@ent-regis/entregis-ts-angular/model/companySlotUpdateRequest';
import { CompanySlotPeriodResponse } from '@ent-regis/entregis-ts-angular/model/companySlotPeriodResponse';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ParticipantControllerService } from '@ent-regis/entregis-ts-angular';

@Component({
  selector: 'app-timetable',
  templateUrl: './timetable.component.html',
  styleUrls: ['./timetable.component.scss']
})
export class TimetableComponent implements OnInit {
  @Input() isEventTypeThailandFocus: boolean;
  submited = false;
  slots: ManageCompanySlotResponse[];

  @Input()
  registerCompanyId: number;

  @Input()
  isCloseRegister: boolean;

  @Output() setCanLeave = new EventEmitter<boolean>();

  constructor(private companySlotControllerService: CompanySlotControllerService,
    private participantControllerService: ParticipantControllerService,
    private alert: AlertService) { }

  ngOnInit() {
    this.getCompanySlot(this.registerCompanyId);
  }

  onSelectionChange(index: number, slotPeriodId: number, avalible: string, period: any) {
    this.setCanLeave.emit(false);
    for (const slotPeriod of this.slots[index].slotPeriods) {
      if (slotPeriod.slotPeriodId === slotPeriodId) {
        slotPeriod.available = CompanySlotPeriodResponse.AvailableEnum[avalible];
      }
    }

  }

  getCompanySlot(registerCompanyId: number) {
    return this.companySlotControllerService.getCompanySlotUsingGET1(registerCompanyId)
      .subscribe((reponse: APIResultListManageCompanySlotResponse) => {
        this.slots = reponse.data;
      });
  }

  save() {
    this.submited = true;
    if (!this.validate()) {
      this.alert.showError(msg.error.selectAllItem);
    } else if (!this.validateAvailableTime()) {
      this.alert.showError(msg.error.selectAtLeastOneAvailableTime);
    } else {
      this.setCanLeave.emit(true);
      const request: CompanySlotUpdateRequest = {};
      request.companySlots = [];

      for (const slot of this.slots) {
        for (const slotPeriod of slot.slotPeriods) {
          request.companySlots.push({
            companySlotId: slotPeriod.companySlotId,
            slotPeriodId: slotPeriod.slotPeriodId,
            available: slotPeriod.available
          });
        }
      }

      this.companySlotControllerService.createAndUpdateCompanySlotUsingPOST1(request, this.registerCompanyId).subscribe(() => {
        this.alert.showSuccess(msg.success.dataSaved).then(() => {
          this.getCompanySlot(this.registerCompanyId);
        });
      });

    }
  }

  validateAvailableTime(): boolean {
    return this.slots.filter(f => f.slotPeriods.filter(sp => sp.available === 'Y').length > 0).length > 0;
  }

  validate(): boolean {
    for (const slot of this.slots) {
      for (const slotPeriod of slot.slotPeriods) {
        if (!(slotPeriod.available === 'Y' || slotPeriod.available === 'N')) {
          return false;
        }
      }
    }

    return true;
  }

}
