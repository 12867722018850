import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  CompanyWithSlotPeriodIdsResponse,
  EventInfoWithConsentResponse,
  InvestorMeetingCreateRequest,
  InvestorMeetingRequestResponse,
  InvestorMeetingUpdate,
  InvestorMeetingUpdateRequest,
  MatchingMeetingTypeRequest,
  PublicSlotControllerService,
  RegisterInvestorResponse,
  SlotPeriod,
  SlotPeriodResponse,
  SlotResponse,
} from '@ent-regis/entregis-ts-angular';
import { RegisterInvestorService } from 'src/app/core/service/register-investor.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { filter, first } from 'rxjs/operators';
import { MeetingType } from 'src/app/constants/meeting-type';
import { ActivatedRoute, Router } from '@angular/router';
import { EventService } from 'src/app/shared/services/event.service';
import { Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-meeting-request',
  templateUrl: './meeting-request.component.html',
  styleUrls: ['./meeting-request.component.scss'],
})
export class MeetingRequestComponent implements OnInit, OnDestroy {
  @Output() willSave = new EventEmitter<InvestorMeetingUpdateRequest>();
  @Output() willCancel = new EventEmitter<number>();

  investorProfile: RegisterInvestorResponse;
  investorMeetingReqList: InvestorMeetingRequestResponse[];
  isCloseRegisterInvestor: boolean;
  isEventTypeDigitalRoadShow = false;
  isEventTypeThailandFocus = false;

  public MeetingTypeEnum = InvestorMeetingCreateRequest.MeetingTypeEnum;
  public MeetingPriorityEnum = InvestorMeetingCreateRequest.MeetingPriorityEnum;
  public EventTypeEnum = EventInfoWithConsentResponse.EventTypeEnum;

  // ----------------- emergency change
  approvedCompanies: { [key: number]: CompanyWithSlotPeriodIdsResponse };
  slots: SlotResponse[] = [];
  // ----------------- emergency change

  priorities = [
    { level: 'HIGH' },
    { level: 'MEDIUM' },
    { level: 'LOW' },
  ];
  meetingTypes = [];

  form: FormGroup;
  get formArray() { return this.form.controls.meetings as FormArray; }

  disable = false;
  isInteresting = true;

  private subscription = new Subscription();

  constructor(
    private registerInvestorService: RegisterInvestorService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private publicSlotService: PublicSlotControllerService,
    private eventService: EventService,
    private datePipe: DatePipe,
    private alert: AlertService
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      meetings: this.fb.array([])
    });
    this.getMeetingRequest();
    const type = [MatchingMeetingTypeRequest.MatchingMeetingTypeEnum.ONEONONE, MatchingMeetingTypeRequest.MatchingMeetingTypeEnum.GROUP];

    this.subscription.add(this.registerInvestorService.investorProfile.pipe(filter(profile => profile != null)).subscribe(data => {
      this.investorProfile = data;
    }));
    this.subscription.add(this.registerInvestorService.approvedCompany.pipe(filter(companies => companies != null)).subscribe(companies => {
      this.approvedCompanies = {};
      companies.forEach(company => {
        this.approvedCompanies[company.registerCompanyId] = company;
      })
    }));
    this.subscription.add(this.eventService.eventSubject.pipe(filter(event => event != null)).subscribe(event => {
      this.getSlot(event.eventId);
      this.isEventTypeDigitalRoadShow = event.eventType == EventInfoWithConsentResponse.EventTypeEnum.DIGITALROADSHOW;
      this.isEventTypeThailandFocus = event.eventType == EventInfoWithConsentResponse.EventTypeEnum.THAILANDFOCUS;
      if (this.isEventTypeThailandFocus) {
        type.push(MatchingMeetingTypeRequest.MatchingMeetingTypeEnum.VIRTUALGROUP);
      }
      this.meetingTypes = MeetingType.filter(item => type.includes(item.type));
    }))
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  // ----------------- emergency change

  private getSlot(eventId: number): void {
    this.publicSlotService.getPublicSlotByEventUsingGET(eventId).subscribe(res => {
      this.slots = res.data;
    })
  }

  generateArrayOfSlotStringInSlotPeriodIds(spIds: SlotPeriod[]) {
    const result: SlotString[] = [];
    const slotPeriodIds = spIds.map(m => m.slotPeriodId);
    const virtualSlotPeriodIds = spIds.filter(f => f.slotType == SlotPeriod.SlotTypeEnum.VIRTUAL).map(m => m.slotPeriodId)
    this.slots.forEach(slot => {
      const slotString = this.convertSlotToStringFilterBySlotPeriodIds(slot, slotPeriodIds, virtualSlotPeriodIds);
      result.push(slotString);

    });

    return result;
  }

  convertSlotToStringFilterBySlotPeriodIds(slot: SlotResponse, spIds: number[], virtualSpIds: number[]) {
    const slotMorningPeriods = slot.slotPeriodMorning.filter(sp => spIds.includes(sp.slotPeriodId) && !virtualSpIds.includes(sp.slotPeriodId) );
    const slotAfternoonPeriods = slot.slotPeriodAfternoon.filter(sp => spIds.includes(sp.slotPeriodId) && !virtualSpIds.includes(sp.slotPeriodId));
    const slotMorningPeriodsVirtual = slot.slotPeriodMorning.filter(sp => spIds.includes(sp.slotPeriodId) && virtualSpIds.includes(sp.slotPeriodId) );
    const slotAfternoonPeriodsVirtual = slot.slotPeriodAfternoon.filter(sp => spIds.includes(sp.slotPeriodId) && virtualSpIds.includes(sp.slotPeriodId));
    
    const slotPeriodMorningString = this.convertSlotPeriodsToString(slotMorningPeriods);
    const slotAfternoonPeriodsString = this.convertSlotPeriodsToString(slotAfternoonPeriods);
    const slotPeriodMorningStringVirtual = this.convertSlotPeriodsToString(slotMorningPeriodsVirtual);
    const slotAfternoonPeriodsStringVirtual = this.convertSlotPeriodsToString(slotAfternoonPeriodsVirtual);
    return {
      date: slot.slotDate,
      slotPeriodMorningString: slotPeriodMorningString,
      slotAfternoonPeriodsString:slotAfternoonPeriodsString,
      slotPeriodMorningStringVirtual: slotPeriodMorningStringVirtual,
      slotAfternoonPeriodsStringVirtual: slotAfternoonPeriodsStringVirtual, 
    };
  }

  convertSlotPeriodsToString(sps: SlotPeriodResponse[]) {
    const tz = this.isEventTypeThailandFocus ? 'UTC +7' : null;
    return sps
      .map(sp => {
        return `${this.datePipe.transform(sp.startTime, 'HH:mm', tz)} - ${this.datePipe.transform(sp.endTime, 'HH:mm', tz)}`;
      });
  }
  // ----------------- emergency change

  getMeetingRequest() {
    this.registerInvestorService.investorMeetingRequest.subscribe(data => {
      this.investorMeetingReqList = data;
      //this.investorMeetingReqList.sort((a, b) => a.registerCompanySymbol.localeCompare(b.registerCompanySymbol));
      this.setDataIntoForm(this.investorMeetingReqList);
      this.checkIsCloseRegister();
    });
  }

  checkIsCloseRegister() {
    this.registerInvestorService.isCloseRegister.pipe(first(x => x !== null))
      .subscribe(isClose => {
        this.isCloseRegisterInvestor = isClose;

        if (isClose) {
          this.formArray.controls.forEach((item: FormGroup) => {
            item.controls.interest.disable();
            item.controls.meetingType.disable();
            item.controls.priority.disable();
          });
        }
      });
  }

  setDataIntoForm(list: InvestorMeetingRequestResponse[]) {
    while (this.formArray.length) {
      this.formArray.removeAt(0);
    }

    list.forEach(item => {
      const data = {
        registerCompanyId: item.registerCompanyId,
        investorMeetingRequestId: item.investorMeetingRequestId,
        registerCompanyName: item.registerCompanyName,
        interest: item.investorMeetingRequestId === null ? false : true,
        meetingType: item.meetingType,
        priority: item.priority,
        registerCompanySector: item.registerCompanySector,
        symbol: item.registerCompanySymbol,
        businessType: item.registerCompanyBusinessType
      };
      this.formArray.push(this.fb.group(data));
    });

    this.formArray.controls.forEach((item: FormGroup) => {
      if (!item.controls.investorMeetingRequestId.value) {
        item.controls.meetingType.disable();
        item.controls.priority.disable();
      }
    });
  }

  onCheckboxChange(index: number, event?: any) {
    if (!this.isCloseRegisterInvestor) {
      if (event) {
        event.stopPropagation();
      }
      this.registerInvestorService.canLeavePage$.next(false);
      const fg = this.formArray.at(index);
      const interestFc = fg.get('interest');
      interestFc.setValue(!interestFc.value);
      if (!interestFc.value) {
        fg.get('meetingType').setValue(null);
        fg.get('priority').setValue(null);
        fg.get('meetingType').disable();
        fg.get('priority').disable();
      } else {
        fg.get('meetingType').enable();
        fg.get('priority').enable();
      }
    } else {
      if (event) {
        event.stopPropagation();
      }
    }
  }

  onCheckboxChange2(item: FormGroup) {

    if (!item.value.interest) {
      item.get('meetingType').setValue(null);
      item.get('priority').setValue(null);
      item.get('meetingType').disable();
      item.get('priority').disable();
    } else {
      item.get('meetingType').enable();
      item.get('priority').enable();
    }
  }

  setValidation(formControlsNames: string[]) {
    formControlsNames.forEach(name => {
      this.formArray.controls.forEach((fa: FormGroup) => {
        fa.controls[name].markAsTouched();
        fa.controls[name].setValidators(Validators.required);
        fa.controls[name].updateValueAndValidity();
      });
    });
  }

  onSubmit() {
    this.setValidation(['meetingType', 'priority']);
    this.registerInvestorService.canLeavePage$.next(true);
    if (this.form.invalid) {
      return;
    }

    const cancelIds = [];
    const datas = [];
    const formDatas = this.form.get('meetings').value;

    formDatas.forEach(item => {
      if (item.investorMeetingRequestId && !item.meetingtype && !item.priority) {
        cancelIds.push(item);
      } else if (item.meetingType && item.priority) {
        datas.push(item);
      }
    });

    if (datas.length !== 0) {
      this.updateMeetingRequest(datas);
    }

    if (cancelIds.length !== 0) {
      this.cancelMeetingRequest(cancelIds);
    }
  }

  updateMeetingRequest(datas: any[]) {
    const request: InvestorMeetingUpdateRequest = {};
    request.investorMeetingRequests = [];

    datas.forEach(item => {
      request.investorMeetingRequests.push({
        investorMeetingRequestId: item.investorMeetingRequestId,
        meetingType: InvestorMeetingUpdate.MeetingTypeEnum[item.meetingType],
        priority: InvestorMeetingUpdate.PriorityEnum[item.priority],
        registerCompanyId: item.registerCompanyId,
        registerInvestorId: this.investorProfile.registerInvestorId
      });
    });
    if (this.isEventTypeThailandFocus) {
      this.router.navigate(['..', 'available-time'], { relativeTo: this.route });
    } else {
      this.router.navigate(['..', 'investor-info'], { relativeTo: this.route });
    }
    this.willSave.emit(request);
  }

  cancelMeetingRequest(cancelIds: any[]) {
    cancelIds.forEach(item => {
      this.willCancel.emit(item.investorMeetingRequestId);
    });
  }

  openCompanyDescription(company) {
    const name = company.registerCompanyName;
    const symbol = company.symbol;
    const description = company.businessType;
    if (symbol) {
      this.alert.showInfo(`(${symbol}) ${name}`, description)
    } else {
      this.alert.showInfo(`${name}`, description)
    }
    return false;
  }

  setCanLeave(bool: boolean) {
    this.registerInvestorService.canLeavePage$.next(bool)
  }

}

interface SlotString {
  date: Date,
  slotPeriodMorningString: string[];
  slotAfternoonPeriodsString: string[];
  slotPeriodMorningStringVirtual: string[];
  slotAfternoonPeriodsStringVirtual: string[];
}