import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@ent-regis/entregis-ts-angular/api/eventPublicController.service";
import * as i2 from "./auth.service";
export class EventService {
    constructor(eventPublicService, authService) {
        this.eventPublicService = eventPublicService;
        this.authService = authService;
        this.eventSubject = new BehaviorSubject(null);
        this.authService.eventAliasSubject.subscribe(x => {
            if (x) {
                this.eventPublicService.getInfoWithConsentUsingGET(x)
                    .subscribe((response) => {
                    this.eventSubject.next(response.data);
                });
            }
            else {
                this.eventSubject.next(null);
            }
        });
    }
}
EventService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EventService_Factory() { return new EventService(i0.ɵɵinject(i1.EventPublicControllerService), i0.ɵɵinject(i2.AuthService)); }, token: EventService, providedIn: "root" });
