import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginType } from 'src/app/constants/login-type.enum';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const isAuthenticated = this.authService.isAuthenticated;    
      if (typeof isAuthenticated === 'boolean') {
        if (isAuthenticated) {
          return true;
        } else {
          const eventAlias = route.paramMap.get('eventAlias');
          if (state.url.startsWith(`/regis/${eventAlias}/company`)) {
            this.router.navigate(['login', eventAlias, LoginType.company]);
          } else if (state.url.startsWith(`/regis/${eventAlias}/investor`)) {
            this.router.navigate(['login', eventAlias, LoginType.investor]);
          }
          
          return false;
        }
      } else {
        return isAuthenticated;
      }
  }
  
}
