import { LoginComponent } from './login/login.component';
import { Routes } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { CompanyComponent } from './company/company.component';
import { InvestorComponent } from './investor/investor.component';
import { MeetingRequestComponent } from './investor/meeting-request/meeting-request.component';
import { AvailableTimeComponent } from './investor/available-time/available-time.component';
import { InvestorInfoComponent } from './investor/investor-info/investor-info.component';
import { HotelComponent } from './investor/hotel/hotel.component';
import { ScheduleCompanyComponent } from './schedule-company/schedule-company.component';
import { ScheduleInvestorComponent } from './schedule-investor/schedule-investor.component';
import { AuthGuardGuard } from './shared/services/auth-guard.guard';
import { LoginType } from './constants/login-type.enum';
import { LoginAdminComponent } from './login-admin/login-admin.component';
import { UnauthorizeComponent } from './unauthorize/unauthorize.component';
const ɵ0 = () => import("./register-company/register-company.module.ngfactory").then(m => m.RegisterCompanyModuleNgFactory), ɵ1 = () => import("./register-investor/register-investor.module.ngfactory").then(m => m.RegisterInvestorModuleNgFactory), ɵ2 = () => import("./register-seminar-investor/register-seminar-investor.module.ngfactory").then(m => m.RegisterSeminarInvestorModuleNgFactory), ɵ3 = { loginType: LoginType.company }, ɵ4 = { loginType: LoginType.investor }, ɵ5 = { loginType: LoginType.admin }, ɵ6 = () => import("./check-in/check-in.module.ngfactory").then(m => m.CheckInModuleNgFactory);
const routes = [
    { path: 'register-company/:eventAlias', loadChildren: ɵ0 },
    { path: 'register-investor/:eventAlias', loadChildren: ɵ1 },
    { path: 'register-seminar-investor/:eventAlias', loadChildren: ɵ2 },
    { path: 'external/company/:token', component: ScheduleCompanyComponent },
    { path: 'external/investor/:token', component: ScheduleInvestorComponent },
    { path: 'login/:eventAlias/company', component: LoginComponent, data: ɵ3 },
    { path: 'login/:eventAlias/investor', component: LoginComponent, data: ɵ4 },
    { path: 'login/admin', component: LoginAdminComponent, data: ɵ5 },
    {
        path: 'regis/:eventAlias', component: LayoutComponent, canActivate: [AuthGuardGuard],
        children: [
            { path: 'company/:registerCompanyId', component: CompanyComponent },
            {
                path: 'investor/:registerInvestorId', component: InvestorComponent,
                children: [
                    { path: '', redirectTo: 'meeting-request', pathMatch: 'full' },
                    { path: 'meeting-request', component: MeetingRequestComponent },
                    { path: 'available-time', component: AvailableTimeComponent },
                    { path: 'investor-info', component: InvestorInfoComponent },
                    { path: 'hotel', component: HotelComponent },
                ]
            },
        ]
    },
    { path: 'checkin/:eventAlias', loadChildren: ɵ6 },
    { path: 'session-timeout', component: UnauthorizeComponent }
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6 };
