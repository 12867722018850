import { Injectable } from '@angular/core';
import { ReplaySubject, BehaviorSubject } from 'rxjs';
import {
  RegisterInvestorResponse,
  RegisterInvestorControllerService,
  RegisterInvestorUpdateRequest,
  APIResult,
  InvestorMeetingRequestControllerService,
  InvestorMeetingRequestResponse,
  InvestorMeetingUpdateRequest,
  RegisterCompanyPublicControllerService,
  CompanyWithSlotPeriodIdsResponse,
  InvestorSlotControllerService,
  APIResultListInvestorSlotResponse,
  InvestorSlotResponse
} from '@ent-regis/entregis-ts-angular';
import { first } from 'rxjs/operators';
import { AlertService } from 'src/app/shared/services/alert.service';
import { EventService } from 'src/app/shared/services/event.service';
import { checkEqualOtherName } from 'src/app/functions/form.function';


@Injectable({
  providedIn: 'root'
})
export class RegisterInvestorService {

  registerInvestorId = new ReplaySubject<number>(1);
  investorId: number;
  investorProfile = new ReplaySubject<RegisterInvestorResponse>(1);
  investorMeetingRequest = new ReplaySubject<InvestorMeetingRequestResponse[]>(1);
  approvedCompany = new ReplaySubject<CompanyWithSlotPeriodIdsResponse[]>(1);
  investorSlots = new ReplaySubject<InvestorSlotResponse[]>(1);
  isCloseRegister = new ReplaySubject<boolean>(1);
  public canLeavePage$ = new BehaviorSubject<boolean>(true);
  constructor(
    private registerCompanyPublicService: RegisterCompanyPublicControllerService,
    private registerInvestorControllerService: RegisterInvestorControllerService,
    private investorMeetingRequestControllerService: InvestorMeetingRequestControllerService,
    private alert: AlertService,
    private eventService: EventService,
    private investorSlotService: InvestorSlotControllerService,
  ) {
    this.fetchAllData();
    this.registerInvestorId.subscribe((id: number) => {
      this.investorId = id;
    });
  }

  fetchAllData() {
    this.registerInvestorId.subscribe((id: number) => {
      this.fetchRegisterInvestorProfileById();
      this.fetchInvestorMeetingRequest();
      this.fetchApprovedCompany();
      this.fetchInvestorSlot();
    });
  }

  fetchRegisterInvestorProfileById() {
    this.registerInvestorId.pipe(first()).subscribe(x => {
      this.getRegisterInvestorProfileById(x, (registerInvestor) => {
        this.investorProfile.next(registerInvestor);
      });
    });
  }

  fetchInvestorMeetingRequest() {
    this.registerInvestorId.pipe(first()).subscribe(x => {
      this.getMeetingRequest(x, (investorMeetingRequest) => {
        this.investorMeetingRequest.next(investorMeetingRequest);
      });
    });
  }

  fetchApprovedCompany(): void {
    this.eventService.eventSubject.pipe(first(event => event != null)).subscribe(event => {
      this.registerCompanyPublicService.getApprovedRegisterCompanyUsingGET(event.eventAlias).subscribe(res => {
        this.approvedCompany.next(res.data);
      })
    })
  }

  fetchInvestorSlot() {
    return this.investorSlotService.getInvestorSlotUsingGET1()
      .subscribe((reponse: APIResultListInvestorSlotResponse) => {
        this.investorSlots.next(reponse.data);
      });
  }

  saveInvestorMeetingRequest(investorMeetingRequest: InvestorMeetingUpdateRequest, complete: () => void) {
    this.investorMeetingRequestControllerService.createAndUpdateMeetingRequestUsingPOST1(investorMeetingRequest, this.investorId)
      .subscribe(() => {
        if (complete) { complete(); }
      }, (err) => {
        this.alert.showError(err.error.message);
      });
  }

  saveRegisterInvestorInfo(investorProfile: RegisterInvestorUpdateRequest, complete: () => void) {
    this.registerInvestorControllerService.updateRegisterInvestorUsingPOST(this.investorId, investorProfile)
      .subscribe(() => {
        if (complete) { complete(); }
      }, (err) => {
        this.alert.showError(err.error.message);
      });
  }

  cancelInvestorMeetingRequestById(investorMeetingRequestId: number, completion: () => void) {
    this.investorMeetingRequestControllerService.cancelInvestorMeetingRequestByIdUsingPOST1(investorMeetingRequestId)
      .subscribe((x: APIResult) => {
        if (completion) { completion(); }
      });
  }

  private getRegisterInvestorProfileById(id: number, completion: (registerInvestor: RegisterInvestorResponse) => void) {
    this.registerInvestorControllerService.getRegisterInvestorProfileUsingGET(id).subscribe(x => {
      if (completion) { completion(x.data); }
    });
  }

  private getMeetingRequest(id: number, completion: (investorMeetingRequestResponse: InvestorMeetingRequestResponse[]) => void) {
    this.investorMeetingRequestControllerService.getMeetingRequestUsingGET1(id).subscribe((x: APIResult) => {
      if (completion) { completion(x.data); }
    });
  }

  convertRegisterInvestorToFCValue(investorProfile: RegisterInvestorResponse) {
    return {
      personal: {
        firstname: investorProfile.firstName,
        lastname: investorProfile.lastName,
        company: investorProfile.companyName,
        jobTitle: investorProfile.jobTitle,
        email: investorProfile.email,
        department: investorProfile.department,
        mobile: {
          number: investorProfile.mobileNumber,
          countryCode: investorProfile.countryCode
        },
        extNumber: investorProfile.extNumber,
        country: investorProfile.countryId
      },
      assistant: {
        firstname: investorProfile.assistantFirstName,
        lastname: investorProfile.assistantLastName,
        email: investorProfile.assistantEmail,
        extNumber: investorProfile.assistantExtNumber,
        mobile: {
          number: investorProfile.assistantMobileNumber,
          countryCode: investorProfile.assistantCountryCode
        }
      },
      salePerson: {
        hasSalePerson: investorProfile.hasSalePerson,
        name: investorProfile.salePersonName,
        brokerName: investorProfile.salePersonBrokerName,
        brokerId: investorProfile.salePersonBrokerId
      }
    };
  }

  convertFCValueToRequest(fCValue: any): RegisterInvestorUpdateRequest {
    var salePersonName = checkEqualOtherName(fCValue.salePerson.name) ? fCValue.salePerson.brokerName : fCValue.salePerson.name;

    return {
      assistantCountryCode: fCValue.assistant.mobile ? fCValue.assistant.mobile.countryCode : null,
      assistantEmail: fCValue.assistant.email,
      assistantFirstName: fCValue.assistant.firstname,
      assistantLastName: fCValue.assistant.lastname,
      assistantExtNumber: fCValue.assistant.extNumber,
      assistantMobileNumber: fCValue.assistant.mobile ? fCValue.assistant.mobile.e164Number : null,
      companyName: fCValue.personal.company,
      countryCode: fCValue.personal.mobile ? fCValue.personal.mobile.countryCode : null,
      countryId: fCValue.personal.country,
      email: fCValue.personal.email,
      firstName: fCValue.personal.firstname,
      hasSalePerson: fCValue.salePerson.hasSalePerson,
      jobTitle: fCValue.personal.jobTitle,
      department: fCValue.personal.department,
      lastName: fCValue.personal.lastname,
      mobileNumber: fCValue.personal.mobile ? fCValue.personal.mobile.e164Number : null,
      extNumber: fCValue.personal.extNumber,
      salePersonName: salePersonName,
      brokerName: salePersonName,
      brokerId: fCValue.salePerson.brokerId
    };
  }
}