import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
export class LoginAdminComponent {
    constructor(adalService, router, entRegisAuthService) {
        this.adalService = adalService;
        this.router = router;
        this.entRegisAuthService = entRegisAuthService;
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.adalService.userInfo.authenticated) {
                const isAuthenticated = this.entRegisAuthService.isAuthenticated;
                if (typeof isAuthenticated === 'boolean') {
                    this.decide(isAuthenticated);
                }
                else {
                    const isAuthen = yield isAuthenticated.toPromise();
                    this.decide(isAuthen);
                }
            }
        });
    }
    decide(isAuthen) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (isAuthen) {
                this.goInside();
            }
            else {
                const response = yield this.entRegisAuthService.getTokenWithAzureToken(this.adalService.getToken()).toPromise();
                const accessToken = response.access_token;
                const refreshToken = response.refresh_token;
                this.entRegisAuthService.updateToken(accessToken, refreshToken);
                this.goInside();
            }
        });
    }
    login() {
        this.adalService.login();
    }
    goInside() {
        const eventAlias = localStorage.getItem('eventAlias');
        this.router.navigate(['checkin', eventAlias]);
    }
}
