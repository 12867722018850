import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import {
  BrokerResponse,
  CountryResponse,
  RegisterInvestorResponse,
  CountryPublicControllerService,
  InvestorCompanyResponse,
  RegisterInvestorPublicControllerService,
  RegisterInvestorControllerService, EventInfoWithConsentResponse, CheckEmailDuplicateByIdRequest, CoHostPublicControllerService, CoHostResponse
} from '@ent-regis/entregis-ts-angular';
import {
  SearchCountryField,
  TooltipLabel,
  CountryISO,
} from 'ngx-intl-tel-input';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { RegisterInvestorService } from 'src/app/core/service/register-investor.service';
import { debounceTime, first } from 'rxjs/operators';
import { appConst } from 'src/app/constants/app-const';
import { EventService } from 'src/app/shared/services/event.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { keyPressCharacters, checkAndRemoveTitleWording } from 'src/app/functions/string-formatter.function';
import { checkEqualOtherName } from 'src/app/functions/form.function';

@Component({
  selector: 'app-investor-info',
  templateUrl: './investor-info.component.html',
  styleUrls: ['./investor-info.component.scss'],
})
export class InvestorInfoComponent implements OnInit, OnDestroy {
  @Output() willSave = new EventEmitter<RegisterInvestorResponse>();

  brokers: BrokerResponse[];
  countries: CountryResponse[];
  public companies: InvestorCompanyResponse[] = [];
  isCloseRegisterInvestor: boolean;
  isShowHotel: boolean;

  searchCountryField = [SearchCountryField.Iso2, SearchCountryField.Name];
  tooltipLabel = TooltipLabel.Name;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.Thailand];
  listInvited: CoHostResponse[] = [];

  event: EventInfoWithConsentResponse;
  registerInvestorId: number;
  submitted = false;

  infoFG = new FormGroup({
    personal: new FormGroup({
      firstname: new FormControl(null, Validators.required),
      lastname: new FormControl(null, Validators.required),
      company: new FormControl(null, Validators.required),
      jobTitle: new FormControl(null, Validators.required),
      department: new FormControl(null),
      email: new FormControl(null, [Validators.required, Validators.pattern(appConst.emailPattern)]),
      mobile: new FormControl(null, Validators.required),
      country: new FormControl(null, Validators.required),
      extNumber: new FormControl(null),
    }),
    assistant: new FormGroup({
      firstname: new FormControl(null),
      lastname: new FormControl(null),
      email: new FormControl(null, Validators.pattern(appConst.emailPattern)),
      mobile: new FormControl(null),
      extNumber: new FormControl(null),
    }),
    salePerson: new FormGroup({
      hasSalePerson: new FormControl(false),
      name: new FormControl(null),
      brokerName: new FormControl(null),
      brokerId: new FormControl(null)
    }),
  });

  get personFirstName() { return this.infoFG.get('personal.firstname'); }
  get personLastName() { return this.infoFG.get('personal.lastname'); }
  get personCompany() { return this.infoFG.get('personal.company'); }
  get personJobTitle() { return this.infoFG.get('personal.jobTitle'); }
  get personDepartment() { return this.infoFG.get('personal.department'); }
  get personEmail() { return this.infoFG.get('personal.email'); }
  get personMobile() { return this.infoFG.get('personal.mobile'); }
  get personCountry() { return this.infoFG.get('personal.country'); }

  get assistantFirstName() { return this.infoFG.get('assistant.firstname'); }
  get assistantLastName() { return this.infoFG.get('assistant.lastname'); }
  get assistantEmail() { return this.infoFG.get('assistant.email'); }
  get assistantMobile() { return this.infoFG.get('assistant.mobile'); }

  get salePersonName() { return this.infoFG.get('salePerson.name'); }
  get salePersonHasSalePerson() { return this.infoFG.get('salePerson.hasSalePerson'); }
  get salePersonBrokerName() { return this.infoFG.get('salePerson.brokerName'); }
  get salePersonBrokerId() { return this.infoFG.get('salePerson.brokerId'); }

  private subscription = new Subscription();

  constructor(
    private registerInvestorService: RegisterInvestorService,
    private countryService: CountryPublicControllerService,
    private registerInvestorPublicService: RegisterInvestorPublicControllerService,
    private registerInvestorControllerService: RegisterInvestorControllerService,
    private eventService: EventService,
    private route: ActivatedRoute,
    private router: Router,
    private coHostService: CoHostPublicControllerService
  ) { }

  ngOnInit() {
    this.getCountry();
    this.getCompany();
    this.checkIsCloseRegister();
    this.getEventId(() => {
      this.subscription.add(this.registerInvestorService.investorProfile.subscribe((x: RegisterInvestorResponse) => {
        const fcValue = this.registerInvestorService.convertRegisterInvestorToFCValue(x);
        if (fcValue.salePerson.name && this.checkNotListInvited(fcValue.salePerson.brokerName)) {
          const otherName = this.listInvited.find(f => f.name.toLocaleUpperCase() == "OTHERS");
          if (otherName) {
            fcValue.salePerson.name = otherName.name;
          }
        }
        this.infoFG.setValue(fcValue);
      }));
    });
    this.getInvestorId();
    this.subscription.add(this.salePersonName.valueChanges.subscribe(() => this.salePersonBrokerName.reset()));
    this.subscription.add(this.salePersonHasSalePerson.valueChanges.subscribe(hasSalePerson => {
      if (hasSalePerson) {
        this.salePersonName.setValidators(this.brokerNameValidator);
      } else {
        this.salePersonName.setValidators(null);
        this.salePersonBrokerName.setValidators(null);
        this.salePersonName.setValue(null);
        this.salePersonBrokerName.setValue(null);
      }
      this.salePersonName.updateValueAndValidity();
      this.salePersonBrokerName.updateValueAndValidity();
    }));

  }

  brokerNameValidator(control: AbstractControl): { [key: string]: any } | null {
    if (control.value) {
      if (control.parent && checkEqualOtherName(control.value)) {
        control.parent.get('brokerName').setValidators(Validators.required);
      } else {
        control.parent.get('brokerName').setValidators(null);
      }
      control.parent.get('brokerName').updateValueAndValidity();
    } else {
      return { 'required': true };
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  setCanLeave(bool: boolean) {
    this.registerInvestorService.canLeavePage$.next(bool)
  }

  checkIsCloseRegister() {
    this.registerInvestorService.isCloseRegister.pipe(first(x => x !== null))
      .subscribe(isClose => {
        this.isCloseRegisterInvestor = isClose;
        if (isClose) { this.infoFG.disable(); }
      });
  }

  getCountryCode(country: string) {
    for (const item in CountryISO) {
      if (CountryISO[item] === country.toLowerCase()) {
        return CountryISO[item];
      }
    }
  }

  getCountry() {
    this.countryService.getCountryUsingGET().subscribe((res) => {
      this.countries = res.data;
    });
  }

  private getCompany(): void {
    this.registerInvestorPublicService.getAllInvestorCompanyUsingGET()
      .subscribe(
        (result) => {
          this.companies = result.data;
        }
      );
  }

  private getEventId(complete: () => void): void {
    this.eventService.eventSubject.pipe(first(x => x != null)).subscribe(event => {
      this.event = event;
      if (this.event) {
        this.isShowHotel = this.event.isShowHotel;
        this.coHostService.getCoHostListUsingGET1(this.event.eventId).subscribe((res) => {
          this.listInvited = res.data;
          complete();
        });
      }
    });
  }

  private getInvestorId(): void {
    this.subscription.add(this.registerInvestorService.registerInvestorId.subscribe(id => this.registerInvestorId = id));
  }

  onSubmit() {
    this.submitted = true;
    this.registerInvestorService.canLeavePage$.next(true);
    if (this.infoFG.valid) {
      this.checkEmailDuplicated(this.personEmail, () => {
        if (this.isShowHotel && this.event.eventType != EventInfoWithConsentResponse.EventTypeEnum.DIGITALROADSHOW) {
          this.router.navigate(['..', 'hotel'], { relativeTo: this.route });
        }
        const request = this.registerInvestorService.convertFCValueToRequest(
          this.infoFG.value
        );
        this.willSave.emit(request);
      });
    } else {
      this.infoFG.markAllAsTouched();
      let top = document.getElementById('anchor');
      if (top !== null) {
        top.scrollIntoView();
        top = null;
      }
    }
  }

  checkEmailDuplicated(email: AbstractControl, complete: () => void) {
    if (email.value) {
      if (email.touched) {
        const request: CheckEmailDuplicateByIdRequest = {
          email: email.value,
          eventId: this.event.eventId,
          id: this.registerInvestorId
        };
        this.registerInvestorControllerService.checkEmailDuplicateInEventUsingPOST3(request)
          .subscribe((isDuplicated) => {
            if (isDuplicated.data) {
              email.setErrors({ duplicated: isDuplicated.data });
              document.getElementById('anchor').scrollIntoView();
            } else if (this.personEmail.value === this.assistantEmail.value) {
              this.personEmail.setErrors({ duplicated: true });
              this.assistantEmail.setErrors({ duplicated: true });
              document.getElementById('anchor').scrollIntoView();
            } else {
              this.personEmail.setErrors(null);
              this.assistantEmail.setErrors(null);
              if (complete) { complete(); }
            }
          });
      } else {
        if (complete) { complete(); }
      }
    }
  }

  onKeyPressCharacters(event: any): boolean {
    return keyPressCharacters(event);
  }

  onCheckAndRemoveWording(name: string): void {
    const value = this.infoFG.get(`${name}`).value;
    this.infoFG.get(`${name}`).setValue(checkAndRemoveTitleWording(value));
  }

  checkSalePersonName(): boolean {
    return checkEqualOtherName(this.salePersonName.value);
  }

  checkNotListInvited(name: string): boolean {
    return name ? !this.listInvited.map(m => m.name).includes(name) : false;
  }
}
