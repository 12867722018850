import { AuthProvider } from './core/authentication/auth-provider';
import {
  RegisterCompanyConfirmRmParticipantModalComponent
} from './company/info/register-company-confirm-rm-participant-modal/register-company-confirm-rm-participant-modal.component';
import {
  RegisterCompanyAddEditParticipantModalComponent
} from './company/info/register-company-add-edit-participant-modal/register-company-add-edit-participant-modal.component';
import { environment } from './../environments/environment';
import { ApiModule, BASE_PATH, Configuration, ConfigurationParameters } from '@ent-regis/entregis-ts-angular';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, APP_INITIALIZER } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutComponent } from './layout/layout.component';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { BodyComponent } from './layout/body/body.component';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxUiLoaderModule, NgxUiLoaderHttpModule } from 'ngx-ui-loader';
import { loaderConfig } from './config/loader-config';
import { CompanyComponent } from './company/company.component';
import { TimetableComponent } from './company/timetable/timetable.component';
import { InfoComponent } from './company/info/info.component';
import localeTh from '@angular/common/locales/th';
import { DatePipe, registerLocaleData } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { CdkTableModule } from '@angular/cdk/table';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AppState } from './core/app-state';
import { AuthInterceptor } from './core/authentication/auth-interceptor';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { BsDatepickerModule, BsDropdownModule, PopoverModule, TimepickerModule, TypeaheadModule } from 'ngx-bootstrap';
import { InvestorComponent } from './investor/investor.component';
import { MeetingRequestComponent } from './investor/meeting-request/meeting-request.component';
import { AvailableTimeComponent } from './investor/available-time/available-time.component';
import { HotelComponent } from './investor/hotel/hotel.component';
import { InvestorInfoComponent } from './investor/investor-info/investor-info.component';
import { ScheduleCompanyComponent } from './schedule-company/schedule-company.component';
import { ScheduleInvestorComponent } from './schedule-investor/schedule-investor.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ConsentModule } from './shared/modules/consent/consent.module';
import { PipesModule } from './shared/modules/pipes/pipes.module';
import { PresentationComponent } from './company/presentation/presentation.component';
import { MeetingTypePipe } from './shared/modules/pipes/meeting-type.pipe';
import { NotFoundModalComponent } from './check-in/not-found-modal/not-found-modal.component';
import { AlertCollectBadgeModalComponent } from './check-in/alert-collect-badge-modal/alert-collect-badge-modal.component';
import { LoginAdminComponent } from './login-admin/login-admin.component';
import { Adal8HTTPService, Adal8Service } from 'adal-angular8';
import { UnauthorizeComponent } from './unauthorize/unauthorize.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ReusedComponentModule } from './shared/modules/reused-component/reused-component.module';
let sharedApiModuleConfig: Configuration;

export function apiConfigFactory(): Configuration {
  const params: ConfigurationParameters = {
  };

  params.apiKeys = {
    Authorization: ''
  };

  if (sharedApiModuleConfig == null) {
    sharedApiModuleConfig = new Configuration(params);
  }
  return sharedApiModuleConfig;
}

export function init_app(authProvider: AuthProvider) {
  return () => authProvider.onAppLoaded();
}

registerLocaleData(localeTh);

@NgModule({
  entryComponents: [
    RegisterCompanyAddEditParticipantModalComponent,
    RegisterCompanyConfirmRmParticipantModalComponent,
    NotFoundModalComponent,
    AlertCollectBadgeModalComponent
  ],
  declarations: [
    AppComponent,
    LayoutComponent,
    HeaderComponent,
    FooterComponent,
    BodyComponent,
    CompanyComponent,
    TimetableComponent,
    InfoComponent,
    LoginComponent,
    RegisterCompanyAddEditParticipantModalComponent,
    RegisterCompanyConfirmRmParticipantModalComponent,
    InvestorComponent,
    MeetingRequestComponent,
    AvailableTimeComponent,
    InvestorInfoComponent,
    HotelComponent,
    ScheduleCompanyComponent,
    ScheduleInvestorComponent,
    PresentationComponent,
    NotFoundModalComponent,
    AlertCollectBadgeModalComponent,
    LoginAdminComponent,
    UnauthorizeComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgbModule,
    HttpClientModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    NgxUiLoaderModule.forRoot(loaderConfig),
    NgxUiLoaderHttpModule.forRoot({
      showForeground: true, excludeRegexp: [
        'api/v1/public/register-investor\?',
        '/checkRegisterEmailDuplicate',
        '/exists-company/']
    }),
    ApiModule.forRoot(apiConfigFactory),
    CdkTableModule,
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    NgxIntlTelInputModule,
    TypeaheadModule.forRoot(),
    FontAwesomeModule,
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    PopoverModule.forRoot(),
    TooltipModule.forRoot(),
    ConsentModule,
    PipesModule,
    DragDropModule,
    ReusedComponentModule
  ],
  providers: [
    AppState,
    AuthProvider,
    {
      provide: BASE_PATH, useValue: environment.baseUrl
    },
    {
      provide: LOCALE_ID, useValue: 'en-US'
    },
    {
      provide: APP_INITIALIZER,
      useFactory: init_app,
      multi: true,
      deps: [AuthProvider]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,  // if error can be deleted
      multi: true,
    },
    DatePipe,
    MeetingTypePipe,
    Adal8Service,
    {
      provide: Adal8HTTPService, useFactory: Adal8HTTPService.factory, deps: [HttpClient, Adal8Service]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
