import { Component, OnDestroy, OnInit } from '@angular/core';
import { CompanyWithSlotPeriodIdsResponse, InvestorMeetingRequestResponse, InvestorSlotControllerService, RegisterInvestorResponse } from '@ent-regis/entregis-ts-angular';
import { InvestorSlotResponse } from '@ent-regis/entregis-ts-angular/model/investorSlotResponse';
import { msg } from 'src/app/constants/msg';
import { InvestorSlotUpdateRequest } from '@ent-regis/entregis-ts-angular/model/investorSlotUpdateRequest';
import { InvestorSlotPeriodResponse } from '@ent-regis/entregis-ts-angular/model/investorSlotPeriodResponse';
import { RegisterInvestorService } from 'src/app/core/service/register-investor.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'src/app/shared/services/alert.service';
import { combineLatest, Subscription } from 'rxjs';

@Component({
  selector: 'app-available-time',
  templateUrl: './available-time.component.html',
  styleUrls: ['./available-time.component.scss']
})
export class AvailableTimeComponent implements OnInit, OnDestroy {

  private subscription = new Subscription();

  submited = false;
  slots: InvestorSlotResponse[] = [];
  meetingRequests: InvestorMeetingRequestResponse[] = [];
  approvedCompany: CompanyWithSlotPeriodIdsResponse[] = [];
  isCloseRegister: boolean;
  isEventTypeDigitalRoadShow = false;
  isEventTypeThailandFocus = false;
  private mapSPToCompanySymbols: { [key: number]: string[] };

  constructor(
    private investorSlotService: InvestorSlotControllerService,
    private registerInvestorService: RegisterInvestorService,
    private route: ActivatedRoute,
    private router: Router,
    private alert: AlertService
  ) { }

  ngOnInit() {
    this.registerInvestorService.fetchAllData();

    this.subscription.add(combineLatest(
      this.registerInvestorService.investorMeetingRequest,
      this.registerInvestorService.investorProfile,
      this.registerInvestorService.investorSlots,
      this.registerInvestorService.approvedCompany).subscribe(([meetingRequest, profile, slots, companies]) => {
        if (meetingRequest && profile && slots && companies) {
          this.meetingRequests = meetingRequest;
          this.isCloseRegister = profile.isCloseRegister;
          this.isEventTypeDigitalRoadShow = profile.eventType ===  RegisterInvestorResponse.EventTypeEnum.DIGITALROADSHOW;
          this.isEventTypeThailandFocus = profile.eventType ===  RegisterInvestorResponse.EventTypeEnum.THAILANDFOCUS;
          this.slots = slots;
          this.approvedCompany = companies;

          this.mapSPToCompanySymbols = {};
          const sps: InvestorSlotPeriodResponse[] = Array.prototype.concat(...this.slots.map(slot => slot.slotPeriods));
          sps.forEach(sp => {
            const spId = sp.slotPeriodId;
            this.mapSPToCompanySymbols[spId] = this.approvedCompany.filter(company => {

              return this.meetingRequests.find(request => request.registerCompanyId == company.registerCompanyId && request.investorMeetingRequestId != null) !== undefined 
                && company.slotPeriodIds.includes(spId);
            }).map(company => company.companySymbol);
          });
        }
      }))
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onSelectionChange(index: number, slotPeriodId: number, avalible: string) {
    for (const slotPeriod of this.slots[index].slotPeriods) {
      if (slotPeriod.slotPeriodId === slotPeriodId) {
        // slotPeriod.available = AvailableEnum[avalible];
        slotPeriod.available = InvestorSlotPeriodResponse.AvailableEnum[avalible];
      }
    }
  }

  lookupSymbolsFromSpId(spId: number) {
    return this.mapSPToCompanySymbols[spId];
  }

  save() {
    this.registerInvestorService.canLeavePage$.next(true);
    this.submited = true;
    if (!this.validate()) {
      this.alert.showError(msg.error.selectAllItem);
    } else {
      this.router.navigate(['..', 'investor-info'], { relativeTo: this.route });

      const selectedSlots = [];
      const request: InvestorSlotUpdateRequest = {};
      request.investorSlots = [];

      for (const slot of this.slots) {
        for (const slotPeriod of slot.slotPeriods) {
          request.investorSlots.push({
            investorSlotId: slotPeriod.investSlotId,
            slotPeriodId: slotPeriod.slotPeriodId,
            available: slotPeriod.available
          });
        }
      }

      this.investorSlotService.createAndUpdateInvestorSlotUsingPOST1(request).subscribe(() => {
        this.alert.showSuccess(msg.success.dataSaved).then(() => {
          this.registerInvestorService.fetchInvestorSlot();
        });
      });

    }
  }

  validate() {
    for (const slot of this.slots) {
      for (const slotPeriod of slot.slotPeriods) {
        if (!(slotPeriod.available === 'Y' || slotPeriod.available === 'N')) {
          return false;
        }
      }
    }
    return true;
  }

  changeIsAvalable(index: number, slotPeriodId: number,value: string){
    this.registerInvestorService.canLeavePage$.next(false);
    for (const slotPeriod of this.slots[index].slotPeriods) {
      if (slotPeriod.slotPeriodId === slotPeriodId) {
        // slotPeriod.available = AvailableEnum[avalible];
        slotPeriod.available = value=='Y'?'N':'Y';
      }
    }
    //form.setValue(!form.value);
  }

}
