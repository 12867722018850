/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./layout.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./header/header.component.ngfactory";
import * as i3 from "./header/header.component";
import * as i4 from "@angular/router";
import * as i5 from "../shared/services/auth.service";
import * as i6 from "../shared/services/event.service";
import * as i7 from "@angular/common";
import * as i8 from "./body/body.component.ngfactory";
import * as i9 from "./body/body.component";
import * as i10 from "./footer/footer.component.ngfactory";
import * as i11 from "./footer/footer.component";
import * as i12 from "./layout.component";
var styles_LayoutComponent = [i0.styles];
var RenderType_LayoutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LayoutComponent, data: {} });
export { RenderType_LayoutComponent as RenderType_LayoutComponent };
function View_LayoutComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "common-img default-img"], ["style", "min-height: 100%;"]], [[4, "background-image", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-header", [], null, null, null, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(3, 114688, null, 0, i3.HeaderComponent, [i4.Router, i5.AuthService, i6.EventService, i7.DatePipe], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "py-3 container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-body", [], null, null, null, i8.View_BodyComponent_0, i8.RenderType_BodyComponent)), i1.ɵdid(6, 114688, null, 0, i9.BodyComponent, [], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-footer", [], null, null, null, i10.View_FooterComponent_0, i10.RenderType_FooterComponent)), i1.ɵdid(8, 114688, null, 0, i11.FooterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 3, 0); _ck(_v, 6, 0); _ck(_v, 8, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.eventInfo.bgUrl ? (("url(" + _co.eventInfo.bgUrl) + ")") : "url(../../assets/images/defaultBg1.png)"); _ck(_v, 0, 0, currVal_0); }); }
export function View_LayoutComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_LayoutComponent_1)), i1.ɵdid(1, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.eventInfo; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_LayoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-layout", [], null, null, null, View_LayoutComponent_0, RenderType_LayoutComponent)), i1.ɵdid(1, 114688, null, 0, i12.LayoutComponent, [i6.EventService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LayoutComponentNgFactory = i1.ɵccf("app-layout", i12.LayoutComponent, View_LayoutComponent_Host_0, {}, {}, []);
export { LayoutComponentNgFactory as LayoutComponentNgFactory };
