<div class="container">
  <!-- <div class="mt-4" *ngIf="investorProfile">
    <p class="mb-0"><span class="my-auto h1">{{investorProfile.companyName}}</span><span class="h6">&nbsp;-&nbsp;{{investorProfile.firstName}} {{investorProfile.lastName}}</span></p>
  </div> -->
  <div *ngIf="investorProfile">
    <h4 id="investor-filed-name-text">{{investorProfile.firstName}} {{investorProfile.lastName}}</h4>
    <div class="d-flex">
      <p id="investor-filed-company-text" class="text-muted">{{investorProfile.companyName}}</p>
    </div>
  </div>
  <div class="text-center">
    <div id="investor-filed-timezone-for-digital-roadshow-text" class="h6 text-danger"
      *ngIf="eventType === eventTypeEnum.DIGITALROADSHOW">
      Timezone : {{now | date:'zzz'}} (Your local time)
    </div>
    <div class="text-danger mb-2" *ngIf="eventType !== eventTypeEnum.DIGITALROADSHOW">
      <div id="investor-filed-timezone-for-thailand-focus-text" class="h6 mb-0">Timezone : {{now | date:'zzz': 'UTC
        +7'}}
        (Bangkok time)</div>
      <span id="investor-filed-timezone-for-thailand-focus-warning-text" class="h6">The event will be conducted in
        physical event, unless selected virtual</span>
    </div>
    <div>
      <div id="investor-filed-timezone-available-text" class="h6">Time show on this page is only company's availability</div>
    </div>
    <p id="investor-remark-text" *ngIf="isClosedRegis" class="text-blue">Remark: Unable to edit after registration
      period
      {{startDate | date: 'MMM dd, yyyy'}} - {{endDate | date: 'MMM dd, yyyy'}}</p>
    </div>

  <div>
    <div>
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a id="investor-meeting-request-nav-link" class="nav-link" (click)="gotoTab(['meeting-request'])"
            [class.active]="isActive(['meeting-request'])">Meeting Request</a>
        </li>
        <li class="nav-item" *ngIf="eventType !== eventTypeEnum.DIGITALROADSHOW">
          <a id="investor-available-time-nav-link" class="nav-link" (click)="gotoTab(['available-time'])"
            [class.active]="isActive(['available-time'])">Available Time</a>
        </li>
        <li class="nav-item">
          <a id="investor-investor-info-nav-link" class="nav-link" (click)="gotoTab(['investor-info'])"
            [class.active]="isActive(['investor-info'])">Info</a>
        </li>
        <li class="nav-item" *ngIf="isShowHotel && eventType !== eventTypeEnum.DIGITALROADSHOW">
          <a id="investor-hotel-nav-link" class="nav-link" (click)="gotoTab(['hotel'])"
            [class.active]="isActive(['hotel'])">Hotel</a>
        </li>
      </ul>
    </div>
    <router-outlet (activate)=onActiveTab($event)></router-outlet>
  </div>
</div>