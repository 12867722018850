export const msg = {
  success: {
    dataSaved: 'Save data success.',
    uploadFile: 'Upload file success.',
    deleteFile: 'Delete file success.'
  },
  error: {
    generic: '',
    allRequiredData: 'Please enter all required data.',
    selectAllItem: 'Please select all required data.',
    selectAtLeastOneAvailableTime: 'Please select at least one available time',
    yourCompanyIsRegistered: 'Your company is already registered.',
    emailIsDuplicated: 'email is duplicated.',
    contactAdmin: 'Please contact admin.',
    invalidLogin: 'Invalid username or password',
    selectAtLeastOneCompany: 'Please select at least one company.',
    uploadFile: 'Upload file error,please try agian.',
    uploadFileLarge: 'Unable to upload file over 20MB.',
    selectAtLeastOneSession: 'Please select at least one seminar session.',
  },
  confirmRemove: {
    title: 'Are you sure?',
    text: 'You won\'t be able to revert this!',
    confirmButtonText: 'Yes, delete it!'
  },
  confirmLeave: {
    title: 'Are you sure?',
    text: 'You have some unsaved information on this page. Do you wish to discard your changes?',
    confirmButtonText: 'Yes',
    cancelButtonText: 'No'
  }
};
