import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-not-found-modal',
  templateUrl: './not-found-modal.component.html',
  styleUrls: ['./not-found-modal.component.scss']
})
export class NotFoundModalComponent implements OnInit {
  @Output() retryScan = new EventEmitter<null>();
  private route: ActivatedRoute;
  constructor(
    public bsModalRef: BsModalRef,
    private router: Router
  ) { }

  @ViewChild('template', { static: false }) template: TemplateRef<any>;

  ngOnInit() {
  }

  retry(): void {
    this.retryScan.emit();
    this.bsModalRef.hide();
  }

  home(): void {
    this.bsModalRef.hide();
    this.router.navigate(['..'], {relativeTo: this.route});
  }
}
