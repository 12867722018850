import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements OnInit {

  title = 'CONFIRM';
  message = 'CONFIRM ?';
  btnConfirm = 'CONFIRM';
  bsModalRef: BsModalRef;
  confirm: () => void;
  @ViewChild('template', { static: false }) template: TemplateRef<any>;

  constructor(
    private modalService: BsModalService
  ) { }

  ngOnInit() { }

  openModal(): any {
    this.bsModalRef = this.modalService.show(this.template, { class: 'modal-md', ignoreBackdropClick: true });
  }

  onSubmit(): void {
    if (this.confirm) { this.confirm(); }
  }

  onClose(): void {
    this.bsModalRef.hide();
  }


}
