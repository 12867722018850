<div class="modal-content">
    <div class="modal-header">
        <h6 class="modal-title">{{participant ? participant.firstName + '  ' + participant.lastName : 'Add Participant'}}</h6>
        <button type="button" class="close" (click)=bsModalRef.hide()>
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <form *ngIf="form" role="form" [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="modal-body">
            <div class="row">
                <div class="col-6">
                    <label for="firstName">First Name <span class="text-danger">(No Title Required)*</span></label>
                    <input id="firstName" class="form-control" formControlName="firstName" name="firstName"
                    (blur)="onCheckAndRemoveWording('firstName')" (keypress)="onKeyPressCharacters($event)" required>
                </div>
                <div class="col-6">
                    <label for="lastName">Last Name <span class="text-danger">*</span></label>
                    <input id="lastName" class="form-control" formControlName="lastName" name="lastName"
                    (blur)="onCheckAndRemoveWording('lastName')" (keypress)="onKeyPressCharacters($event)" required>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-6">
                    <label for="email">Email <span class="text-danger">*</span></label>
                    <input *ngIf="form.value.participantId" id="email" class="form-control" formControlName="email" name="email" 
                    [readOnly]="form.value.participantId">
                    <input *ngIf="!form.value.participantId" id="email" class="form-control" formControlName="email" name="email" required>
                    <p class="text-danger" *ngIf="emailF.errors?.pattern">Please provide a valid email address</p>
                    <p class="text-danger" *ngIf="emailF.errors?.duplicated">Email is already registered.</p>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-12">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="willReceiveEmail-checkbox" formControlName="willReceiveEmail" name="willReceiveEmail">
                        <label class="form-check-label"> To receive notify email </label>
                    </div>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-6">
                    <label for="tel">Mobile <span class="text-danger">*</span></label>
                    <ngx-intl-tel-input [cssClass]="'form-control intl-tel-input'"
                        [preferredCountries]="preferredCountries" [searchCountryFlag]="true"
                        [searchCountryField]="searchCountryField" [tooltipField]="tooltipLabel" [phoneValidation]="true"
                        [inputId]="'mobileNumber'" formControlName="mobileNumber" required  [enablePlaceholder]="true">
                    </ngx-intl-tel-input>
                    <div class="text-danger mt-1" *ngIf="mobileNumber.errors?.validatePhoneNumber">Mobile number is invalid
                        pattern</div>
                </div>
                <div class="col-6">
                    <label for="extNumber">Ext. Number</label>
                    <input id="extNumber" class="form-control" formControlName="extNumber" name="extNumber">
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-6">
                    <label for="jobTitle">Job Title <span class="text-danger">*</span></label>
                    <input id="jobTitle" class="form-control" formControlName="jobTitle" name="jobTitle" required>
                </div>
            </div>
            <div class="row mt-2">
              <div class="col-6">
                <label for="dietary">Dietary Requirement</label>
                <ng-select highlightColor="#9575cd" formControlName="dietaryRequirementId" name="dietary">
                  <ng-option value="">Other Dietary</ng-option>
                  <ng-option *ngFor="let dietary of dietaries" [value]=dietary.dietaryId>{{dietary.dietaryName}}
                  </ng-option>
                </ng-select>
              </div>
              <div class="col-6" *ngIf="!form.value.dietaryRequirementId">
                <label for="dietary">Other Dietary</label>
                <input type="text" id="otherDietary" class="form-control" name="otherDietary"
                formControlName="otherDietary">
              </div>
            </div>
            <div class="row mt-2">
                <div class="col-12">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="participant-checkbox" formControlName="isParticipant" name="isParticipant">
                        <label class="form-check-label" for="participant-checkbox">
                          Be a speaker
                        </label>
                      </div>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-12">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="contact-checkbox" formControlName="isContact" name="isContact">
                        <label class="form-check-label" for="contact-checkbox">
                          Be a contact person
                        </label>
                      </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-primary">{{participant ? 'Save changes' : 'Create' }}</button>
            <button type="button" class="btn btn-secondary" (click)=bsModalRef.hide()>Close</button>
        </div>
    </form>

</div>
