import { Injectable } from '@angular/core';
import Swal, { SweetAlertResult } from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor() { }

  public showSuccess(text: string): Promise<SweetAlertResult> {
    return Swal.fire({
      confirmButtonColor: '#fbaf47',
      heightAuto: false,
      text: text,
      icon: 'success',
      showClass: {
        popup: 'animate__animated animate__fadeIn animate__faster'
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOut animate__faster'
      }
    });
  }

  public showError(text: string): Promise<SweetAlertResult> {
    return Swal.fire({
      heightAuto: false,
      confirmButtonColor: '#fbaf47',
      text: text,
      icon: 'error',
      showClass: {
        popup: 'animate__animated animate__fadeIn animate__faster'
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOut animate__faster'
      }
    });
  }

  public showInfo(title: string, message: string): Promise<SweetAlertResult> {
    return Swal.fire({
      confirmButtonColor: '#fbaf47',
      heightAuto: false,
      title: title,
      text: message,
      showClass: {
        popup: 'animate__animated animate__fadeIn animate__faster'
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOut animate__faster'
      }
    });
  }
}
