import { Component } from '@angular/core';
import { Adal8Service } from 'adal-angular8';
import { config } from 'src/environments/config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'enterprise-registration-web-public';
  constructor(private adalService: Adal8Service) {
    this.adalService.init(config.adalConfig);
    this.adalService.handleWindowCallback();
  }
}

